import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React, { forwardRef, useMemo } from 'react';
import { Button, CTA, ImageWithBackground, IImage, MotionImage, useInViewScroll, useWindowSize } from 'ui';
import { Chevron } from './Chevron';
import { makeVariants } from '../utils/makeVariants';
import { TVImage } from './TVImage';

export interface TwoColumnProps {
  backgroundColor: string;
  textColor: string;
  heading?: string;
  body?: string;
  image: IImage;
  subImage?: IImage;
  contentType?: 'chevron' | 'tv' | 'icon' | '';
  imagePosition?: 'left' | 'right' | 'left-overlap' | 'right-overlap';
  cta?: CTA;
  primary?: string;
  reverseColumn?: boolean;
}

export const TwoColumnBlock: React.FC<TwoColumnProps> = ({
  heading,
  body,
  backgroundColor,
  textColor,
  primary,
  contentType,
  cta,
  image,
  subImage,
  imagePosition,
  reverseColumn,
}) => {
  const { t } = useTranslation('common');

  const { ref, progress: y } = useInViewScroll([25, -50], ['start 100vh', 'end 0vh']);
  const { ref: ref2, progress: y2 } = useInViewScroll([50, -150], ['start 100vh', 'end 0vh']);

  const imageOnLeft = imagePosition?.includes('left') ?? false;
  const overlap = imagePosition?.includes('overlap');

  const variants = makeVariants.slideIn();

  const DefaultFirstBlock = () => {
    const { width } = useWindowSize();
    const ASPECT_RATIO = 1.45299145299;
    const dimensions = useMemo(() => {
      if (subImage) return { width: 100, height: 100 / ASPECT_RATIO };

      switch (true) {
        case width < 768:
          return { width: 75, height: 75 / ASPECT_RATIO };
        case width < 1024:
          return { width: 66.666, height: 66.666 / ASPECT_RATIO };
        default:
          return { width: 100, height: 100 / ASPECT_RATIO };
      }
    }, [width]);

    return (
      <div id={'block-1'} className={`grow-1 w-full basis-full overflow-hidden`}>
        <div className={`flex items-center justify-center ${imageOnLeft ? 'sm:pr-6' : 'md:pl-6'}  max-md:mb-8`}>
          <div
            className={`${overlap ? 'absolute max-md:relative' : 'relative'} ${
              imagePosition === 'left-overlap' ? 'max-md:left-0' : 'auto'
            } flex max-h-[400px] justify-center max-md:my-8`}
            style={{
              width: `${dimensions.width}vw`,
              height: `${dimensions.height}vw`,
              right: imagePosition === 'right-overlap' ? -50 : 'auto',
              marginTop: subImage ? -50 : 'auto',
              left: imagePosition === 'left-overlap' ? '-30%' : 'auto',
            }}
          >
            <MotionImage ref={forwardRef(ref)} className={`rounded-lg object-contain`} src={image.src} alt={image.alt ?? ''} fill style={{ y }} />
            {subImage && (
              <motion.div className="absolute bottom-[-20px] right-[-20px] md:bottom-[-120px] md:right-[-50px]">
                <MotionImage
                  ref={forwardRef(ref2)}
                  className="h-[148px] rounded-lg md:h-[188px]"
                  src={subImage.src}
                  alt={subImage.alt ?? ''}
                  fill
                  style={{ y: y2 }}
                />
              </motion.div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const firstBlock = (() => {
    switch (contentType) {
      case 'tv':
        return <TVImage variants={variants} screenSrc={image} imageOnLeft={imageOnLeft} />;
      case 'chevron':
        return (
          <div className={`h-full w-full`}>
            <div className={`flex h-full w-full items-center`}>
              <div className="mb-6 h-[410px] flex-1 md:mb-0 md:h-[360px]">
                <Chevron bgColor={primary || '#05B2B6'} image={image?.src ?? ''} />
              </div>
            </div>
          </div>
        );
      case 'icon':
        if (!subImage) return DefaultFirstBlock();
        return <ImageWithBackground image={image} backgroundImage={subImage} size={'large'} accent={backgroundColor} variants={variants} />;
      default:
        return DefaultFirstBlock();
    }
  })();

  const secondBlock = (
    <div className={`mt-6 flex w-full shrink-0 basis-1/2 flex-col justify-center sm:mt-0 md:w-[380px]`}>
      <h2
        className={`type-heading mb-6 mt-0 w-full`}
        dangerouslySetInnerHTML={{ __html: heading ? t(heading) : '' }}
        style={{ color: textColor }}
        data-i18n={heading}
      />
      <div className={`type-body mb-6`} style={{ color: textColor }} dangerouslySetInnerHTML={{ __html: body ? t(body) : '' }} data-i18n={body} />
      {cta && (
        <Button
          buttonType="primary"
          href={cta.href}
          label={cta.label}
          styleOverrides={{
            tailwind: `px-8 py-2 w-min whitespace-nowrap`,
            css: {
              color: cta.textColor ?? textColor,
              backgroundColor: cta.backgroundColor,
            },
          }}
        />
      )}
    </div>
  );

  return (
    <section className="relative w-full overflow-hidden text-left" style={{ backgroundColor }} data-testid="two-column-section">
      <motion.div
        variants={variants}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true, amount: 0.5 }}
        className={`container z-20 mx-auto flex min-h-[594px] max-w-screen-lg ${reverseColumn ? 'flex-col-reverse' : 'flex-col'} ${
          imageOnLeft ? 'md:flex-row' : 'md:flex-row-reverse'
        } items-center justify-center p-6`}
      >
        {firstBlock}
        {secondBlock}
      </motion.div>
    </section>
  );
};
