// ColumnItems.tsx
import { motion } from 'framer-motion';
import { CopySection, CopyProps } from './CopySection';

interface ColumnItemsProps {
  columnItems?: CopyProps[];
  isCorporate?: boolean;
  withDivider?: boolean;
  primary?: string;
}

export const ColumnItems: React.FC<ColumnItemsProps> = ({ columnItems, primary, withDivider = true, isCorporate = false }) => {
  if (!columnItems) {
    return null;
  }

  return (
    <motion.ul className={`flex w-full flex-col justify-around py-4 first:ml-0 max-[400px]:items-start  md:flex-row`}>
      {columnItems.map(({ ...column }, key) => {
        return <CopySection key={key} {...column} isColumn withDivider={key > 0 ? true : false} />;
      })}
    </motion.ul>
  );
};
