/* eslint-disable max-len */
import { motion } from 'framer-motion';
import Image from 'next/image';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import { responsiveConfig, useInViewScroll, useResponsive, useWindowSize, useScrolledTheme } from '../hooks';
import { IImage } from '../types';
import { makeVariants } from '../utils/makeVariants';
import { SplitDiv, SplitH2 } from './hocs';
import Head from 'next/head';

const IMG_HEIGHT_RATIO = 255 / 347;

interface FocusOnSelectProps {
  images: IImage[];
  accent: string;
}

const defaultImages = [
  { src: `/watchTV.png`, alt: 'watchTV' },
  { src: `/family.png`, alt: 'chevron' },
  { src: `/kids.png`, alt: 'chevron' },
  { src: `/family.png`, alt: 'chevron' },
];

const FocusOnSelect: React.FC<FocusOnSelectProps> = ({ images, accent }) => {
  const [index, setIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [__, setToLeft] = useState(true);

  function Arrow(props: any) {
    let className = props.type === 'next' ? 'right-[-25px]' : 'left-[-25px]';
    className += ` absolute text-[${accent}] top-[50%] cursor-pointer`;
    const char =
      props.type === 'next' ? (
        <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 0H4.86991L11 9H6.07185L0 0Z" fill={accent} />
          <path fillRule="evenodd" clipRule="evenodd" d="M0 17H4.86991L11 8L6.07185 8L0 17Z" fill={accent} />
        </svg>
      ) : (
        <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.301875">
            <path fillRule="evenodd" clipRule="evenodd" d="M11 0H6.13009L0 9H4.92815L11 0Z" fill={accent} />
            <path fillRule="evenodd" clipRule="evenodd" d="M11 17H6.13009L0 8L4.92815 8L11 17Z" fill={accent} />
          </g>
        </svg>
      );
    return (
      <span className={className} onClick={props.onClick}>
        {char}
      </span>
    );
  }

  const beforeChange = useCallback((oldIndex: number, newIndex: number) => {
    setIndex(newIndex);
    setToLeft(newIndex > oldIndex || (newIndex === 0 && oldIndex !== 1));
  }, []);

  const afterChange = useCallback((i: number) => {
    setSelectedIndex(i);
  }, []);

  const sliderRef = useRef<any>(null);

  const { width } = useWindowSize();
  const responsiveInfo = useResponsive();

  const isMobile = width <= 639;

  const containerWidth = useMemo(() => {
    let w = containerRef.current?.offsetWidth || (width || 768) - 24;

    if (!w) {
      const values = Object.values(responsiveConfig);

      for (let i = 0; i < values.length; i++) {
        if (width > values[i] && values[i]) {
          w = values[i];
        }
      }
    }

    return w;
  }, [width]);

  return (
    <div className={`m-auto mb-0 mt-12 w-full px-2`}>
      <div className="relative" ref={containerRef as any}>
        <div
          className={'absolute top-0 z-20 hidden sm:flex'}
          style={{
            transition: 'width 0.5s, box-shadow 0.3s',
            width: index === selectedIndex ? '44%' : '33%',
            opacity: index === selectedIndex ? '1' : '0',
            boxShadow: index === selectedIndex ? '-51px 60px 112px -29px rgba(0,0,0,0.7)' : '-51px 60px 112px -29px rgba(0,0,0,0.0)',
          }}
        >
          {images?.length >= index && <img className="w-full" src={images[index].src} alt={images[index].alt ?? ''} />}
        </div>

        <Slider
          ref={sliderRef}
          dots={false}
          beforeChange={beforeChange}
          afterChange={afterChange}
          focusOnSelect={true}
          infinite={true}
          slidesToShow={isMobile ? 1 : 3}
          slidesToScroll={1}
          nextArrow={<Arrow type="next" />}
          prevArrow={<Arrow type="prev" />}
          speed={500}
        >
          {images.map((image: IImage, index: number) => {
            return (
              <div key={image.src} className="relative cursor-pointer">
                <div
                  className={`display absolute top-0 z-10 h-full w-full bg-white`}
                  style={{ transition: 'opacity 0.3s', opacity: index === index ? '0' : '0.7' }}
                ></div>
                <Image
                  className="w-full"
                  src={image.src}
                  alt={image.alt ?? ''}
                  width={containerWidth * 0.333}
                  height={containerWidth * 0.333 * IMG_HEIGHT_RATIO}
                />
              </div>
            );
          })}
        </Slider>
        <div className={`m-auto mb-0 mt-[60px] flex  w-full flex-row items-center justify-start sm:justify-end`}>
          <div className={`relative mr-[90px] h-[1px] w-[130px] rounded-[1.5px] md:mr-[160px] md:w-[183px]`}>
            <div className={`t-0 l-0 absolute z-10 h-[1px] w-full rounded-[1.5px]`} style={{ backgroundColor: `${accent}`, opacity: 0.3 }} />
            <div
              className={`absolute z-20 mt-[-1px] h-[3px]  rounded-[1.5px]`}
              style={{
                backgroundColor: `${accent}`,
                width: `${(selectedIndex * (100 / (images.length - 1))).toFixed(0)}%`,
                transition: 'width 0.3s ease-in-out',
              }}
            />
          </div>
          <div className="absolute bottom-[-25px] right-0 flex flex-row items-baseline justify-start sm:justify-end">
            {images.map((image: IImage, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => sliderRef.current?.slickGoTo(index)}
                  className="h-[39px] w-[22px] cursor-pointer"
                  style={{
                    fontSize: index === selectedIndex ? 26 : 14,
                    fontWeight: index === selectedIndex ? 600 : 300,
                    color: index === selectedIndex ? accent : '#000',
                    transition: 'font-size 0.5s ease-in-out',
                  }}
                >
                  {index + 1}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export interface CarouselProps {
  backgroundColor: string;
  heading: string;
  body?: string;
  images: IImage[];
  accent: string;
}

export const Carousel: React.FC<CarouselProps> = ({ heading, body, backgroundColor, images: ogImages, accent }) => {
  const variants = makeVariants.slideIn();

  // workaround until Directus is fixed
  const images = defaultImages;

  const { ref, progress: y } = useInViewScroll([0, 100], ['start 0vh', 'end 0vh']);

  const { componentInView } = useScrolledTheme({
    ref,
    background: backgroundColor,
    accent,
    text: '#000000',
  });

  const getCarouselJsonLd = () => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      name: heading,
      description: body,
      image: ogImages,
    };

    return {
      __html: JSON.stringify(obj),
    };
  };

  const firstBlock = (
    <motion.div
      initial="initial"
      whileInView="animate"
      variants={variants}
      className={`m-auto mb-0 flex w-full flex-col items-start text-left md:flex-row md:space-x-6 lg:space-x-[100px] `}
    >
      <SplitH2 content={heading} className={'type-title mt-0 grow'} style={{ color: accent }} />
      {body && <SplitDiv content={body} className={`type-body mb-6 grow text-lg max-md:mt-6 md:w-[300px] lg:w-[400px]`} />}
    </motion.div>
  );
  return (
    <motion.section
      animate={{ opacity: componentInView ? 1 : 0.2 }}
      ref={ref}
      className="relative w-full text-left lg:pb-[100px]"
      data-testid="carousel-section"
    >
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getCarouselJsonLd()} />
      </Head>
      <motion.div className={'container mx-auto flex min-h-[594px] items-center p-6'}>
        <motion.div className={'flex w-full flex-col'}>
          {firstBlock}
          <FocusOnSelect images={images} accent={accent} />
        </motion.div>
      </motion.div>
    </motion.section>
  );
};
