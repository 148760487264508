import { MetaProperty } from '../types';

export const getMetadatum = ({
  property,
  content,
  key,
  attributeName = 'property',
}: {
  property: MetaProperty;
  attributeName?: 'name' | 'property' | 'httpEquiv' | 'itemProp';
  content: string;
  key?: string;
}) => {
  return {
    [attributeName]: property,
    content,
    ...(key ? { key } : {}),
  };
};
