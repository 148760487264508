import React, { useCallback, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Slider from 'react-slick';
import { useInViewScroll, useWindowSize, responsiveConfig, IImage, makeVariants, SplitDiv, SplitH1 } from '../';

const IMG_HEIGHT_RATIO = 255 / 347;

export interface SliderBlockProps {
  backgroundColor: string;
  heading: string;
  body?: string;
  images: IImage[];
  accent: string;
}

export const SliderBlock: React.FC<SliderBlockProps> = ({ heading, body, backgroundColor, images, accent }) => {
  const variants = makeVariants.slideIn();
  const { ref, progress: y } = useInViewScroll([0, 100], ['start 0vh', 'end 0vh']);

  const firstBlock = (
    <motion.div
      initial="initial"
      whileInView="animate"
      variants={variants}
      className={`mb-0 flex w-full flex-col items-center justify-between max-[600px]:mt-12 min-[601px]:mt-32`}
    >
      <SplitH1 content={heading} className={`type-heading mb-6 mt-0 w-full text-center text-[32px] md:max-w-[50%]`} />
      {body && <SplitDiv content={body} className={`type-body mb-6 text-center text-[12px] font-light leading-[16px] md:max-w-[50%] md:text-left`} />}
    </motion.div>
  );
  return (
    <section ref={ref} className="relative w-full overflow-hidden text-left" style={{ backgroundColor }} data-testid="carousel-section">
      <motion.div className={'container mx-auto flex min-h-[594px] max-w-screen-lg items-center p-6'}>
        <motion.div className={'flex w-full flex-col'}>
          <FocusOnSelect images={images} accent={accent} />
          {firstBlock}
        </motion.div>
      </motion.div>
    </section>
  );
};

interface FocusOnSelectProps {
  images: IImage[];
  accent: string;
}

const FocusOnSelect: React.FC<FocusOnSelectProps> = ({ images, accent }) => {
  const [index, setIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [__, setToLeft] = useState(true);

  const beforeChange = useCallback((oldIndex: number, newIndex: number) => {
    setIndex(newIndex);
    setToLeft(newIndex > oldIndex || (newIndex === 0 && oldIndex !== 1));
  }, []);

  const afterChange = useCallback((i: number) => {
    setSelectedIndex(i);
  }, []);

  const sliderRef = useRef<any>(null);

  const { width } = useWindowSize();

  const isMobile = width <= 639;

  const getOffset = useCallback(
    (index: number) => {
      const maxIndex = images.length - 1;

      const leftIndex = index - 1 >= 0 ? index - 1 : maxIndex;
      const rightIndex = index + 1 <= maxIndex ? index + 1 : 0;

      if (index === leftIndex) {
        return -115;
      } else if (index === rightIndex) {
        return 115;
      }
      return 0;
    },
    [images.length],
  );

  const containerWidth = useMemo(() => {
    let w = containerRef.current?.offsetWidth || (width || 768) - 24;

    if (!w) {
      const values = Object.values(responsiveConfig);

      for (let i = 0; i < values.length; i++) {
        if (width > values[i] && values[i]) {
          w = values[i];
        }
      }
    }

    return w;
  }, [width]);

  return (
    <div className={`m-auto mb-0 mt-12 w-full px-2`}>
      <div className="relative" ref={containerRef as any}>
        <div
          className={'absolute left-0 right-0 top-0 z-20 mx-auto hidden sm:flex'}
          style={{
            transition: 'height 1s 0.15s, width 1s 0.15s, opacity 0.1s 0.15s',
            width: containerWidth * (index === selectedIndex ? 0.444 : 0.333),
            height: containerWidth * (index === selectedIndex ? 0.444 : 0.333) * IMG_HEIGHT_RATIO,
            opacity: index === selectedIndex ? '1' : '0',
          }}
          key={images[index].src}
        >
          <Image className="w-full" src={images[index].src} alt={images[index].alt ?? ''} fill />
        </div>

        <Slider
          ref={sliderRef}
          dots={false}
          beforeChange={beforeChange}
          afterChange={afterChange}
          focusOnSelect={true}
          slidesToShow={isMobile ? 1 : 3}
          slidesToScroll={1}
          nextArrow={<Arrow type="next" accent={accent} />}
          prevArrow={<Arrow type="prev" accent={accent} />}
          speed={500}
          centerMode={true}
          centerPadding="0px"
        >
          {images.map((i: IImage, index: number) => {
            const offset = getOffset(index);
            return (
              <div key={i.src} className="cursor-pointer">
                <div
                  className="relative cursor-pointer"
                  style={{
                    width: containerWidth * 0.333,
                    height: containerWidth * 0.333 * IMG_HEIGHT_RATIO,
                  }}
                >
                  <div
                    className={`display absolute top-0 z-10 h-full w-full bg-white`}
                    style={{
                      transitionDuration: '1s',
                      opacity: index === index ? '0' : '0.3',
                      transform: `translateX(${offset}px)`,
                      zIndex: offset !== 0 ? 2 : 1,
                    }}
                  ></div>
                  <Image
                    className={`absolute top-0  h-full w-full`}
                    src={i.src}
                    alt={i.alt ?? ''}
                    width={containerWidth * 0.333}
                    height={containerWidth * 0.333 * IMG_HEIGHT_RATIO}
                    style={{ transform: `translateX(${offset}px`, transitionDuration: '1s', zIndex: offset !== 0 ? 2 : 1 }}
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

// TODO: Replace with HeroIcon
function Arrow(props: { accent: string; type: string; onClick?: () => void }) {
  let className = props.type === 'next' ? 'right-[-25px] sm:right-[140px] lg:right-[225px]' : 'sm:left-[140px] lg:left-[235px] left-[-25px]';
  className += ` absolute text-[${props.accent}] top-[50%] cursor-pointer`;
  const char =
    props.type === 'next' ? (
      <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H4.86991L11 9H6.07185L0 0Z" fill={props.accent} />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 17H4.86991L11 8L6.07185 8L0 17Z" fill={props.accent} />
      </svg>
    ) : (
      <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.301875">
          <path fillRule="evenodd" clipRule="evenodd" d="M11 0H6.13009L0 9H4.92815L11 0Z" fill={props.accent} />
          <path fillRule="evenodd" clipRule="evenodd" d="M11 17H6.13009L0 8L4.92815 8L11 17Z" fill={props.accent} />
        </g>
      </svg>
    );
  return (
    <span className={className} onClick={props.onClick}>
      {char}
    </span>
  );
}
