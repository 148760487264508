import { DirectusChannelSearchBlock } from 'config';
import { ChannelSearchBlockProps } from '../../../components';
import { mapDirectusImage } from '../general';

export const mapDirectusChannelSearchBlock = (block: DirectusChannelSearchBlock): ChannelSearchBlockProps => {
  return {
    anchor: block.anchor,
    heading: block.heading,
    searchPlaceholder: block.search_bar_placeholder,
    backgroundImage: mapDirectusImage(block.background_image) || undefined,
    backgroundColor: block.background_color ?? '#fff',
    genres: block.channel_list_items.map(item => ({
      heading: item.item.heading,
      channels: item.item.list.split('\n'),
    })),
  };
};
