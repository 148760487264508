import React from 'react';
import Image from 'next/image';
import { IImage } from '../types';
import { motion } from 'framer-motion';
//import { MotionImage } from './AnimatedImage';

type TVImageProps = {
  screenSrc: IImage;
  imageOnLeft: boolean;
  variants: any;
};

export const TVImage = ({ variants, imageOnLeft }: TVImageProps) => {
  const imageSrc = process.env.DIRECTUS_APP_ID?.includes('undefined') ? false : true;
  return (
    <div className="relative flex h-[600px] w-full flex-grow items-center max-md:mb-4  max-md:h-[400px] max-sm:h-[320px]">
      <motion.div
        variants={variants}
        initial="containerInitial"
        whileInView={'containerAnimate'}
        viewport={{ once: true }}
        className={`${
          !imageOnLeft ? 'right-[-136px] md:right-[-88px] lg:right-[-148px]' : 'left-[-136px] md:left-[-88px] lg:left-[-148px]'
        }  absolute h-[600px] w-full overflow-hidden`}
      >
        <Image
          className="absolute h-full w-full overflow-hidden object-contain object-center"
          // eslint-disable-next-line sonarjs/no-nested-template-literals
          src={`${!imageSrc ? `https://${process.env.DIRECTUS_APP_ID}.directus.app/assets/455cdfd3-a580-4c77-83b3-8a6025d4c096` : '/tablet-landscape.png'}`}
          alt="TV Screen"
          fill
        />

        {/* <MotionImage
          className="z-3 absolute-center w-[76%] overflow-hidden object-center"
          variants={variants}
          initial="hidden"
          whileInView={'visible'}
          viewport={{ once: true }}
          src={screenSrc.src}
          alt={screenSrc.alt ?? ''}
        /> */}
      </motion.div>
    </div>
  );
};
