import React, { useContext, useRef, useEffect } from 'react';
import { useInView } from 'framer-motion';
import { PageContext } from 'ui';

export const useScrolledTheme = ({ ref, background, accent, text }) => {
  const { theme, setTheme } = useContext(PageContext);

  const componentInView = useInView(ref, { margin: '-50% 0px -50% 0px' });
  useEffect(() => {
    if (componentInView) {
      setTheme(theme => ({
        background: background || theme.background,
        accent: accent || theme.accent,
        text: text || theme.text,
      }));
    }
  }, [accent, background, componentInView, setTheme, text]);

  return {
    componentInView,
    colors: theme,
  };
};
