import React, { useMemo, CSSProperties } from 'react';
import classNames from 'classnames';
import { ButtonProps, ButtonIcon, HtmlString } from '../../';
import { useState } from 'react';
import { serializeStyleOverride } from '../../utils/serializeStyleOverride';

// TODO: Add a gradient to the "before" pseudo element;
// TODO: show underline on hover
// TODO: Move composites (e.g. pseudoelement) to tailwind.plugins
const classes = {
  base: 'weight-500 transition-all  relative overflow-visible min-[300px]:whitespace-nowrap font-WorkSans ' + 'items-center  rounded-md',
  primary: {
    base: 'hover:drop-shadow-md weight-600 justify-center hover:bg-opacity-[95%] inline-block',
    corporate: '',
    enterprise: '',
  },
  secondary: {
    base: ` text-left flex justify-start max-w-min ml-4 hover:weight-600  before:transition-all before:duration-300 before:absolute before:w-0 before:opacity-0
     before:left-0 before:mt-[2rem] before:rounded-sm before:h-[2px] before:inset-0 before:hover:w-[88%] 
     before:hover:opacity-[88%] `,
    corporate: '',
    enterprise: '',
  },
  disabled: {
    base: 'bg-xumoSmoke text-xumoCharcoal decoration-xumoSmoke',
  },
};

function applyDynamicStyles(isPrimary: boolean, isDisabled: boolean, isCorporate: boolean, overrides?: string, isDarkMode?: boolean): string {
  return [
    classes[isPrimary ? 'primary' : 'secondary']['base'],
    classes[isPrimary ? 'primary' : 'secondary'][isCorporate ? 'corporate' : 'enterprise'],
    overrides,
  ].join(' ');
}

export const withButtonStyles = <P extends {}, T extends HTMLElement>(
  Component: React.FC<P & Omit<ButtonProps<T>, 'label'>> & { displayName?: string; name?: string },
  defaultStyles: ButtonProps<HTMLElement>['styleOverrides'] = '',
) => {
  const WrappedComponent = React.forwardRef(
    (
      {
        buttonType,
        styleOverrides,
        isDisabled,
        handleClick,
        label,
        isCorporate,
        isPrimary,
        icon,
        ...rest
      }: Omit<ButtonProps<T>, 'label'> & Required<{ isCorporate: boolean; isPrimary: boolean; label: HtmlString }> & P,
      ref: React.Ref<any>,
    ) => {
      const { css, tailwindClasses } = serializeStyleOverride(styleOverrides);
      const [isHovered, setIsHovered] = useState(false);
      const className: string = useMemo(() => {
        const dynamicStyles = applyDynamicStyles(isPrimary, isDisabled ?? false, isCorporate, tailwindClasses, isHovered);
        return classNames(`${classes.base} ${defaultStyles} ${dynamicStyles}`);
        // TODO: Extend with Click as dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isDisabled, isPrimary, isCorporate, styleOverrides, css, isHovered]);

      // React doesn't support !important in inline styles, but the tailwind classes use it.
      const styles = {
        color: 'white',
        ...css,
      };

      return (
        <Component
          ref={ref}
          className={className}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={(event: any) => {
            handleClick && handleClick(event);
          }}
          style={styles}
          {...(rest as P)}
        >
          {icon && <ButtonIcon {...icon} />}
          <span dangerouslySetInnerHTML={label} />
        </Component>
      );
    },
  );

  WrappedComponent.displayName = `withButtonStyles(${Component?.displayName || Component?.name || 'Component'})`;
  return WrappedComponent;
};
