/* eslint-disable sonarjs/no-duplicate-string */
import type { AppProps } from 'next/app';
import '../styles/global.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { appWithTranslation } from 'next-i18next';
import { PageProvider, NavigationHeader, SkipLink } from 'ui';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

const navProps = {
  startTransparent: false,
  cta: { label: 'Account', href: 'https://tv.xumo.com/account' },
  links: [
    {
      label: 'Xumo TV',
      href: '/products/xumo-tv',
      subnav: {
        featured: {
          title: 'Introducing Xumo TV',
          image: '/featured-shop.png',
          link: {
            label: 'Bring streaming home with an operating system that’s all new and all you',
            href: '/products/xumo-tv',
          },
        },
        cta: false,
      },
    },
    {
      label: 'Xumo Play',
      href: 'https://play.xumo.com',
      subnav: {
        featured: {
          title: 'Free streaming',
          image: '/xumo-play.png',
          link: {
            label: 'Your destination for free LIVE and on-demand streaming channels',
            href: 'https://play.xumo.com',
          },
        },
        cta: false,
      },
    },
    {
      label: 'About',
      href: '/company',
      subnav: {
        sections: [
          {
            title: 'Company',
            links: [
              {
                label: 'Who We Are',
                href: '/company',
              },
              {
                label: 'Careers',
                href: 'https://comcast.jibeapply.com/main/jobs?keywords=xumo&sortBy=relevance&page=1',
              },
            ],
          },
          {
            title: 'Media',
            links: [
              {
                label: 'Press',
                href: '/press',
              },
            ],
          },
        ],
        // featured: {
        //   title: 'Latest Article',
        //   image: '/featured-company.jpg',
        //   link: {
        //     label: 'Meet the new Xumo',
        //     href:

        //   },
        // },
        cta: false,
      },
    },
    {
      label: 'Account',
      href: 'https://tv.xumo.com/account',
    },
    {
      label: 'Support',
      href: 'https://tv.xumo.com/support',
      subnav: {
        sections: [
          {
            title: 'Xumo TV',
            links: [
              {
                label: 'Manage Account',
                href: 'https://tv.xumo.com/account',
              },
              {
                label: 'Support Portal',
                href: 'https://tv.xumo.com/support',
              },
            ],
          },
          {
            title: 'Xumo Play',
            links: [
              {
                label: 'FAQs',
                href: 'https://corp.xumo.com/faq',
              },
            ],
          },
        ],
        cta: false,
      },
    },
  ],
  secondaryLinks: [
    {
      label: 'For Business',
      href: '/enterprise',
    },
  ],
};

function App({ Component, pageProps }: AppProps) {
  const {
    locale,
    query: { slug },
  } = useRouter();

  const route = slug ? slug.join('/') : '/';

  // Prevent page from re-rendering on hash change
  const component = useMemo(() => {
    return <Component {...pageProps} />;
  }, [locale, route, pageProps, Component]);

  return (
    <PageProvider>
      <SkipLink className={'skip-link absolute top-32 z-10 hover:cursor-pointer focus:outline-none'} skipTo={'#after-nav'}>
        <React.Fragment />
      </SkipLink>
      <NavigationHeader {...navProps} />
      {component}
    </PageProvider>
  );
}

export default appWithTranslation(App);
