import { DirectusColorCopyGrid } from 'config';
import { ColorCopyGridProps } from '../../..';
import { mapDirectusCTAs } from '../general';

export const mapDirectusColorCopyGrid = (block: DirectusColorCopyGrid): ColorCopyGridProps => {
  return {
    heading: block.heading ?? '',
    body: block.body ?? '',
    copy: (block.copy_items ?? []).map(copy => ({
      heading: copy.item.heading ?? '',
      body: copy.item.body ?? '',
      cta: mapDirectusCTAs(copy.item.cta_items ?? []),
    })),
    backgroundColor: block.background_color ?? '#fff',
    asCard: block.asCard ?? false,
  };
};
