import { Field } from 'formik';
import { useContext } from 'react';
import { PageContext } from '..';
import { useTranslation } from 'next-i18next';
import { InputType } from '../types';
import { isEnterprise, isEnterpriseOrShopper } from '../utils';
import { StringSchema } from 'yup';
export interface IFormField {
  type: InputType;
  name: string;
  placeholder: string;
  width?: 'full' | 'half';
  customValidation?: StringSchema;
}

export type FormFieldProps = IFormField & {
  handleChange: (e: any) => void;
  value: string | string[];
  focusedElementId: string | undefined;
  styleOverride?: string;
  errorId?: string;
};

export const FormField = ({ name, placeholder, type, handleChange, value, focusedElementId, errorId, width = 'half', styleOverride = '' }: FormFieldProps) => {
  const { t } = useTranslation('common');
  const { pageCtx } = useContext(PageContext);
  const { host } = pageCtx;

  const focusBorder = isEnterpriseOrShopper(host) ? 'focus:border-xumoAegean' : 'focus:border-xumoBerry';
  switch (type) {
    case InputType.Textarea:
      return (
        <div className="relative w-full">
          <textarea
            className={`
            flex w-full max-w-full cursor-text resize-none justify-start border-b-2 border-xumoFog pt-6 transition-all
            placeholder:text-xs hover:border-xumoSmoke
            ${focusBorder} focus:border-x-transparent focus:border-t-transparent focus:outline-none focus:outline-0 focus:placeholder:visible ${
              styleOverride.includes(' h-') ? '' : 'h-32'
            } ${styleOverride}`}
            name={name}
            value={value}
            onChange={handleChange}
            aria-errormessage={errorId}
            data-i18n={t(placeholder)}
            placeholder={t(placeholder)}
          />
        </div>
      );
    default:
      return (
        <Field
          className={`z-20
        cursor-text border-2 border-x-0 border-t-0 border-xumoFog bg-transparent outline-none transition-all
        placeholder:text-xs hover:border-xumoSmoke focus:border-opacity-[42%] ${width === 'full' ? 'w-full' : 'sm:w-2/5'} ${styleOverride}`}
          name={name}
          value={value}
          type={type}
          onChange={handleChange}
          placeholder={placeholder}
          aria-errormessage={errorId}
          data-i18n={t(placeholder)}
        />
      );
  }
};
