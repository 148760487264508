/* eslint-disable max-len */
import { motion, useSpring, useTransform, AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useInViewScroll, useWindowSize } from '../hooks';
import { mapImageLayout } from '../utils';
import { makeVariants } from '../utils/makeVariants';
import { MotionImage } from './AnimatedImage';
import { CopyProps, CopySection } from './CopySection';
import { useScrolledTheme, Button, ButtonIcon, ButtonVariant, GlobeBlock, StatsBlock, TechStackBlock } from 'ui';
import { useRouter } from 'next/router';
import Color from 'color';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { CTA, IImage } from '../types';

export type TvSplashBlockProps = {
  copyProps: CopyProps;
  backgroundColor: string;
  imageLayout: { top: Layout; bottom: Layout };
  topSwirl: IImage;
  bottomSwirl?: IImage;
  deviceImages?: IImage[];
  devicePlacement?: 'left-overlap' | 'left' | 'right-overlap' | 'right';
  ctas: CTA[];
  headingOne?: boolean;
};

type Layout = 'full-screen' | 'right' | 'right-wide';

const squiggleVariant = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

// File uploads are broken at the moment for Directus (May 5)
const fallbacks = {
  '/shop/xumo-tv': '/belair-tv.webp',
  '/shop/xumo-stream-box': '/stream-box.webp',
};
const swirlFallback = {
  '/shop/xumo-tv': '/new-swirl-1.webp',
  '/shop/xumo-stream-box': '/orange-swirl.webp',
  '/shop/streaming': '/pink-swirl.webp',
};

// TODO: Add accents to TvSplash
const colorFallback = {
  '/shop': '#396059',
  '/shop/xumo-tv': '#0029FF',
  '/shop/xumo-stream-box': '#0029FF',
  '/shop/streaming': '#0029FF',
};

// TODO: add to Directus
const video = 'https://xumo-marketing.directus.app/assets/8630252f-4f56-4c2f-b922-342de8c54a91';

const DescriptiveButton: React.FC<{ heading: string; description: string; href: string }> = ({ heading, description, href }) => {
  const { t } = useTranslation('common');
  const chevronVariant = {
    initial: { x: 0, transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
    animate: { x: 10, transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
  };

  const buttonVariant = {
    initial: { background: '#d8e7f300', transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
    animate: { background: '#d8e7f3ff', transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
  };

  return (
    <motion.div variants={buttonVariant} initial="initial" whileHover="animate" className="-ml-3 mb-1 cursor-pointer overflow-hidden rounded-lg p-3 text-black">
      <Link href={href} passHref className="flex flex-col">
        <span className="type-subheading text-black">
          <span data-i18n>{t(heading)}</span>
          <motion.svg
            variants={chevronVariant}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 622.6 657.02"
            className="ml-2 inline-block object-contain"
            style={{ width: 20, height: 20 }}
          >
            <path fill={'#06B2B6'} d="M171.96,0l246.9,328.36-247.44,328.66h193.37l247.21-328.36L365.25,.31l-.23-.31H171.96Z" />
          </motion.svg>
        </span>
        <span data-i18n className="type-body text-base text-xumoOnyx">
          {t(description)}
        </span>
      </Link>
    </motion.div>
  );
};

export const TvSplashBlock: React.FC<TvSplashBlockProps> = ({
  copyProps,
  backgroundColor,
  topSwirl,
  bottomSwirl,
  deviceImages,
  devicePlacement,
  imageLayout,
  headingOne,
  fineprint,
  imageFineprint,
  ctas
}) => {
  const { width } = useWindowSize();
  const { ref, progress: y } = useInViewScroll([0, 100], ['start 100vh', 'end 0vh']);
  const amt = useSpring(y, { stiffness: 400, damping: 90 });
  const containerY = useTransform(amt, [0, 1], ['-15vh', '0']);

  const router = useRouter();
  const fallbackImage = fallbacks[router.asPath as keyof typeof fallbacks];
  const fallbackSwirl = topSwirl.src || swirlFallback[router.asPath as keyof typeof swirlFallback];
  const fallbackColor = colorFallback[router.asPath as keyof typeof colorFallback];

  const variants = makeVariants.slideIn();
  const imgVariants = {
    initial: {
      opacity: 0,
      rotateY: '-50deg',
      x: 30, //devicePlacement === 'right-overlap' ? 30 : devicePlacement === 'left-overlap' ? -30 : 0,
    },
    animate: (i: number) => ({
      opacity: 1,
      rotateY: '-10deg',
      x: 0,
      transition: {
        type: 'spring',
        delay: 0.4 * i,
      },
    }),
  };

  const videoVariants = {
    initial: {
      opacity: 0,
      rotateY: '-60deg',
      x: 30, //devicePlacement === 'right-overlap' ? 30 : devicePlacement === 'left-overlap' ? -30 : 0,
    },
    animate: (i: number) => ({
      opacity: 1,
      rotateY: '-10deg',
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 80,
        damping: 30,
      },
    }),
  };

  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: backgroundColor,
    accent: fallbackColor || '#2AAC00',
    text: '#000000',
  });

  const videoRef = useRef();

  const [videoShown, showVideo] = useState(false);

  useEffect(() => {
    if (videoShown && videoRef.current) {
      (videoRef.current as any).play();
    }
  }, [videoShown]);

  const tvHero = router.asPath.indexOf('/products/xumo-tv') === 0 && headingOne;
  const enterprise = router.asPath.indexOf('/enterprise') === 0 && router.asPath.indexOf('/enterprise/case') !== 0;
  const enterpriseHero = enterprise && headingOne;

  return (
    <>
      <div className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 h-[115vh] overflow-hidden">
        <motion.div style={{ y: containerY }} className="container relative mx-auto h-full px-10 max-md:opacity-10">
          <AnimatePresence>
            {fallbackSwirl && componentInView && !enterprise && (
              <MotionImage
                key="squiggle"
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={squiggleVariant}
                src={fallbackSwirl}
                alt=""
                quality={85}
                sizes="(max-width: 1600px) 100vw, 75vw"
                fill
                className="relative left-[-10%] h-full  w-full object-cover object-left md:left-[50%] lg:w-auto"
              />
            )}
          </AnimatePresence>
        </motion.div>
      </div>
      <section ref={ref} className={`relative flex w-full flex-col pb-[50px] max-md:mt-[100px] md:flex-row z-10`} data-id={'tv-splash-block'}>
        <motion.div
          variants={variants}
          initial="initial"
          whileInView={'animate'}
          viewport={{ once: true }}
          className={`wrapper relative z-[2] flex flex-col items-center justify-center md:flex-row `}
          style={{color: colors.text}}
        >
          <div className="w-full text-left lg:w-2/5">
            <CopySection headingOne={headingOne && !tvHero} showRatings={tvHero} inheritColors {...copyProps} ctas={ctas} config={{ ...copyProps.config }} />
            {enterpriseHero && (
              <div className="pr-16 pt-4">
                <DescriptiveButton
                  href="#content-owners"
                  heading="Content Owners"
                  description="Build your FAST channels and guarantee the distribution and monetization of your content"
                />
                <DescriptiveButton
                  href="#distributors"
                  heading="Distributors"
                  description="Elevate your streaming channel lineup with Xumo, your comprehensive FAST channel solution"
                />
                <DescriptiveButton
                  href="#advertisers"
                  heading="Advertisers"
                  description="Xumo powers and monetizes a highly valuable network of global FAST ad inventory"
                />
              </div>
            )}
            {fineprint && <div className="type-body mt-8 text-[12px]" data-i18n={fineprint} dangerouslySetInnerHTML={{ __html: fineprint }} />}
          </div>
          <div className="relative mt-12 flex h-[300px] w-full items-center lg:mt-0 lg:h-[600px] lg:w-3/5">
            <AnimatePresence>
              {!!deviceImages?.length &&
                [deviceImages[0]].map((image, index) => (
                  <motion.div
                    custom={index}
                    variants={imgVariants}
                    initial="initial"
                    animate="animate"
                    exit="initial"
                    viewport={{ margin: '-50% 0px -50% 0px' }}
                    className={`${index === 0 ? 'max-md:mt-12 ' : ''} ${fineprint && '-mt-6'} absolute z-[10] h-[100%] w-[100%] md:left-[10%]`}
                    key={image.src || index}
                  >
                    <MotionImage
                      className={`w-full object-contain max-sm:h-full`}
                      sizes="(max-width: 768px) 100vw, (max-width: 1600px) 60vw, 50vw"
                      quality={80}
                      src={image.src}
                      alt={image.alt ?? ''}
                      priority
                      fill
                    />
                    {imageFineprint && <div className="absolute text-center w-full type-body -bottom-8 lg:-bottom-6 text-[12px]" data-i18n={imageFineprint} dangerouslySetInnerHTML={{ __html: imageFineprint }} />}
                  </motion.div>
                ))}
            </AnimatePresence>
          </div>
        </motion.div>
      </section>
      {enterpriseHero && (
        <>
          <GlobeBlock />
          <StatsBlock />
          <TechStackBlock />
        </>
      )}
    </>
  );
};
