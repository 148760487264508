import React, { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { CTA } from '../types';
import Link from 'next/link';
import Image from 'next/image';
import { SubscribeForm, SubscribeFormProps } from '.';
import { isEnterpriseOrShopper } from '../utils';
import { PageContext } from '..';
export interface FooterProps {
  sections: { heading: string; links: CTA[] }[];
  contacts?: {
    heading: string;
    ctas: CTA[];
  };
  legal: { heading: string; links: CTA[] };
  form?: SubscribeFormProps;
}

// TODO: CMSify branch rewrites
const rewriteTable = {
  'tv.xumo.com': {
    'staging': 'tv.stg.xumo.com',
    'uat': 'tv.qa.xumo.com'
  }
}

const findMatch = (link) => {
  const possibleRewrites = Object.keys(rewriteTable)
  for (let i = 0; i < possibleRewrites.length; i++) {
    const substring = possibleRewrites[i]
    if (link.includes(substring)) {
      return substring
    }
  }
}

const checkRewrite = (link) => {
  const { VERCEL_GIT_COMMIT_REF: branch } = process.env;
  const found = findMatch(link) 
  const rewrite = rewriteTable[found] && rewriteTable[found][branch]
  return rewrite
    ? link.replace(found, rewrite)
    : link
}


export const Footer: React.FC<FooterProps> = ({ sections, form, legal, contacts }: FooterProps) => {
  const { t } = useTranslation('common');

  const { pageCtx } = useContext(PageContext);
  const { host } = pageCtx;

  return (
    <footer className={`flex w-full items-center justify-center bg-xumoSmoke px-9 sm:px-24`} style={{ zIndex: 5 }} data-testid={'footer-section'}>
      <div className="container flex w-full flex-col pb-3 pt-24">
        <div className="flex w-full flex-row flex-wrap">
          {sections.map(({ links, heading }, index) => (
            <div
              key={index}
              className={`mb-4 flex w-full min-w-[175px] flex-col  flex-wrap items-start justify-start whitespace-nowrap
              transition-all max-md:w-1/3 lg:mr-6 lg:w-[calc(22%-1.5rem)]`}
            >
              <p className={`mb-2 text-left text-lg font-bold text-xumoOnyx`} data-i18n={heading} dangerouslySetInnerHTML={{ __html: t(heading) }} />
              <ul className="flex flex-col items-start justify-start">
                {links.map(({ label, href, target }, index) => (
                  <li key={index} className="flex flex-row items-start justify-start">
                    {/* TODO: Differentiate between internal and external links */}
                    <Link
                      href={checkRewrite(href)}
                      target={target}
                      className={` mr-8 h-8 text-left text-sm transition-colors ${
                        isEnterpriseOrShopper(host) ? 'text-[#333] hover:text-xumoAegean' : 'text-[#333] hover:text-xumoBerry'
                      }`}
                      passHref
                      data-i18n={label}
                      dangerouslySetInnerHTML={{ __html: t(label) }}
                    ></Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {(form || contacts) && (
            <div className="flex min-w-[130px] flex-col justify-between max-[1102px]:flex-col-reverse lg:w-1/3 min-[1102px]:-mb-[62px]">
              {form && <SubscribeForm {...form} />}
              <div className="flex flex-col justify-start pb-8 text-left min-[1102px]:mt-6">
                {contacts?.heading ? (
                  <p className="mb-2 text-lg font-bold text-xumoLicorice" dangerouslySetInnerHTML={{ __html: t(contacts.heading) }} />
                ) : null}
                {contacts?.ctas.map((cta, index) => (
                  <a
                    href={checkRewrite(href)}
                    key={index}
                    target={cta.target}
                    className={`${isEnterpriseOrShopper(host) ? 'text-xumoAegean' : 'text-xumoBerry'} break-words font-bold`}
                  >
                    {t(cta.label)}
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="self-start max-[1102px]:pt-8">
          <Image
            src={`https://${process.env.DIRECTUS_APP_ID}.directus.app/assets/4f119f10-9445-48de-b024-ec629b31b494`}
            width={124}
            height={46}
            style={{ width: 124, height: 46 }}
            alt="Xumo"
          />
        </div>
        <hr className="mb-5 flex w-full flex-row justify-between border-b-[2px] border-t-0 border-xumoGrey sm:mb-4" />
        <p className="mb-6 self-start">
          <span className="text-left text-sm text-xumoCharcoal" data-i18n={legal.heading} dangerouslySetInnerHTML={{ __html: t(legal.heading) }} />
          {legal.links.map(({ label, href, target }, index) => (
            <span key={index}>
              <span className="text-sm text-xumoCharcoal"> | </span>
              <Link
                href={checkRewrite(href)}
                target={target}
                passHref
                className="text-sm text-xumoCharcoal"
                data-i18n={label}
                dangerouslySetInnerHTML={{ __html: t(label) }}
              ></Link>
            </span>
          ))}
        </p>
      </div>
    </footer>
  );
};
