import { DirectusNewsBlock } from 'config';
import { NewsBlockProps } from '../../../components/NewsBlock';
import { mapDirectusImage, mapDirectusCTAs } from '../general';
import websiteContent from 'config/xumo-website-output.json';

export const mapDirectusNewsBlock = (gallery: DirectusNewsBlock): NewsBlockProps => ({
  heading: gallery.heading,
  body: gallery.body,
  cta: gallery.cta?.length ? mapDirectusCTAs(gallery.cta, null, true) : undefined,
  stories: [...websiteContent.press, ...websiteContent.links]
    .sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
    .slice(0, gallery.max_items)
    .map(news => ({
      ...news,
      thumbnail: mapDirectusImage(news.thumbnail),
    })),
});

export const mapDirectusPressBlock = (press): NewsBlockProps => ({
  title: press.title,
  date: press.date,
  description: press.description,
  content: press.content,
  thumbnail: mapDirectusImage(press.thumbnail),
});
