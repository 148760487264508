import { motion, useTransform, useSpring, MotionValue } from 'framer-motion';
import { useState } from 'react';
import { IImage } from '../types';
import { MotionImage } from './AnimatedImage';

export type BubbleProps = {
  image?: IImage;
  position?: Position;
  scale?: number;
  variants: Record<string, any>;
  index: number;
  progress?: MotionValue<number>;
};

export type Position = {
  x: number;
  y: number;
};

export const Bubble = ({ image, noBubble, index, position, progress, variants }: BubbleProps) => {
  const [isBubbleHovered, setIsBubbleHovered] = useState(false);
  const aspectRatio = image.width / image.height;

  return (
    <motion.div key={'bubble-inner-' + index} className="relative m-2 mb-4 flex items-center justify-center" variants={variants}>
      {!noBubble ? (
        <motion.div className="relative h-[60px] w-[60px] p-6 lg:h-[80px] lg:w-[80px] xl:h-[100px] xl:w-[100px]">
          <MotionImage
            fill
            src={image.src ?? ''}
            alt={image.alt ?? ''}
            sizes="(max-width: 768px) 15vw, (max-width: 1400px) 12vw, 10vw"
            className="overflow-hidden rounded-full object-contain opacity-100 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] will-change-transform"
          />
        </motion.div>
      ) : (
        <motion.div className="relative m-2 h-[20px] lg:m-6 lg:h-[30px] " style={{ aspectRatio }}>
          <MotionImage
            fill
            src={image.src}
            alt={image.alt ?? ''}
            sizes="(max-width: 768px) 20vw, (max-width: 1400px) 15vw, 10vw"
            className="h-full w-full overflow-hidden object-contain opacity-100 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] will-change-transform"
          />
        </motion.div>
      )}
    </motion.div>
  );
};
