import { SUPPORTED_LOCALES } from '../utils/locales';

import websiteContent from 'config/xumo-website-output.json';
import { DirectusPage } from 'config';
import { Website } from '../types';

export interface GetGeneralPageStaticPathsArgs {
  website: Website.Corporate | Website.Enterprise | Website.Shopper;
}

export interface GetGeneralPageStaticPathsItem {
  params: {
    slug: string[];
  };
  locale: string;
}

export const getGeneralPageStaticPaths =
  ({ website }: GetGeneralPageStaticPathsArgs) =>
  async (): Promise<{ paths: GetGeneralPageStaticPathsItem[]; fallback: boolean }> => {
    const data = websiteContent.content as DirectusPage[];
    const pressData = websiteContent.press;

    // Converts the slug into an array of strings, removing any empty values
    let pages: string[][] = data
      .map(({ slug }: { slug?: string }) => (slug ? slug.split('/') : null))
      .filter((slug): slug is string[] => !!slug && !(slug[0] === '404'));

    let press: string[][] = pressData.map(({ slug }: { slug?: string }) => slug.split('/'));

    let routes: string[][] = [...pages];

    const paths = [];

    const allLocalePaths = SUPPORTED_LOCALES;

    for (let i = 0; i < SUPPORTED_LOCALES.length; i++) {
      const locale = allLocalePaths[i];

      for (let j = 0; j < routes.length; j++) {
        const route = routes[j];

        paths.push({
          params: {
            slug: route,
          },
          locale: locale || 'en',
        });
      }
    }

    for (let j = 0; j < press.length; j++) {
      const route = press[j];

      paths.push({
        params: {
          slug: route,
          press: true,
        },
        locale: 'en',
      });
    }

    return {
      paths,
      fallback: false,
    };
  };
