import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { DataLayer } from '../components/AdobeAnalytics';

export interface ClickEvent {
  eventMethod: string;
  eventPage: string;
  eventName: string;
  type?: string;
  target?: string;
  category?: string;
  action?: string;
  label?: string;
  value?: number;
}

export const useClickTracking = () => {
  const [events, setEvents] = useState<ClickEvent[]>([]);

  const trackClick = useCallback((event: ClickEvent) => {
    setEvents(prevEvents => [...prevEvents, event]);
  }, []);

  useEffect(() => {
    const handleDataLayerEvent = () => {
      // Iterate through the events in the state and send click events to Adobe Analytics
      events.forEach(event => {
        if (event.type === 'click') {
          // TODO: Add Adobe Analytics tracking code here for click events
          console.log('Click event:', event);
        }
      });

      // Clear the events after processing
      setEvents([]);
    };

    // Listen for the custom event
    document.addEventListener('c-tracking-log-click', handleDataLayerEvent);

    return () => {
      document.removeEventListener('c-tracking-log-click', handleDataLayerEvent);
    };
  }, [events]);

  return { trackClick };
};

export const useAdobePageAnalytics = title => {
  const router = useRouter();

  const handleLoad = () => {
    document.dispatchEvent(
      new window.CustomEvent('c-tracking-log-page', {
        bubbles: true,
        detail: {
          screenName: title.toLowerCase(),
        },
      }),
    );
  };

  const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
    handleLoad();
    document.dispatchEvent(
      new window.CustomEvent('c-tracking-log-page-start', {
        bubbles: true,
        detail: { url, shallow }, // url is the new URL, shallow is true if the route change was shallow
      }),
    );
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
