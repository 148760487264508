import { DirectusDisclaimer } from 'config';
import { DisclaimerProps } from '../../..';

export const mapDirectusDisclaimer = ({ disclaimer, top, align, offset }: DirectusDisclaimer): DisclaimerProps => {
  return {
    disclaimer,
    top: top ? parseInt(`${top}`, 10) : top,
    align: align as 'right' | 'left' | 'center' | undefined,
    offset: offset ? parseInt(`${offset}`, 10) : offset,
  };
};
