import { DirectusVerboseDeviceShowcase } from 'config';
import { VerboseDeviceShowcaseProps } from '../../../components';
import { mapDirectusCTAs, mapDirectusImage } from '../general';

export const mapDirectusVerboseDeviceShowcase = (verboseDeviceShowcase: DirectusVerboseDeviceShowcase): VerboseDeviceShowcaseProps => {
  const ctas = mapDirectusCTAs(verboseDeviceShowcase?.cta_items ?? [], undefined, true);

  return {
    backgroundColor: verboseDeviceShowcase.background_color ?? '#fff',
    primary: verboseDeviceShowcase.primary,
    heading: verboseDeviceShowcase.heading,
    body: verboseDeviceShowcase.body,
    imageLeft: mapDirectusImage(verboseDeviceShowcase.imageLeft),
    imageTop: mapDirectusImage(verboseDeviceShowcase.imageTop),
    imageBottom: mapDirectusImage(verboseDeviceShowcase.imageBottom),
    imageMobile: mapDirectusImage(verboseDeviceShowcase.imageMobile),
    iosAppCta: ctas.find(c => c.label.includes('ios')) || ctas[0],
    androidAppCta: ctas.find(c => c.label.includes('android')) || ctas[1] || ctas[0],
    ctaImage: mapDirectusImage(verboseDeviceShowcase.ctaImage),
  };
};
