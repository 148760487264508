import { DirectusSubscibeBlock } from 'config';
import { SubscibeBlockProps } from '../../../components';

export const mapDirectusSubscibeBlock = (block: DirectusSubscibeBlock): SubscibeBlockProps => {
  return {
    heading: block.heading,
    placeholder: block.placeholder_text,
    buttonText: block.button_text,
    backgroundColor: block.background_color,
    accent: block.accent,
  };
};
