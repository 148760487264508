import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import { PageContext } from '../contexts';
import { CTA } from '../types';
import { isEnterpriseOrShopper } from '../utils';
import { IFormField, XumoForm } from './';

export type SubscribeFormProps = {
  heading: string;
  CTA: CTA;
  field: IFormField;
};

export const SubscribeForm: React.FC<SubscribeFormProps> = ({ heading, CTA, field }) => {
  const { t } = useTranslation('common');

  const { pageCtx } = useContext(PageContext);
  const { host } = pageCtx;

  return (
    <div className="flex w-full flex-col">
      <p
        className={`mb-4 self-start text-lg font-bold text-xumoLicorice sm:whitespace-nowrap`}
        data-i18n={heading}
        dangerouslySetInnerHTML={{ __html: t(heading) }}
      />
      <XumoForm
        formFields={[{ ...field, width: 'full' }]}
        formAction={{ submissionEndpoint: '/items/subscribe', successMessage: 'Susccessfully subscribed' }}
        CTA={CTA}
        styleOverrides={{
          formField: `w-full placeholder:text-xumoCoal text-xumoLicorice hover:border-b-xumoJet focus:border-opacity-[32%]  ${
            isEnterpriseOrShopper(host) ? 'caret-xumoAegean focus:border-b-xumoAegean' : 'caret-xumoBerry border-b-xumoBerry'
          }`,
          CTA: {
            general: `border-0 text-white px-6 py-2`,
            disabledColor: 'bg-xumoCharcoal',
            enabledColor: isEnterpriseOrShopper(host) ? 'bg-xumoAegean' : 'bg-xumoBerry',
          },
          form: `justify-start items-start`,
        }}
      />
    </div>
  );
};
