import { DirectusTwoColumn } from 'config';
import { getRGBAFromHex, TwoColumnProps } from '../../..';
import { mapDirectusImage, DirectusImageAsset } from '../general';
import { mapDirectusCTAs } from '../general/ctas';

export const mapDirectusTwoColumnBlock = (twoColumn: DirectusTwoColumn): TwoColumnProps => {
  const imagePlacementFromTextPlacementMap = {
    right: 'left',
    left: 'right',
    'left-overlap': 'right-overlap',
    'right-overlap': 'left-overlap',
  };
  return {
    backgroundColor: twoColumn.background_color ?? '#000',
    textColor: twoColumn.text_color ?? '#000',
    heading: twoColumn.heading,
    body: twoColumn.body,
    contentType: twoColumn.content_type as 'chevron' | 'tv' | 'icon' | '',
    image: mapDirectusImage(twoColumn.image),
    subImage: mapDirectusImage(twoColumn.subimage),
    imagePosition: imagePlacementFromTextPlacementMap[
      (twoColumn.textPlacement as 'left' | 'right' | 'left-overlap' | 'right-overlap' | undefined) ?? 'left'
    ] as 'left' | 'right' | 'left-overlap' | 'right-overlap' | undefined,
    cta: mapDirectusCTAs(twoColumn?.cta_items ?? []),
  };
};
