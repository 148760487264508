export const Widths = {
  fit: 'w-fit',
  xxs: 'w-xxs',
  xs: 'w-xs',
  sm: 'w-sm',
  md: 'w-md',
  lg: 'w-lg',
  xl: 'w-xl',
  auto: 'w-auto',
};
