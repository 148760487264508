import { DirectusCarousel } from 'config';
import { CarouselProps } from '../../..';
import { mapDirectusImage } from '../general';

export const mapDirectusCarousel = ({ heading, body, backgroundColor, accent, image_items }: DirectusCarousel): CarouselProps => {
  return {
    heading: heading,
    body: body,
    images: image_items ? image_items.map(i => mapDirectusImage(i.item.image)) : [],
    backgroundColor: backgroundColor,
    accent,
  };
};
