//@ts-nocheck
/* eslint-disable max-len */
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { CTA, IImage } from '../types/common';
import { useWindowSize } from '../hooks';
import { makeVariants } from '../utils/makeVariants';
import { Button, ImageWithBackground, MotionImage, SplitDiv } from './';
import { useScrolledTheme } from 'ui';
import Head from 'next/head';

export interface CtaColumnsProps {
  bgColor: string;
  textColor: string;
  accent: string;
  columns?: CtaColumn[];
  heading?: string;
  body?: string;
  columnAlign?: string;
  headingAlign?: string;
  ctaAlign?: string;
  iconSize?: 'small' | 'medium' | 'large';
  withDivider?: boolean;
  imageBgFill?: boolean;
  largeColHeaders?: boolean;
}

export interface CtaColumn {
  icon?: IImage;
  iconBackground?: IImage;
  superText?: string;
  heading?: string;
  body?: string;
  cta?: CTA;
}

export const CtaColumns: React.FC<CtaColumnsProps> = ({
  bgColor,
  textColor,
  accent,
  columns,
  heading,
  body,
  columnAlign,
  headingAlign,
  ctaAlign,
  iconSize = 'small',
  withDivider = true,
  imageBgFill = false,
  largeColHeaders = false,
}) => {
  const { t } = useTranslation('common');
  const variants = makeVariants.slideIn();
  const { width } = useWindowSize();

  const ref = useRef();
  const { componentInView } = useScrolledTheme({
    ref,
    background: bgColor,
    accent: '#000000',
    text: textColor,
  });

  const getItemListJsonLd = () => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: columns.map(({ heading, body, icon }, index) => {
        return {
          '@type': 'ListItem',
          position: index + 1,
          name: heading,
          description: body,
          image: icon,
        };
      }),
    };

    return {
      __html: JSON.stringify(obj),
    };
  };

  const hasIntroCopy = !!heading || !!body;

  return (
    <motion.section ref={ref} className="relative mx-auto w-full" data-testid={'cta-columns-section'}>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getItemListJsonLd()} />
      </Head>
      <div className={`z-1 wrapper relative mx-auto ${hasIntroCopy ? 'py-16' : 'py-16 lg:py-24'}`}>
        {heading && (
          <motion.h2
            variants={variants}
            initial="initial"
            whileInView="animate"
            className="type-heading z-10"
            style={{
              textAlign: headingAlign,
              color: textColor,
            }}
            data-i18n={heading}
          >
            {t(heading)}
          </motion.h2>
        )}
        {body && (
          <motion.div
            variants={variants}
            initial="initial"
            whileInView="animate"
            className="allow-html-styles type-body w-full pt-8 md:w-1/2"
            dangerouslySetInnerHTML={{ __html: t(body) }}
            style={{
              textAlign: headingAlign,
              color: textColor,
            }}
            data-i18n={body}
          />
        )}
        <motion.div
          initial="initial"
          whileInView="animate"
          variants={variants}
          viewport={{ once: true, margin: '-25% 0% -25% 0%' }}
          className={`flex w-full flex-col flex-wrap items-start max-md:space-y-8 md:flex-row md:justify-between lg:space-x-6 ${
            body ? 'max-sm:pt-8 md:pt-16' : !hasIntroCopy ? '' : 'md:pt-24'
          }`}
        >
          {
            // eslint-disable-next-line sonarjs/cognitive-complexity
            columns.map(({ icon, iconBackground, body, heading, superText, cta }) => {
              return (
                <motion.div
                  initial="initial"
                  whileInView="animate"
                  variants={variants}
                  viewport={{ once: true }}
                  className={`flex min-w-[110px] shrink grow basis-0 flex-row flex-col first:border-0  max-lg:w-full max-md:border-t max-md:pt-16 max-md:first:mt-0 max-md:first:border-0 max-[720px]:self-center max-sm:mt-8 md:border-l md:pl-8 md:first:pl-0 md:last:pr-0 
                  ${columnAlign === 'left' ? 'items-start' : 'items-center'}
                  ${heading ? 'justify-between md:justify-start' : 'justify-start'}`}
                  style={{ borderColor: withDivider ? accent || textColor : 'transparent' }}
                  key={body || heading}
                >
                  {icon &&
                    (imageBgFill || iconBackground?.src ? (
                      <ImageWithBackground image={icon} backgroundImage={iconBackground} size={'small'} accent={bgColor} variants={variants} />
                    ) : (
                      <MotionImage
                        className={`relative mb-4 shrink-0 translate-x-0 overflow-hidden rounded-xl object-contain opacity-100 ${
                          !withDivider && 'self-start'
                        } ${iconSize === 'small' ? 'w-[75px]' : iconSize === 'medium' ? 'mb-5 w-[40%]' : 'mb-5 w-[100%]'}`}
                        variants={variants}
                        sizes="(max-width: 768px) 100vw, (max-width: 1600px) 50vw, 33vw"
                        src={icon.src}
                        alt={icon.alt}
                        fill
                      />
                    ))}
                  <div
                    className={`${columnAlign === 'left' ? 'items-start' : 'items-center'} flex
                  flex-col max-md:w-1/2 max-md:justify-center max-md:justify-self-end max-md:pl-4 max-sm:w-full max-sm:pl-0`}
                  >
                    {superText && (
                      <SplitDiv
                        content={superText}
                        className="allow-html-styles type-body md:w-4/5 md:whitespace-nowrap"
                        variants={variants}
                        style={{ textAlign: columnAlign, color: textColor }}
                        data-i18n={superText}
                      />
                    )}
                    {heading && (
                      <motion.div
                        className={`${largeColHeaders ? 'type-title' : 'type-subheading'} my-2`}
                        style={{ color: textColor, textAlign: width <= 768 ? 'center' : columnAlign }}
                        variants={variants}
                        data-i18n={heading}
                        dangerouslySetInnerHTML={{ __html: t(heading) }}
                      />
                    )}
                    {body && (
                      <motion.div
                        content={body}
                        className={`allow-html-styles type-body list-outside list-disc ${body.length < 25 ? 'md:whitespace-nowrap' : ''} md:w-4/5`}
                        variants={variants}
                        style={{ textAlign: columnAlign, color: textColor }}
                        data-i18n={body}
                        dangerouslySetInnerHTML={{ __html: t(body) }}
                      />
                    )}
                    {cta && (
                      <Button
                        label={cta.label}
                        href={cta.href}
                        buttonType="link-primary"
                        key={cta.label}
                        styleOverrides={{
                          css: { backgroundColor: accent },
                          tailwind: `${ctaAlign === 'left' ? 'align-self-left' : ''} mt-5 w-48 h-8 py-5 text-sm leading-[14px]	`,
                        }}
                        data-i18n={cta.label}
                      />
                    )}
                  </div>
                </motion.div>
              );
            })
          }
        </motion.div>
      </div>
    </motion.section>
  );
};
