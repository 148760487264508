import { DirectusItemWrapper, DirectusFormFieldItems } from 'config';
import { IFormField, InputType } from '../../..';
import _ from 'lodash';

export const mapDirectusFormFields = (items: DirectusItemWrapper<DirectusFormFieldItems>[]): IFormField[] => {
  return items.map(i => {
    const fieldType = InputType[_.capitalize(i.item.type.replace(/\s+/g, '')) as keyof typeof InputType];
    if (!fieldType) throw new Error('Invalid field type: ' + i.item.type);
    return {
      type: fieldType,
      name: i.item.name,
      placeholder: i.item.placeholder,
      width: i.item.width as 'half' | 'full',
      initialValue: i.item.initial_value ?? '',
    };
  });
};
