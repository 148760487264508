import React from 'react';
import { useTranslation } from 'next-i18next';
import { makeVariants } from '../utils/makeVariants';
import { motion } from 'framer-motion';

export type TwoColumnTextProps = {
  heading: string;
  body: string;
  styleOverrides?: {
    heading?: string;
    body?: string;
    references?: string;
  };
};

export const TwoColumnText = ({ heading, body, styleOverrides }: TwoColumnTextProps) => {
  const { t } = useTranslation('common');
  const variants = makeVariants.slideIn();

  return (
    <motion.div variants={variants} className="flex flex-col items-start justify-between pt-16 md:flex-row">
      <h2 className={`${styleOverrides?.heading} type-heading pb-8 text-left md:w-2/5`} data-18n={heading} dangerouslySetInnerHTML={{ __html: t(heading) }} />
      <div
        className={`flex flex-col items-start justify-start text-left text-black md:w-1/2 ${styleOverrides?.body} type-body allow-html-styles`}
        dangerouslySetInnerHTML={{ __html: t(body) }}
      />
    </motion.div>
  );
};
