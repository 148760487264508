/* eslint-disable max-len */
import { motion, useSpring, useTransform, AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useInViewScroll, useWindowSize } from '../hooks';
import { mapImageLayout } from '../utils';
import { makeVariants } from '../utils/makeVariants';
import { MotionImage } from './AnimatedImage';
import { CopyProps, CopySection } from './CopySection';
import { useScrolledTheme, Button, ButtonIcon, ButtonVariant, GlobeBlock, StatsBlock, TechStackBlock } from 'ui';
import { useRouter } from 'next/router';
import Color from 'color';
import Link from 'next/link';

import { CTA, IImage } from '../types';

export const VideoModal: React.FC<any> = ({ anchor, video }) => {
  const videoRef = useRef();

  // TODO: convert to hook -> const anchorActive = useActiveAnchor(anchor)
  const router = useRouter();
  const anchorIsActive = anchor && new URL('https://a.a' + router.asPath).hash === '#' + anchor;

  useEffect(() => {
    if (anchorIsActive && videoRef.current) {
      (videoRef.current as any).play();
    }
  }, [anchorIsActive]);

  return (
    <>
      <AnimatePresence>
        {anchorIsActive && (
          <motion.div
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1, transition: { restDelta: 0.001 } }}
            exit={{ opacity: 0, scale: 1.1 }}
            className="align-center fixed bottom-0 left-0 right-0 top-0 z-50 flex h-screen flex-col items-center justify-center overflow-hidden bg-[#000000ee] "
          >
            <div className="container relative flex aspect-video h-[50vh] max-h-[50vh] grow-0 flex-col justify-center lg:w-max">
              <video controls ref={videoRef as any} src={video.src} className="aspect-video h-full grow-0 object-contain" />
              <div>
                <Button
                  label={'Close video'}
                  href="#"
                  buttonType={'primary'}
                  styleOverrides={{
                    tailwind: 'm-4 px-4 py-2 m-2 text-black bg-white',
                  }}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
