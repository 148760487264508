import { Website } from '../types';

export const isEnterprise = (hostName: string) => {
  try {
    return hostName.toLowerCase().includes(Website.Enterprise) || hostName.includes('3000');
  } catch (error) {
    throw new Error('Unable to determine if enterprise; please check that you are pulling in the host name correctly.');
  }
};

export const isEnterpriseOrShopper = (hostName: string) => {
  if (hostName === undefined) return true;

  // check that hostName is a valid Website
  if (!Object.values(Website).includes(hostName as Website)) {
    throw new Error('Invalid host name');
  }

  return [Website.Enterprise, Website.Shopper].includes(hostName as Website);
};
