export const convertRGBAHexToRGB = (rgbaHex: string): string => {
  if (rgbaHex && rgbaHex.length === 9) {
    const { values } = hexToRGBA(rgbaHex);

    const r = (1 - values.a) * 255 + values.a * values.r;
    const g = (1 - values.a) * 255 + values.a * values.g;
    const b = (1 - values.a) * 255 + values.a * values.b;

    return `rgb(${r}, ${g}, ${b})`;
  }

  return rgbaHex;
};

export const hexToRGBA = (hex: string): { color: string; values: { r: number; g: number; b: number; a: number } } => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16),
    a = parseFloat(parseFloat(`${parseInt(hex.slice(7, 9), 16) / 255}`).toFixed(2));

  return { color: 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')', values: { r, g, b, a } };
};
