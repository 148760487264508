import { DirectusSwirlBlock } from 'config';
import { convertRGBAHexToRGB, getRGBAFromHex } from '../..';
import { SwirlBlockProps } from '../../../components';
import { DirectusImageAsset, mapDirectusCTAs, mapDirectusImage } from '../general';
import { mapDirectusInitialPositionItems } from '../general/position';

export const mapDirectusSwirlBlock = (swirlBlock: DirectusSwirlBlock): SwirlBlockProps => {
  const { copy_blocks } = swirlBlock;

  return {
    backgroundColor: convertRGBAHexToRGB(swirlBlock.background_color ?? '#fff'),
    heading: swirlBlock.heading,
    primary: swirlBlock.primary,
    bullets: swirlBlock.bullet_items?.length
      ? swirlBlock.bullet_items.map(bullet => ({
          heading: bullet.item.heading,
          body: bullet.item.body,
          cta: bullet.item.cta_items?.length ? mapDirectusCTAs(bullet.item.cta_items) : undefined,
        }))
      : undefined,
    secondary: swirlBlock.secondary,
    coloredText: swirlBlock.coloredText,
    style: swirlBlock.style as 'left' | 'right' | 'center',
    subTitle: swirlBlock.subTitle,
    bgImage: swirlBlock.bgImage ? mapDirectusImage(swirlBlock.bgImage) : undefined,
    body: swirlBlock.body,
    cta: swirlBlock.cta_items?.length ? mapDirectusCTAs(swirlBlock.cta_items, null, true) : undefined,
    initialPosition: mapDirectusInitialPositionItems(swirlBlock?.position_items),
    copyBlocks: copy_blocks?.length
      ? copy_blocks.map(({ chevron_block_copy_items_id: item }) => ({
          body: item.body,
          heading: item.heading,
          ctas: item?.cta_items ? mapDirectusCTAs(item?.cta_items, null, true) : [],
        }))
      : [],
  };
};
