import { IFormField } from '..';

/**
 * Maps array of dynamic form inputs into subarrays, while .
 * NOTE: Given the functionality of FormFields and its implementation in the LeadGenForm,
 * IT WILL BREAK the UI if the firstName or lastName fields aren't at an even index in the list of form items.
 * */
export const chunkFormInput = (array: IFormField[], size: number): Array<any> => {
  if (!array) return [];

  // create the first chunk of the given array, filtering full-width inputs out of subarray
  const firstChunk = [
    array.slice(0, size).filter(input => 'half' === input.width || typeof input.width === 'undefined'),
    ...array.slice(0, size).filter(input => 'full' === input.width), // Don't put full-length inputs into an array; spread them out
  ].filter(item => !Array.isArray(item) || item.length);

  if (Array.isArray(firstChunk[0]) && firstChunk[0].length === 0) {
    return firstChunk;
  }

  // base case terminates recursion
  if (!firstChunk.length) return array;

  return firstChunk.concat(chunkFormInput(array.slice(size, array.length), size));
};
