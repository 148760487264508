import React, { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { NewsStory, NewsStoryProps } from './NewsStory';
import { motion } from 'framer-motion';
import { useScrolledTheme, MotionImage } from 'ui';

export type PressBlockProps = {
  heading: string;
  body?: string;
  stories: NewsStoryProps[];
};

const variants = {
  initial: (i: number) => ({
    opacity: 0,
    x: -40,
  }),
  animate: (i: number) => ({
    x: 0,
    y: 0,
    opacity: 1,
    transition: { staggerChildren: 0.075, ease: 'easeInOut', duration: 0.5 },
  }),
};

export const PressBlock = ({ title, date, description, content, thumbnail }) => {
  const { t, locale } = useTranslation('common');

  const ref = useRef();
  const { componentInView } = useScrolledTheme({
    ref,
    background: '#F4E6E6',
    accent: '#BB2E76',
    text: '#000000',
  });

  const variants = {
    initial: {
      opacity: 0,
      y: 20,
      transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 },
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: { staggerChildren: 0.1, type: 'spring', damping: 65, stiffness: 900, mass: 1 },
    },
  };

  return (
    <motion.section
      ref={ref}
      initial="initial"
      animate="animate"
      variants={variants}
      className="wrapper flex w-full flex-col items-start justify-center py-6 max-lg:mt-[75px] lg:flex-row"
    >
      <motion.div variants={variants} className={`mb-4 pr-16 text-left lg:basis-2/5`}>
        <h1 className={`type-heading mb-8 text-xumoBerry`} dangerouslySetInnerHTML={{ __html: t(title) }} />
        <time datetime={date} className="type-body">
          {new Date(date).toLocaleDateString(locale, { month: 'long', day: 'numeric', year: 'numeric' })}
        </time>
      </motion.div>
      <div className={`lg:basis-3/5`}>
        <motion.div variants={variants} className={`type-body text-left text-md lg:text-xl`} dangerouslySetInnerHTML={{ __html: description }} />
        <motion.div className={`relative my-6 w-full overflow-hidden rounded-lg`} style={{ aspectRatio: thumbnail.width / thumbnail.height }}>
          <MotionImage
            variants={variants}
            src={thumbnail.src}
            alt={thumbnail.alt ?? ''}
            fill
            className="object-contain object-left"
            sizes={'(max-width: 768px) 100vw, (max-width: 1600px) 60vw, 40vw'}
            priority
          />
        </motion.div>
        <motion.div variants={variants} className={`type-body allow-html text-left`} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </motion.section>
  );
};
