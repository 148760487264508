import { Variants, motion } from 'framer-motion';
import Image from 'next/image';
import { IImage, MotionImage } from '../';

type ImageWithBackgroundProps = {
  image: IImage;
  backgroundImage: IImage;
  size: 'small' | 'medium' | 'large';
  variants?: Variants;
  accent?: string;
};

export const ImageWithBackground = ({ image, backgroundImage, size, variants, accent = '#BB2E76' }: ImageWithBackgroundProps) => {
  return (
    <motion.div
      variants={variants}
      className={`relative shrink-0 overflow-hidden rounded-xl ${
        size === 'small'
          ? 'h-[120px] max-h-[120px] min-w-[200px] max-w-[200px]  md:w-full'
          : size === 'medium'
          ? 'h-[160px] max-h-[160px] min-w-[200p]  max-w-[200px] md:w-full'
          : ' h-full min-h-[233px] min-w-[331px]  max-md:mb-12 md:ml-12'
      }  grow-0 items-center justify-center pt-2 md:mb-4 `}
      style={{ backgroundColor: accent ?? undefined }}
    >
      {backgroundImage?.src && (
        <Image
          className="absolute inset-0 h-full w-full"
          src={backgroundImage?.src}
          alt={backgroundImage?.alt || 'background'}
          style={{
            objectFit: 'cover',
          }}
          fill
        />
      )}
      <MotionImage
        className={`absolute-center object-contain ${size === 'small' ? 'w-[75px]' : 'w-[40%]'}`}
        variants={variants}
        src={image.src}
        alt={image.alt || 'icon'}
        fill
      />
    </motion.div>
  );
};
