/* eslint-disable react/react-in-jsx-scope */
import Head from 'next/head';
import { useAdobePageAnalytics } from '../hooks';

export type DataLayer = Record<string, string | ((args: any) => void)>;
type AdobeAnalyticsProps = {
  path: string;
  locale: string;
};

export const AdobeAnalytics = ({ path, locale, title }: AdobeAnalyticsProps) => {
  const pathSegments = path.split('/');
  const dataLayer: DataLayer = {
    category: JSON.stringify({
      category: {
        primaryCategory: 'xumo',
        subCategory1: pathSegments.length ? pathSegments[0] : 'home',
        subCategory2: pathSegments.length > 1 ? pathSegments[1] : undefined,
        businessType: 'enterprise',
        siteType: 'sales',
        designType: 'responsive',
      },
    }),
    affiliate: JSON.stringify({
      affiliate: {
        channel: 'web',
        name: 'comcast',
      },
    }),
    pageInfo: JSON.stringify({
      pageInfo: {
        language: locale,
        pageName: title,
        screenName: title,
      },
    }),
    codeBase: JSON.stringify({
      codebase: {
        name: 'Xumo marketing page',
        releaseVersion: '1.0.0',
      },
    }),
  };

  const adobeUrl =
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? 'https://static.cimcontent.net/data-layer/'
      : 'https://static.cimcontent.net/data-layer/nonprod';

  // Handles route changes
  useAdobePageAnalytics(title);

  return (
    <Head>
      <script src={adobeUrl} type="text/javascript" async />
      <script id={'schema-version'} type="tracking-data-digitalData" dangerouslySetInnerHTML={{ __html: `{ schemaVersion: '0.21.1' }` }} async />
      {!!Object.keys(dataLayer).length &&
        Object.keys(dataLayer).map((key: keyof DataLayer, index) => {
          if (!(typeof dataLayer[key] === 'string')) {
            return null;
          }
          return (
            <script key={index} id={`data-page-${key}`} type="tracking-data-page">
              {dataLayer[key]}
            </script>
          );
        })}
    </Head>
  );
};
