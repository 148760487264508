import { DirectusFaqBlock } from 'config';
import { FaqBlockProps } from '../../../components';
import { mapDirectusImage } from '../general';

export const mapDirectusFaqBlock = (block: DirectusFaqBlock): FaqBlockProps => {
  return {
    backgroundColor: block.background_color ?? 'white',
    heading: block.heading,
    questions: block.faq_items.map(question => ({
      heading: question.item.question,
      answer: question.item.answer,
    })),
    searchPlaceholder: block.search_bar_placeholder,
    backgroundImage: mapDirectusImage(block.background_image),
  };
};
