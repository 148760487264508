import React from 'react';

export type AnchorProps = {
  anchor: string;
  top?: number;
};

const Anchor: React.FC<AnchorProps> = ({ anchor, top = 0 }) => {
  return <div id={anchor} className="relative w-full" style={{ top }} />;
};

export default Anchor;
