import { motion } from 'framer-motion';
import { ResponsiveScrollSpringProps, useResponsiveScrollSpring } from '../hooks';
import { IImage } from '../types';
import Image from 'next/image';

type DeviceProps = {
  image: IImage;
  styleOverrides: string;
  windowSize: { height: number; width: number };
  configOverrides?: Partial<ResponsiveScrollSpringProps>;
  id?: string;
};

export const DeviceImage = ({ image, styleOverrides, windowSize, configOverrides, id }: DeviceProps) => {
  const MIN_WINDOW_SIZE = 640;
  const config = {
    mobile: { attribute: 'x' as 'x', values: [-200, 200] },
    desktop: { attribute: 'y' as 'y', values: [75, -75] },
    offset: ['start 125vh', 'end 0vh'],
    breakpointConfig: { MIN_WINDOW_SIZE, windowSize },
    springConfig: { stiffness: 400, damping: windowSize.width > MIN_WINDOW_SIZE ? 90 : 200 },
    ...(configOverrides ?? {}),
  };
  const { ref, spring } = useResponsiveScrollSpring(config);

  return (
    <motion.div id={id} ref={ref} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} style={spring} className={styleOverrides}>
      <Image className="h-full w-full max-w-none object-contain" src={image.src} fill alt={image.alt ?? ''} />
    </motion.div>
  );
};
