export enum Website {
  Enterprise = 'enterprise',
  Corporate = 'corporate',
  Shopper = 'shopper',
  Play = 'play',
}

export enum MetaProperty {
  // base properties for search. they require the 'name' attribute
  Title = 'title',
  Description = 'description',
  Image = 'image',
  Locale = 'locale',
  ViewPort = 'viewport',
  Keywords = 'keywords',

  // Open Graph; they require the 'property' attribute
  OgTitle = 'og:title',
  OgImage = 'og:image',
  OgUrl = 'og:url',
  OgType = 'og:type', // Required for FB
  // <--  Non-Essential, But Recommended -->
  OgDescription = 'og:description',
  OgSiteName = 'og:site_name',
  // <--  Non-Essential-->
  OgDeterminer = 'og:determiner',
  OgLocale = 'og:locale',
  OgLocaleAlternate = 'og:locale:alternate',
  OgImageWidth = 'og:image:width',
  OgImageHeight = 'og:image:height',
  OgImageAlt = 'og:image:alt',
  OgVideo = 'og:video',
  OgVideoType = 'og:video:type',
  OgVideoWidth = 'og:video:width',
  OgVideoHeight = 'og:video:height',
  OgVideoSecureUrl = 'og:video:secure_url',
  OgVideoUrl = 'og:video:url',
  OgAudioSecureUrl = 'og:audio:secure_url',
  OgArticlePublishedTime = 'article:published_time',
  OgArticleModifiedTime = 'article:modified_time',
  OgArticleExpirationTime = 'article:expiration_time',
  OgArticleAuthor = 'article:author',
  OgArticleSection = 'article:section',
  OgArticleTag = 'article:tag',

  // Facebook
  FbAppId = 'fb:app_id',
  FbPages = 'fb:pages',
  FbAdmins = 'fb:admins',
  FbProfileId = 'fb:profile_id',
  FbPublisher = 'fb:publisher',

  // Apple
  AppleMobileWebAppCapable = 'apple-mobile-web-app-capable',
  AppleMobileWebAppStatusBarStyle = 'apple-mobile-web-app-status-bar-style',
  AppleMobileWebAppTitle = 'apple-mobile-web-app-title',
  AppleTouchFullscren = 'apple-touch-fullscreen',
  AppleTouchIcon = 'apple-touch-icon',
  AppleTouchIconPrecomposed = 'apple-touch-icon-precomposed',
  AppleTouchStartupImage = 'apple-touch-startup-image',

  // Google
  GooglePlayApp = 'google-play-app',
  GoogleSiteVerification = 'google-site-verification',
  GoogleAnalytics = 'google-analytics',
  GoogleSearchConsole = 'google-search-console',
  Google = 'google',
  Googlebot = 'googlebot',
  GooglebotNews = 'googlebot-news',
  GooglebotVideo = 'googlebot-video',
  GooglebotImage = 'googlebot-image',
  GooglebotMobile = 'googlebot-mobile',
  GooglebotSecure = 'googlebot-secure',
  GooglebotPreview = 'googlebot-preview',
  GooglebotSiteVerification = 'googlebot-site-verification',
  GooglebotNewsKeywords = 'googlebot-news:keywords',
  GooglebotNewsPublicationDate = 'googlebot-news:publication_date',
  GooglebotNewsTitle = 'googlebot-news:title',
  GooglebotNewsSection = 'googlebot-news:section',
  GooglebotNewsLocation = 'googlebot-news:location',
  GooglebotNewsAuthors = 'googlebot-news:authors',
  GooglebotNewsOriginalPublicationDate = 'googlebot-news:original_publication_date',
  GooglebotNewsOriginalTitle = 'googlebot-news:original_title',
  GooglebotNewsOriginalDescription = 'googlebot-news:original_description',
  GooglebotNewsOriginalImage = 'googlebot-news:original_image',
  GooglebotNewsOriginalImageWidth = 'googlebot-news:original_image_width',

  // Twitter
  TwitterCard = 'twitter:card', // Required
  TwitterSite = 'twitter:site', // @Username of website. Required for analytics
  TwitterImageAlt = 'twitter:image:alt', // Required for accessibility
  TwitterTitle = 'twitter:title',
  TwitterDescription = 'twitter:description',
  TwitterImage = 'twitter:image',
  TwitterCreator = 'twitter:creator',
  TwitterUrl = 'twitter:url',
  TwitterPlayer = 'twitter:player',

  // Microsoft
  MsApplicationTileColor = 'msapplication-TileColor',
  MsApplicationTileImage = 'msapplication-TileImage',
  MsApplicationConfig = 'msapplication-config',
  MsApplicationNavbuttonColor = 'msapplication-navbutton-color',

  // LinkedIn
  LinkedinPublisher = 'linkedin:publisher',
  LinkedinAuthor = 'linkedin:author',
  LinkedinTitle = 'linkedin:title',
  LinkedinDescription = 'linkedin:description',
  LinkedinImage = 'linkedin:image',
  LinkedinUrl = 'linkedin:url',
  LinkedinType = 'linkedin:type',
  LinkedinLocale = 'linkedin:locale',
  LinkedinLocaleAlternate = 'linkedin:locale:alternate',
  LinkedinSiteName = 'linkedin:site_name',
  LinkedinArticlePublishedTime = 'linkedin:article:published_time',
  LinkedinArticleModifiedTime = 'linkedin:article:modified_time',
  LinkedinArticleExpirationTime = 'linkedin:article:expiration_time',
  LinkedinArticleAuthor = 'linkedin:article:author',
  LinkedinArticleSection = 'linkedin:article:section',
  LinkedinArticleTag = 'linkedin:article:tag',
  LinkedinArticlePublisher = 'linkedin:article:publisher',
  LinkedinArticleImage = 'linkedin:article:image',
  LinkedinArticleImageSecureUrl = 'linkedin:article:image:secure_url',
  LinkedinArticleImageWidth = 'linkedin:article:image:width',
  LinkedinArticleImageHeight = 'linkedin:article:image:height',
  LinkedinArticleImageAlt = 'linkedin:article:image:alt',
  LinkedinArticleVideo = 'linkedin:article:video',
  LinkedinArticleVideoSecureUrl = 'linkedin:article:video:secure_url',
  LinkedinArticleVideoType = 'linkedin:article:video:type',
  LinkedinArticleVideoWidth = 'linkedin:article:video:width',
  LinkedinArticleVideoHeight = 'linkedin:article:video:height',
  LinkedinArticleVideoUrl = 'linkedin:article:video:url',
}

export enum IconName {
  AcademicCapIcon = 'AcademicCapIcon',
  AdjustmentsHorizontalIcon = 'AdjustmentsHorizontalIcon',
  AdjustmentsVerticalIcon = 'AdjustmentsVerticalIcon',
  ArchiveBoxArrowDownIcon = 'ArchiveBoxArrowDownIcon',
  ArchiveBoxXMarkIcon = 'ArchiveBoxXMarkIcon',
  ArchiveBoxIcon = 'ArchiveBoxIcon',
  ArrowDownCircleIcon = 'ArrowDownCircleIcon',
  ArrowDownLeftIcon = 'ArrowDownLeftIcon',
  ArrowDownOnSquareStackIcon = 'ArrowDownOnSquareStackIcon',
  ArrowDownOnSquareIcon = 'ArrowDownOnSquareIcon',
  ArrowDownRightIcon = 'ArrowDownRightIcon',
  ArrowDownTrayIcon = 'ArrowDownTrayIcon',
  ArrowDownIcon = 'ArrowDownIcon',
  ArrowLeftCircleIcon = 'ArrowLeftCircleIcon',
  ArrowLeftOnRectangleIcon = 'ArrowLeftOnRectangleIcon',
  ArrowLeftIcon = 'ArrowLeftIcon',
  ArrowLongDownIcon = 'ArrowLongDownIcon',
  ArrowLongLeftIcon = 'ArrowLongLeftIcon',
  ArrowLongRightIcon = 'ArrowLongRightIcon',
  ArrowLongUpIcon = 'ArrowLongUpIcon',
  ArrowPathRoundedSquareIcon = 'ArrowPathRoundedSquareIcon',
  ArrowPathIcon = 'ArrowPathIcon',
  ArrowRightCircleIcon = 'ArrowRightCircleIcon',
  ArrowRightOnRectangleIcon = 'ArrowRightOnRectangleIcon',
  ArrowRightIcon = 'ArrowRightIcon',
  ArrowSmallDownIcon = 'ArrowSmallDownIcon',
  ArrowSmallLeftIcon = 'ArrowSmallLeftIcon',
  ArrowSmallRightIcon = 'ArrowSmallRightIcon',
  ArrowSmallUpIcon = 'ArrowSmallUpIcon',
  ArrowTopRightOnSquareIcon = 'ArrowTopRightOnSquareIcon',
  ArrowTrendingDownIcon = 'ArrowTrendingDownIcon',
  ArrowTrendingUpIcon = 'ArrowTrendingUpIcon',
  ArrowUpCircleIcon = 'ArrowUpCircleIcon',
  ArrowUpLeftIcon = 'ArrowUpLeftIcon',
  ArrowUpOnSquareStackIcon = 'ArrowUpOnSquareStackIcon',
  ArrowUpOnSquareIcon = 'ArrowUpOnSquareIcon',
  ArrowUpRightIcon = 'ArrowUpRightIcon',
  ArrowUpTrayIcon = 'ArrowUpTrayIcon',
  ArrowUpIcon = 'ArrowUpIcon',
  ArrowUturnDownIcon = 'ArrowUturnDownIcon',
  ArrowUturnLeftIcon = 'ArrowUturnLeftIcon',
  ArrowUturnRightIcon = 'ArrowUturnRightIcon',
  ArrowUturnUpIcon = 'ArrowUturnUpIcon',
  ArrowsPointingInIcon = 'ArrowsPointingInIcon',
  ArrowsPointingOutIcon = 'ArrowsPointingOutIcon',
  ArrowsRightLeftIcon = 'ArrowsRightLeftIcon',
  ArrowsUpDownIcon = 'ArrowsUpDownIcon',
  AtSymbolIcon = 'AtSymbolIcon',
  BackspaceIcon = 'BackspaceIcon',
  BackwardIcon = 'BackwardIcon',
  BanknotesIcon = 'BanknotesIcon',
  Bars2Icon = 'Bars2Icon',
  Bars3BottomLeftIcon = 'Bars3BottomLeftIcon',
  Bars3BottomRightIcon = 'Bars3BottomRightIcon',
  Bars3CenterLeftIcon = 'Bars3CenterLeftIcon',
  Bars3Icon = 'Bars3Icon',
  Bars4Icon = 'Bars4Icon',
  BarsArrowDownIcon = 'BarsArrowDownIcon',
  BarsArrowUpIcon = 'BarsArrowUpIcon',
  Battery0Icon = 'Battery0Icon',
  Battery100Icon = 'Battery100Icon',
  Battery50Icon = 'Battery50Icon',
  BeakerIcon = 'BeakerIcon',
  BellAlertIcon = 'BellAlertIcon',
  BellSlashIcon = 'BellSlashIcon',
  BellSnoozeIcon = 'BellSnoozeIcon',
  BellIcon = 'BellIcon',
  BoltSlashIcon = 'BoltSlashIcon',
  BoltIcon = 'BoltIcon',
  BookOpenIcon = 'BookOpenIcon',
  BookmarkSlashIcon = 'BookmarkSlashIcon',
  BookmarkSquareIcon = 'BookmarkSquareIcon',
  BookmarkIcon = 'BookmarkIcon',
  BriefcaseIcon = 'BriefcaseIcon',
  BugAntIcon = 'BugAntIcon',
  BuildingLibraryIcon = 'BuildingLibraryIcon',
  BuildingOffice2Icon = 'BuildingOffice2Icon',
  BuildingOfficeIcon = 'BuildingOfficeIcon',
  BuildingStorefrontIcon = 'BuildingStorefrontIcon',
  CakeIcon = 'CakeIcon',
  CalculatorIcon = 'CalculatorIcon',
  CalendarDaysIcon = 'CalendarDaysIcon',
  CalendarIcon = 'CalendarIcon',
  CameraIcon = 'CameraIcon',
  ChartBarSquareIcon = 'ChartBarSquareIcon',
  ChartBarIcon = 'ChartBarIcon',
  ChartPieIcon = 'ChartPieIcon',
  ChatBubbleBottomCenterTextIcon = 'ChatBubbleBottomCenterTextIcon',
  ChatBubbleBottomCenterIcon = 'ChatBubbleBottomCenterIcon',
  ChatBubbleLeftEllipsisIcon = 'ChatBubbleLeftEllipsisIcon',
  ChatBubbleLeftRightIcon = 'ChatBubbleLeftRightIcon',
  ChatBubbleLeftIcon = 'ChatBubbleLeftIcon',
  ChatBubbleOvalLeftEllipsisIcon = 'ChatBubbleOvalLeftEllipsisIcon',
  ChatBubbleOvalLeftIcon = 'ChatBubbleOvalLeftIcon',
  CheckBadgeIcon = 'CheckBadgeIcon',
  CheckCircleIcon = 'CheckCircleIcon',
  CheckIcon = 'CheckIcon',
  ChevronDoubleDownIcon = 'ChevronDoubleDownIcon',
  ChevronDoubleLeftIcon = 'ChevronDoubleLeftIcon',
  ChevronDoubleRightIcon = 'ChevronDoubleRightIcon',
  ChevronDoubleUpIcon = 'ChevronDoubleUpIcon',
  ChevronDownIcon = 'ChevronDownIcon',
  ChevronLeftIcon = 'ChevronLeftIcon',
  ChevronRightIcon = 'ChevronRightIcon',
  ChevronUpDownIcon = 'ChevronUpDownIcon',
  ChevronUpIcon = 'ChevronUpIcon',
  CircleStackIcon = 'CircleStackIcon',
  ClipboardDocumentCheckIcon = 'ClipboardDocumentCheckIcon',
  ClipboardDocumentListIcon = 'ClipboardDocumentListIcon',
  ClipboardDocumentIcon = 'ClipboardDocumentIcon',
  ClipboardIcon = 'ClipboardIcon',
  ClockIcon = 'ClockIcon',
  CloudArrowDownIcon = 'CloudArrowDownIcon',
  CloudArrowUpIcon = 'CloudArrowUpIcon',
  CloudIcon = 'CloudIcon',
  CodeBracketSquareIcon = 'CodeBracketSquareIcon',
  CodeBracketIcon = 'CodeBracketIcon',
  Cog6ToothIcon = 'Cog6ToothIcon',
  Cog8ToothIcon = 'Cog8ToothIcon',
  CogIcon = 'CogIcon',
  CommandLineIcon = 'CommandLineIcon',
  ComputerDesktopIcon = 'ComputerDesktopIcon',
  CpuChipIcon = 'CpuChipIcon',
  CreditCardIcon = 'CreditCardIcon',
  CubeTransparentIcon = 'CubeTransparentIcon',
  CubeIcon = 'CubeIcon',
  CurrencyBangladeshiIcon = 'CurrencyBangladeshiIcon',
  CurrencyDollarIcon = 'CurrencyDollarIcon',
  CurrencyEuroIcon = 'CurrencyEuroIcon',
  CurrencyPoundIcon = 'CurrencyPoundIcon',
  CurrencyRupeeIcon = 'CurrencyRupeeIcon',
  CurrencyYenIcon = 'CurrencyYenIcon',
  CursorArrowRaysIcon = 'CursorArrowRaysIcon',
  CursorArrowRippleIcon = 'CursorArrowRippleIcon',
  DevicePhoneMobileIcon = 'DevicePhoneMobileIcon',
  DeviceTabletIcon = 'DeviceTabletIcon',
  DocumentArrowDownIcon = 'DocumentArrowDownIcon',
  DocumentArrowUpIcon = 'DocumentArrowUpIcon',
  DocumentChartBarIcon = 'DocumentChartBarIcon',
  DocumentCheckIcon = 'DocumentCheckIcon',
  DocumentDuplicateIcon = 'DocumentDuplicateIcon',
  DocumentMagnifyingGlassIcon = 'DocumentMagnifyingGlassIcon',
  DocumentMinusIcon = 'DocumentMinusIcon',
  DocumentPlusIcon = 'DocumentPlusIcon',
  DocumentTextIcon = 'DocumentTextIcon',
  DocumentIcon = 'DocumentIcon',
  EllipsisHorizontalCircleIcon = 'EllipsisHorizontalCircleIcon',
  EllipsisHorizontalIcon = 'EllipsisHorizontalIcon',
  EllipsisVerticalIcon = 'EllipsisVerticalIcon',
  EnvelopeOpenIcon = 'EnvelopeOpenIcon',
  EnvelopeIcon = 'EnvelopeIcon',
  ExclamationCircleIcon = 'ExclamationCircleIcon',
  ExclamationTriangleIcon = 'ExclamationTriangleIcon',
  EyeDropperIcon = 'EyeDropperIcon',
  EyeSlashIcon = 'EyeSlashIcon',
  EyeIcon = 'EyeIcon',
  FaceFrownIcon = 'FaceFrownIcon',
  FaceSmileIcon = 'FaceSmileIcon',
  FilmIcon = 'FilmIcon',
  FingerPrintIcon = 'FingerPrintIcon',
  FireIcon = 'FireIcon',
  FlagIcon = 'FlagIcon',
  FolderArrowDownIcon = 'FolderArrowDownIcon',
  FolderMinusIcon = 'FolderMinusIcon',
  FolderOpenIcon = 'FolderOpenIcon',
  FolderPlusIcon = 'FolderPlusIcon',
  FolderIcon = 'FolderIcon',
  ForwardIcon = 'ForwardIcon',
  FunnelIcon = 'FunnelIcon',
  GifIcon = 'GifIcon',
  GiftTopIcon = 'GiftTopIcon',
  GiftIcon = 'GiftIcon',
  GlobeAltIcon = 'GlobeAltIcon',
  GlobeAmericasIcon = 'GlobeAmericasIcon',
  GlobeAsiaAustraliaIcon = 'GlobeAsiaAustraliaIcon',
  GlobeEuropeAfricaIcon = 'GlobeEuropeAfricaIcon',
  HandRaisedIcon = 'HandRaisedIcon',
  HandThumbDownIcon = 'HandThumbDownIcon',
  HandThumbUpIcon = 'HandThumbUpIcon',
  HashtagIcon = 'HashtagIcon',
  HeartIcon = 'HeartIcon',
  HomeModernIcon = 'HomeModernIcon',
  HomeIcon = 'HomeIcon',
  IdentificationIcon = 'IdentificationIcon',
  InboxArrowDownIcon = 'InboxArrowDownIcon',
  InboxStackIcon = 'InboxStackIcon',
  InboxIcon = 'InboxIcon',
  InformationCircleIcon = 'InformationCircleIcon',
  KeyIcon = 'KeyIcon',
  LanguageIcon = 'LanguageIcon',
  LifebuoyIcon = 'LifebuoyIcon',
  LightBulbIcon = 'LightBulbIcon',
  LinkIcon = 'LinkIcon',
  ListBulletIcon = 'ListBulletIcon',
  LockClosedIcon = 'LockClosedIcon',
  LockOpenIcon = 'LockOpenIcon',
  MagnifyingGlassCircleIcon = 'MagnifyingGlassCircleIcon',
  MagnifyingGlassMinusIcon = 'MagnifyingGlassMinusIcon',
  MagnifyingGlassPlusIcon = 'MagnifyingGlassPlusIcon',
  MagnifyingGlassIcon = 'MagnifyingGlassIcon',
  MapPinIcon = 'MapPinIcon',
  MapIcon = 'MapIcon',
  MegaphoneIcon = 'MegaphoneIcon',
  MicrophoneIcon = 'MicrophoneIcon',
  MinusCircleIcon = 'MinusCircleIcon',
  MinusSmallIcon = 'MinusSmallIcon',
  MinusIcon = 'MinusIcon',
  MoonIcon = 'MoonIcon',
  MusicalNoteIcon = 'MusicalNoteIcon',
  NewspaperIcon = 'NewspaperIcon',
  NoSymbolIcon = 'NoSymbolIcon',
  PaintBrushIcon = 'PaintBrushIcon',
  PaperAirplaneIcon = 'PaperAirplaneIcon',
  PaperClipIcon = 'PaperClipIcon',
  PauseCircleIcon = 'PauseCircleIcon',
  PauseIcon = 'PauseIcon',
  PencilSquareIcon = 'PencilSquareIcon',
  PencilIcon = 'PencilIcon',
  PhoneArrowDownLeftIcon = 'PhoneArrowDownLeftIcon',
  PhoneArrowUpRightIcon = 'PhoneArrowUpRightIcon',
  PhoneXMarkIcon = 'PhoneXMarkIcon',
  PhoneIcon = 'PhoneIcon',
  PhotoIcon = 'PhotoIcon',
  PlayCircleIcon = 'PlayCircleIcon',
  PlayPauseIcon = 'PlayPauseIcon',
  PlayIcon = 'PlayIcon',
  PlusCircleIcon = 'PlusCircleIcon',
  PlusSmallIcon = 'PlusSmallIcon',
  PlusIcon = 'PlusIcon',
  PowerIcon = 'PowerIcon',
  PresentationChartBarIcon = 'PresentationChartBarIcon',
  PresentationChartLineIcon = 'PresentationChartLineIcon',
  PrinterIcon = 'PrinterIcon',
  PuzzlePieceIcon = 'PuzzlePieceIcon',
  QrCodeIcon = 'QrCodeIcon',
  QuestionMarkCircleIcon = 'QuestionMarkCircleIcon',
  QueueListIcon = 'QueueListIcon',
  RadioIcon = 'RadioIcon',
  ReceiptPercentIcon = 'ReceiptPercentIcon',
  ReceiptRefundIcon = 'ReceiptRefundIcon',
  RectangleGroupIcon = 'RectangleGroupIcon',
  RectangleStackIcon = 'RectangleStackIcon',
  RocketLaunchIcon = 'RocketLaunchIcon',
  RssIcon = 'RssIcon',
  ScaleIcon = 'ScaleIcon',
  ScissorsIcon = 'ScissorsIcon',
  ServerStackIcon = 'ServerStackIcon',
  ServerIcon = 'ServerIcon',
  ShareIcon = 'ShareIcon',
  ShieldCheckIcon = 'ShieldCheckIcon',
  ShieldExclamationIcon = 'ShieldExclamationIcon',
  ShoppingBagIcon = 'ShoppingBagIcon',
  ShoppingCartIcon = 'ShoppingCartIcon',
  SignalSlashIcon = 'SignalSlashIcon',
  SignalIcon = 'SignalIcon',
  SparklesIcon = 'SparklesIcon',
  SpeakerWaveIcon = 'SpeakerWaveIcon',
  SpeakerXMarkIcon = 'SpeakerXMarkIcon',
  Square2StackIcon = 'Square2StackIcon',
  Square3Stack3DIcon = 'Square3Stack3DIcon',
  Squares2X2Icon = 'Squares2X2Icon',
  SquaresPlusIcon = 'SquaresPlusIcon',
  StarIcon = 'StarIcon',
  StopCircleIcon = 'StopCircleIcon',
  StopIcon = 'StopIcon',
  SunIcon = 'SunIcon',
  SwatchIcon = 'SwatchIcon',
  TableCellsIcon = 'TableCellsIcon',
  TagIcon = 'TagIcon',
  TicketIcon = 'TicketIcon',
  TrashIcon = 'TrashIcon',
  TrophyIcon = 'TrophyIcon',
  TruckIcon = 'TruckIcon',
  TvIcon = 'TvIcon',
  UserCircleIcon = 'UserCircleIcon',
  UserGroupIcon = 'UserGroupIcon',
  UserMinusIcon = 'UserMinusIcon',
  UserPlusIcon = 'UserPlusIcon',
  UserIcon = 'UserIcon',
  UsersIcon = 'UsersIcon',
  VariableIcon = 'VariableIcon',
  VideoCameraSlashIcon = 'VideoCameraSlashIcon',
  VideoCameraIcon = 'VideoCameraIcon',
  ViewColumnsIcon = 'ViewColumnsIcon',
  ViewfinderCircleIcon = 'ViewfinderCircleIcon',
  WalletIcon = 'WalletIcon',
  WifiIcon = 'WifiIcon',
  WindowIcon = 'WindowIcon',
  WrenchScrewdriverIcon = 'WrenchScrewdriverIcon',
  WrenchIcon = 'WrenchIcon',
  XCircleIcon = 'XCircleIcon',
  XMarkIcon = 'XMarkIcon',
}

export enum CTATarget {
  Blank = '_blank', // Default; new window
  Self = '_self', // Same window
  Parent = '_parent', // Stack frames
  Top = '_top', // Stack frames
}

export enum Colors {
  White = 'white',
  Black = 'black',
  Transparent = 'transparent',
  XumoTeal = 'xumoTeal',
  XumoBlack = 'xumoBlack',
  XumoWhite = 'xumoWhite',
  XumoLicorice = 'xumoLicorice',
  XumoCharcoal = 'xumoCharcoal',
  XumoFog = 'xumoFog',
  XumoJet = 'xumoJet',
  XumoSmoke = 'xumoSmoke',
  XumoPlum = 'xumoPlum',
  XumoBlush = 'xumoBlush',
  XumoButtermilk = 'xumoButtermilk',
  XumoForest = 'xumoForest',
  XumoPistachio = 'xumoPistachio',
  XumoAegean = 'xumoAegean',
  XumoIce = 'xumoIce',
}

export enum InputType {
  Textarea = 'textarea',
  Text = 'text',
  Name = 'name',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Email = 'email',
  Password = 'password',
  Number = 'number',
  Search = 'search',
  Tel = 'tel',
  Url = 'url',
  Reset = 'reset',
  Submit = 'submit',
  Time = 'time',
  Date = 'date',
  Month = 'month',
  Week = 'week',
}
