import { motion, useSpring, useTransform } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React from 'react';
import { MotionImage, SplitH1, useInViewScroll } from 'ui';
import { CTA, IImage } from '../types/common';
import { makeVariants } from '../utils/makeVariants';
import GradientBlock from './GradientBlock';

export interface EcosystemService {
  logo: IImage;
  title: string;
  cta?: CTA;
}

export interface EcosystemProps {
  backgroundColor: string;
  bgImage: IImage;
  heading: string;
  body: string;
  services: EcosystemService[];
}

export const Ecosystem: React.FC<EcosystemProps> = ({ heading, body, backgroundColor, bgImage, services }) => {
  const { t } = useTranslation('common');
  const { ref, progress: y } = useInViewScroll([0, 100], ['start 0vh', 'end 0vh']);
  const rotateAmount = useTransform(y, [0, 100], [0, 10], { clamp: false });
  const rotate = useSpring(rotateAmount, { stiffness: 400, damping: 90 });

  const variants = makeVariants.slideIn();

  return (
    <section ref={ref} className="w-full" data-testid={'ecosystem-section'}>
      {/* trigger parallax 100px earlier due to header */}
      <div className={`relative w-full overflow-hidden bg-cover bg-no-repeat`} style={{ backgroundColor }}>
        <motion.div
          variants={variants}
          initial="initial"
          animate="animate"
          className="3xl:max-w-screen-xl container relative mx-auto flex min-h-[600px] max-w-screen-lg flex-row px-6"
        >
          <div className="absolute-center h-full w-[120%]">
            <GradientBlock backgroundColor={backgroundColor} position="left" />
            <MotionImage
              variants={variants}
              src={bgImage.src}
              alt={bgImage.alt ?? ''}
              className="absolute bottom-[0px] left-[0px] right-[0px] top-[0] z-0 object-contain"
              style={{ y, rotate: rotate as any }}
              fill
              priority
              dangerouslyAllowSVG={true}
            />
            <GradientBlock backgroundColor={backgroundColor} position="right" />
          </div>
          <div className="relative z-20 flex basis-full flex-col justify-center text-left sm:basis-[310px]">
            {/*  // TODO: conditionally use h2 */}
            <SplitH1 content={heading} className="weight-700 mb-6 font-WorkSans text-5xl leading-[48px] tracking-[-0.39px]" />
            <motion.div variants={variants} className="type-body-bold mb-5" dangerouslySetInnerHTML={{ __html: t(body) }} data-i18n={body} />
            {services.length > 0 ? (
              <div className="flex flex-row">
                {services.map(service => {
                  return (
                    <motion.a variants={variants} href={service.cta?.href} key={service.title} className="pr-[13px]">
                      <Image src={service.logo.src} alt={service.logo.alt ?? ''} className="h-full w-auto" width={100} height={100} />
                    </motion.a>
                  );
                })}
              </div>
            ) : null}
          </div>
        </motion.div>
      </div>
    </section>
  );
};
