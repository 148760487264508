import { DirectusSignUpPopup } from 'config';
import { SignUpPopupProps } from '../../../components';

export const mapDirectusSignUpPopup = (block: DirectusSignUpPopup): SignUpPopupProps => {
  return {
    trigger: block.trigger,
    heading: block.heading,
    buttonLabel: block.button_text,
    placeholder: block.input_placeholder,
  };
};
