export function bulletpoints(html: string, inset: boolean = false): string {
  if (!html.includes('&bull;')) return html;

  let bullets = html.split('&bull;');
  if (bullets[0] === '') {
    bullets.splice(0, 1);
  }
  const list = bullets.map(bullet => `<li class='${!inset ? 'pl-4 ml-4 ' : 'list-inside'} ml-4'>${bullet}</li>`).join('');
  return `<ul class="list-disc ${inset ? 'list-inside ' : 'list-outside'}">${list}</ul>`;
}
