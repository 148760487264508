import React from 'react';
import { useWindowSize } from '../hooks';

const GradientBlock: React.FC<{ backgroundColor?: string; position: 'left' | 'right' }> = ({ backgroundColor, position }) => {
  const { width } = useWindowSize();
  const isTablet = width < 910;

  return !isTablet && backgroundColor ? (
    <div
      className={`absolute bottom-0 top-0 z-10 h-full w-[25%] max-[1600px]:w-[30%]`}
      style={{
        [position]: '-4%',
        background: `linear-gradient(to ${position === 'left' ? 'right' : 'left'}, ${backgroundColor} 0%, ${backgroundColor} 50%, rgba(0,0,0,0) 100%)`,
      }}
    ></div>
  ) : null;
};

export default GradientBlock;
