import React, { useContext } from 'react';
import { isEnterprise } from '../utils';
import { HeaderLink } from './NavigationHeaderLinkList';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { PageContext } from '../contexts';
import { ButtonIcon } from './ButtonIcon';

type NavigationTooltipProps = {
  links: HeaderLink[];
  isHovered: boolean;
  setIsHovered: (isHovered: boolean) => void;
  isMobile: boolean;
};

const variants = {
  initial: {
    y: -10,
    x: '-5%',
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    x: '-5%',
    transition: { staggerChildren: 0.05, ease: 'linear', duration: 0.5 },
  },
};

export const NavigationTooltip = ({ links, isMobile, isHovered, setIsHovered }: NavigationTooltipProps) => {
  const pathName = useRouter().asPath;
  const { pageCtx } = useContext(PageContext);
  const { host } = pageCtx;

  return (
    <motion.ul
      className={`bg-white px-6 ${isHovered || isMobile ? 'flex flex-col justify-start' : 'hidden'} ${
        isMobile ? 'ml-4 text-left' : 'absolute top-[90%] py-2 shadow-sm'
      }`}
      onMouseEnter={() => setIsHovered(true)}
      initial={isMobile ? '' : 'initial'}
      animate={isMobile ? '' : 'animate'}
      variants={isMobile ? {} : variants}
      exit="exit"
    >
      <AnimatePresence>
        {links.map(({ href, label }, index) => {
          // TODO: Fetch path and bold if current page
          // TODO: animate underline on hover
          // TODO: Animate on leave DOM
          const isSelected = href === pathName;

          // Highlight first option if on different path
          // Otherwise highlight if selected
          const highlightFirstOption =
            isSelected || (!isMobile && href?.toString().split('/')[1] !== pathName.split('/')[1] && index === 0)
              ? `weight-600 ${isEnterprise(host) ? 'text-xumoAegean' : 'text-xumoBerry'}`
              : 'weight-400';
          return (
            (isHovered || isMobile) && (
              <motion.li key={'nav-' + label} className={`relative transition-all`} variants={isMobile ? variants : {}}>
                <Link href={href!} passHref legacyBehavior>
                  <div className="flex flex-row items-center justify-center">
                    {isMobile && isSelected && (
                      <ButtonIcon
                        name="chevron-right"
                        color={`${isHovered || isSelected ? (isEnterprise(host) ? '#155772' : '#555555') : '#555555'}`}
                        placement={'right'}
                        styleOverrides={` ml-1 mr-[4px]`}
                      />
                    )}
                    <a
                      className={`whitespace-nowrap py-1 transition-all hover:cursor-pointer
                     ${highlightFirstOption} type-body-bold no-shift hover:weight-600`}
                    >
                      {label}
                    </a>
                  </div>
                </Link>
              </motion.li>
            )
          );
        })}
      </AnimatePresence>
    </motion.ul>
  );
};
