import { CTA, getRGBAFromHex } from '../../..';
import stripOuterHtmlTags from '../../stripHtmlTags';
import { bulletpoints, mapDirectusImage } from '../general';
import { mapDirectusCTAs } from '../general/ctas';

// Map Directus Response to ChevronBlock Props
export const mapDirectusRotatingScreensBlock = ({ image, background_color, accent, heading, body, images, ctas, fineprint, copy_blocks }) => {
  return {
    heading: heading ?? '',
    body: bulletpoints(stripOuterHtmlTags(body ?? ''), true),
    ctas: mapDirectusCTAs(ctas ?? [], undefined, true),
    copyBlocks: copy_blocks?.length
      ? copy_blocks.map(({ item }) => ({
          body: item.body,
          heading: item.heading,
          ctas: [], // copy_blocks?.item?.cta_items ? mapDirectusCTAs(copy_blocks?.item?.cta_items) : []
        }))
      : [],
    backgroundColor: background_color,
    fineprint,
    accent,
    images: images?.length ? images.map(image => mapDirectusImage(image.directus_files_id)) : [],
  };
};
