import { DirectusCtaItems, DirectusItemWrapper } from 'config';
import { CTA, CTATarget } from '../../../types';

type MapDirectusCTAs<T, Bool> = Bool extends true ? CTA[] : T extends [infer First, ...infer Rest] ? CTA[] : T extends [] ? undefined : CTA;

/**
 *
 * @param items Base Directus Item Wrapper
 * @param index optional index to return a single CTAs
 * @param asArray optional boolean to return an array of CTAs
 * @returns either a single CTA or an array of CTAs
 */
export const mapDirectusCTAs = <T extends DirectusItemWrapper<DirectusCtaItems>[], Bool extends boolean = false>(
  items: T,
  index?: number,
  asArray?: Bool,
): MapDirectusCTAs<T, Bool> => {
  if ((Array.isArray(items) && items.length > 1) || asArray) {
    return items.map(({ item }) => {
      const { label, href, target } = item;
      return {
        label,
        href,
        target: CTATarget[target as keyof typeof CTATarget],
        backgroundColor: item.color,
        textColor: item.text_color,
        icon: (item?.icon_items ?? [undefined]).map(value => {
          if (!value) return undefined;
          return {
            name: value.item.name,
            color: value.item.color ?? '#000',
            variant: value.item.variant,
            placement: value.item.placement as 'left' | 'right',
          };
        })[0],
      } as CTA;
    }) as MapDirectusCTAs<T, Bool>;
  }

  if (!items.length || (index && !items[index])) return undefined as MapDirectusCTAs<T, Bool>;
  const { label, href, target } = items[index ?? 0].item;
  return {
    label,
    href,
    target: CTATarget[target as keyof typeof CTATarget],
    backgroundColor: items[index ?? 0].item.color,
    textColor: items[index ?? 0].item.text_color,
  } as MapDirectusCTAs<T, Bool>;
};
