import { DirectusRadioButtonGallery } from 'config';
import { mapDirectusCTAs, mapDirectusImage } from '..';
import { RadioButtonGalleryProps } from '../../../components';
import { v4 as uuid } from 'uuid';

export const mapDirectusRadioButtonGallery = (block: DirectusRadioButtonGallery): RadioButtonGalleryProps => {
  return {
    heading: block.heading ?? '',
    body: block.body ?? '',
    backgroundColor: block.background_color ?? '#ffffff',
    accent: block.accent ?? '#000000',
    images:
      block.radio_button_gallery_items?.map(item => ({
        image: mapDirectusImage(item.item.image ?? {}),
        label: item.item.radio_button_label ?? '',
        id: uuid(),
      })) ?? [],
    cta: mapDirectusCTAs(block.cta_items ?? []) ?? { href: '', label: '' },
  };
};
