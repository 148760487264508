import { motion } from 'framer-motion';
import React from 'react';
import { MotionImage, SplitH1, useInViewScroll } from 'ui';
import { IImage } from '../types/common';
import { makeVariants } from '../utils/makeVariants';

export interface FullbleedHeroProps {
  heading: string;
  image: IImage; // TODO: Directus Image props
  valueProps: {
    heading: string;
    body: string;
  }[];
}

export const FullbleedHero: React.FC<FullbleedHeroProps> = ({ heading, image, valueProps }) => {
  const { ref, progress: y } = useInViewScroll([0, 200], ['start 0vh', 'end 0vh']);

  const variants = makeVariants.slideIn({
    initial: {
      y: 20,
      x: 0,
    },
    animate: {
      transition: { staggerChildren: 0.1, duration: 0.4 },
    },
  });

  return (
    <div ref={ref} className="relative flex  min-h-[600px] w-full items-end overflow-hidden object-cover">
      <MotionImage
        src={image.src}
        alt={image.alt ?? ''}
        className="absolute left-0 top-0 h-[calc(100%+50px)] w-full object-cover object-top brightness-[.25]"
        style={{ y }}
        fill
      />
      <div className="z-5 container relative mx-auto md:w-4/5">
        {/*  // TODO: conditionally use h2 */}
        <SplitH1
          content={heading}
          variants={variants}
          className="flex flex-col text-center font-sans text-4xl leading-none text-white md:text-6xl "
          data-i18n={heading}
        />
        <div className="mb-20 flex h-max w-full flex-col items-center justify-between max-sm:pt-0 md:flex-row  md:items-start md:pt-[8vh]">
          {valueProps.map((valueProp, index) => (
            <>
              {index !== 0 && (
                <motion.div
                  initial="initial"
                  animate="animate"
                  className="hidden h-32 w-[1px] rounded-md bg-xumoFog md:block"
                  variants={{ ...variants, animate: { ...variants.animate, transition: { ...variants.animate.transition, delay: 0.3 + index * 0.1 } } }}
                />
              )}
              <motion.div
                initial="initial"
                animate="animate"
                variants={{ ...variants, animate: { ...variants.animate, transition: { ...variants.animate.transition, delay: 0.3 + index * 0.1 } } }}
                // eslint-disable-next-line max-len
                className={`relative flex h-full min-h-[106px] w-full items-center justify-start pt-4 max-md:justify-center max-sm:flex-col max-sm:flex-col-reverse md:w-1/3 md:flex-col sm:lg:justify-between`}
              >
                <h3
                  className="w-40 whitespace-nowrap rounded-md border border-xumoTeal p-3 text-xumoTeal max-sm:mt-[20px]  max-sm:text-center"
                  data-i18n={valueProp.heading}
                  dangerouslySetInnerHTML={{ __html: valueProp.heading }}
                />
                <p className="w-3/4 text-xumoFog max-sm:text-center sm:w-1/2 sm:pt-2 md:w-3/4 md:pt-4" dangerouslySetInnerHTML={{ __html: valueProp.body }} />
              </motion.div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
