import { IImage } from '../../../types';

export const mapDirectusImage = (image: DirectusImageAsset | { id: string } | undefined): IImage => {
  if (!image) {
    return { src: '', alt: '', width: 0, height: 0 };
  }

  return {
    src: `https://${process.env.DIRECTUS_APP_ID}.directus.app/assets/${image.id ?? image}`,
    alt: imageTypeGuard(image) && image.description ? image.description : '',
    width: image.width,
    height: image.height,
  };
};

export interface DirectusImageAsset {
  charset: null;
  description: string | null;
  duration: number | null;
  embed: null;
  filename_disk: string;
  filename_download: string;
  filesize: string;
  folder: string | null;
  height: number;
  id: string;
  location: string | null;
  metadata: Record<string, any>;
  modified_by: Record<string, any> | null;
  modified_on: string | null;
  storage: string;
  tags: string[] | null;
  title: string;
  type: string;
  uploaded_by: Record<string, any> | null;
  uploaded_on: string;
  width: number;
}

const imageTypeGuard = (image: DirectusImageAsset | { id: string }): image is DirectusImageAsset => {
  return (image as DirectusImageAsset).description !== undefined;
};
