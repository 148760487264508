import { useState } from 'react';
import { createContext } from 'react';

// Create the Toasts context object
export const ToastsContext = createContext<{
  toasts: Toast[];
  addToast: (toast: Toast) => void;
  removeToast: (id: string) => void;
}>({ toasts: [], addToast: () => {}, removeToast: () => {} });

export type Toast = {
  id: string;
  title: string;
  description: string;
  type: 'success' | 'error';
};

// Create a Toasts provider component
export const ToastsProvider = ({ children }: { children: JSX.Element[] }) => {
  // Define the initial state for the Toasts
  const [toasts, setToasts] = useState<Toast[]>([]);

  // Define functions for adding and removing Toasts
  const addToast = (toast: Toast) => setToasts([...toasts, toast]);
  const removeToast = (id: string) => setToasts(toasts.filter(toast => toast.id !== id));

  // Return the Toasts provider component, wrapping the children in the Toasts context object
  return <ToastsContext.Provider value={{ toasts, addToast, removeToast }}>{children}</ToastsContext.Provider>;
};
