import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import * as yup from 'yup';
import { InputType, isEnterpriseOrShopper, PageContext } from '..';
import { XumoForm } from './Form';
import { withModal } from './hocs';
export type SignUpPopupProps = {
  trigger: string;
  heading: string;
  buttonLabel: string;
  placeholder: string;
};

export const SignUpPopup = withModal(({ heading, placeholder, buttonLabel, handleClose }: SignUpPopupProps & { handleClose: () => void }) => {
  const { t } = useTranslation('common');
  const { pageCtx } = useContext(PageContext);
  const { host } = pageCtx;

  const placeholderText = placeholder.split(' ').reduce((acc, curr, i, arr) => {
    if (i === Math.floor(arr.length / 2)) {
      return acc + '\r\n' + curr;
    }
    return acc + ' ' + curr;
  });

  return (
    <div className={'flex w-full min-w-[350px] flex-col p-6'} data-test-id="sign-up-popup">
      <h3 className={`type-heading mb-6 mt-4 text-center`} data-i18n={heading} dangerouslySetInnerHTML={{ __html: t(heading) }} />
      <XumoForm
        formFields={[
          {
            type: InputType.Textarea,
            width: 'full',
            placeholder: 'Email address',
            name: 'email',
            customValidation: yup.string().required(`An email is required`).email(`Must be a valid email`),
          },
        ]}
        CTA={{ label: buttonLabel, href: '' }}
        formAction={{ submissionEndpoint: '/items/subscribe', successMessage: 'Susccessfully subscribed', onClick: handleClose }}
        styleOverrides={{
          formField: `w-full text-center h-[3.25rem] mb-4 overflow-y-hidden placeholder:relative placeholder:top-[-.5rem] 
          placeholder:text-xumoCoal placeholder:text-center text-xumoLicorice focus:placeholder:hidden focus:border-opacity-[32%] ${
            isEnterpriseOrShopper(host)
              ? 'caret-xumoAegean hover:border-b-xumoAegean focus:border-b-xumoAegean'
              : 'caret-xumoBerry hover:border-b-xumoBerry border-b-xumoBerry'
          }`,
          CTA: {
            general: `border-0 text-white px-6 py-2 bg-xumoBerry`,
            disabledColor: 'bg-xumoCharcoal',
            enabledColor: 'bg-xumoBerry',
          },
          form: `justify-center items-center px-4`,
        }}
        showTextLengthCount={false}
      />
      <div className="type-subscript">{placeholder}</div>
    </div>
  );
});
