import React from 'react';
import { MetaProperty } from '../types/Enums';

type Props = {
  property: MetaProperty;
  attributeName?: 'name' | 'property' | 'httpEquiv' | 'itemProp';
  content: string;
  key?: string;
};

export const Metadatum = ({ property, content, key, attributeName = 'property' }: Props) => {
  const props = {
    [attributeName]: property,
    content,
    ...(key ? { key } : {}),
  };

  return <meta {...props} />;
};
