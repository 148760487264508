import * as smallIcons from '@heroicons/react/20/solid';
import * as outlineIcons from '@heroicons/react/24/outline';
import * as solidIcons from '@heroicons/react/24/solid';
import { ReactElement, createElement } from 'react';
import { IconName } from '..';

export type IconVariant = 'solid' | 'outline' | 'small';

export const getHeroIcon = (name: IconName, variant: IconVariant = 'solid'): ReactElement => {
  const icons = variant === 'small' ? smallIcons : 'solid' ? solidIcons : outlineIcons;
  const Icon = icons[name as keyof typeof icons];
  return createElement(Icon, { class: 'w-6 h-6' });
};
