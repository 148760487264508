import { DirectusLeadGenForm } from 'config';
import { LeadGenFormProps } from '../../..';
import { DirectusImageAsset, mapDirectusCTAs, mapDirectusImage } from '../general';
import { mapDirectusFormFields } from '../general/formFields';

export const mapDirectusLeadGenForm = (leadGenForm: DirectusLeadGenForm): LeadGenFormProps => {
  return {
    heading: leadGenForm.heading,
    body: leadGenForm.body,
    backgroundColor: leadGenForm.background_color,
    backgroundImage: mapDirectusImage(leadGenForm.background_image),
    formFields: mapDirectusFormFields(leadGenForm.form_field_items ?? []),
    CTA: mapDirectusCTAs(leadGenForm.cta_items ?? []) ?? { label: 'Submit', href: '' },
  };
};
