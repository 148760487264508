import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { Button } from './Button';
import { CopyProps } from './CopySection';
import { useScrolledTheme, SplitH1, SplitH2 } from 'ui';

export interface ColorCopyGridProps {
  heading: string;
  body: string;
  copy: CopyProps[];
  backgroundColor: string;
  asCard?: boolean;
}

export const ColorCopyGrid: React.FC<ColorCopyGridProps> = ({ headingOne, heading, body, copy, backgroundColor, asCard }) => {
  const { t } = useTranslation('common');

  const Heading = headingOne ? SplitH1 : SplitH2;

  const setTextWidth = (text: string) => {
    const len = text.length;
    switch (true) {
      case len < 100:
        return 'w-2/3';
      case len < 150:
        return 'xl:w-4/5';
      default:
        return 'w-full';
    }
  };

  const variants = {
    initial: (i: number) => ({
      opacity: 0,
      x: i % 2 === 0 ? -20 : 20,
    }),
    animate: (i: number) => ({
      x: 0,
      y: 0,
      opacity: 1,
      transition: { staggerChildren: 0.075, ease: 'easeInOut', duration: 0.5 },
    }),
  };

  const ref = useRef();
  const { componentInView } = useScrolledTheme({
    ref,
    background: 'rgb(232, 245, 254)',
    accent: '#000000',
    text: '#000000',
  });

  return (
    <motion.section
      ref={ref}
      className="wrapper flex w-full flex-col items-center justify-center overflow-hidden py-16 lg:py-24"
      data-testid="color-copy-grid-section"
    >
      {heading && (
        <Heading variants={variants} content={heading} className={`${headingOne ? 'type-title' : 'type-heading'} z-10 flex flex-col max-lg:text-left`} />
      )}
      {body && (
        <motion.div variants={variants} className="type-body mb-4 py-4 max-lg:text-left lg:w-1/2" data-i18n dangerouslySetInnerHTML={{ __html: t(body) }} />
      )}
      <div className={'wrapper mx-auto grid grid-cols-1 gap-x-24 gap-y-8 px-4 text-left md:grid-cols-2'}>
        {copy.map((block: any, index: number) => (
          <motion.div
            key={index}
            variants={variants}
            initial="initial"
            whileInView={'animate'}
            viewport={{ once: true }}
            className={`${
              asCard ? 'align-center max-w-screen-sm justify-center rounded-md bg-[#d8e7f3] px-4 py-12 text-center text-xumoAegean' : 'text-black'
            } relative flex min-h-[200px] w-full flex-col lg:min-h-[200px] [&:nth-child(-n+2)]:md:pt-8 [&:nth-child(n+3)]:md:mt-4`}
          >
            {!asCard && index % 2 === 1 && (
              <div
                className={`z-6 absolute left-[-6rem] top-0 float-left mx-12 
                ${copy.length < 3 ? 'h-[100%]' : 'h-[92%]'} w-[1px] bg-xumoIce max-md:hidden lg:bottom-[-4rem]`}
              />
            )}
            <motion.div key={index} custom={index} variants={variants} className={asCard && 'align-center flex flex-col'}>
              <motion.h3
                data-i18n={block.heading}
                variants={variants}
                // eslint-disable-next-line max-len
                className={`type-heading ${asCard && 'w-full px-12'} ${!block.cta && !block.body ? 'text-center' : 'mb-6'}`}
                dangerouslySetInnerHTML={{ __html: t(block.heading) }}
              />
              {block.body && (
                <motion.div
                  data-i18n={block.body}
                  variants={variants}
                  className={`type-body mr-auto min-w-[25%] px-8`}
                  dangerouslySetInnerHTML={{ __html: t(block.body) }}
                />
              )}
              {block.cta && (
                <Button
                  label={block.cta.label}
                  href={block.cta.href}
                  buttonType={'link'}
                  styleOverrides={{
                    css: {
                      backgroundColor: `#fff`,
                      color: backgroundColor,
                    },
                    tailwind: `px-8 max-w-fit`,
                  }}
                />
              )}
            </motion.div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};
