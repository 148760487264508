import { DirectusNavigationHeader } from 'config';
import { NavigationHeaderProps } from '../../../components';
import { mapDirectusCTAs, mapDirectusImage } from '../general';

export const mapDirectusNavigationHeader = (navigationHeader: DirectusNavigationHeader): NavigationHeaderProps => {
  return {
    // links: navigationHeader.header_link_items.map(({ item }) => ({
    //   label: item.label,
    //   href: item.href,
    //   subLinks: item.header_link_items?.map(({ item }) => ({
    //     label: item.label,
    //     href: item.href,
    //   })),
    // })),
    // cta: mapDirectusCTAs(navigationHeader.cta_items ?? []) ?? { label: '', href: '' },
    // logo: mapDirectusImage(navigationHeader.logo),
    // startTransparent: navigationHeader.start_transparent === 'yes' ? true : false,
  };
};
