import axios from 'axios';

export const getDirectusAPI = () => {
  return axios.create({
    baseURL: `https://${process.env.DIRECTUS_APP_ID}.directus.app/`,
    headers: {
      Authorization: `Bearer ${process.env.DIRECTUS_ACCESS_TOKEN}`,
    },
  });
};

export const requestCache = new Map();
