/* eslint-disable max-len */
import { motion, MotionValue, useSpring, useTransform } from 'framer-motion';
import React from 'react';
import { Bullet, SplitH1 } from '.';
import { useInViewScroll, useWindowSize } from '../hooks';
import { CTA, IImage } from '../types/common';
import { makeVariants } from '../utils/makeVariants';
import { MotionImage } from './AnimatedImage';

import GradientBlock from './GradientBlock';

export interface IBullet {
  heading?: string;
  body?: string;
  cta?: CTA;
}

interface Position {
  x?: number;
  y?: number;
}

export interface BulletBlockProps {
  heading: string;
  bgImage: IImage;
  backgroundColor?: string;
  bullets: IBullet[];
  initialPosition: Position;
}

export const BulletBlock: React.FC<BulletBlockProps> = ({ heading, bullets, backgroundColor, initialPosition, bgImage }) => {
  const { ref, progress: y } = useInViewScroll([0, 100], ['start 0vh', 'end 0vh']);
  const rotateAmount = useTransform(y, [0, 100], [0, 10], { clamp: false });
  const rotate: MotionValue<string> = useSpring(rotateAmount, { stiffness: 400, damping: 90 });

  const variants = makeVariants.slideIn();
  const { width } = useWindowSize();

  return (
    <section ref={ref} className="mt-[-130px] w-full pt-[100px] lg:mt-[-100px]" data-testid="bullets-section">
      {/* trigger parallax 100px earlier due to header */}
      <motion.div variants={variants} initial="initial" animate="animate" className={`relative w-full overflow-hidden`} style={{ backgroundColor }}>
        <motion.div className="container relative z-20 mx-auto flex min-h-[530px] max-w-screen-lg flex-row px-6 pb-6 pt-24 text-left  lg:py-0">
          <motion.div className="absolute left-[-2vw] top-0 z-10 h-full w-full md:left-[-17vw] md:w-[130%] 2xl:left-[-10vw]">
            <GradientBlock position="left" backgroundColor={backgroundColor} />
            <MotionImage
              className="absolute h-full w-full max-sm:hidden lg:top-[-18%] lg:h-[120%]"
              style={{ y, rotate: rotate as any }} // Not sure where the type error is coming from
              src={bgImage.src}
              alt={heading}
              sizes="110vw"
              priority
              fill
            />
            <GradientBlock position="right" backgroundColor={backgroundColor} />
          </motion.div>
          <div className="z-10 flex w-full flex-col justify-center sm:w-3/4 md:w-full">
            <SplitH1
              content={heading}
              className="type-title mb-[27px] max-md:pt-20 md:max-w-[376px]"
              style={{ textShadow: `-2px -2px 10px #E0E7ED, 2px 2px 10px #E0E7ED,  2px -2px 10px #E0E7ED, -2px 2px 10px #E0E7ED, 0 0 20px #E0E7ED` }}
            />
            <motion.div variants={variants} className="flex flex-col justify-start md:w-3/4 md:flex-row md:space-x-6 lg:space-x-8">
              {bullets.map((bullet, index) => {
                return <Bullet {...bullet} variants={variants} key={'bullet-' + index} />;
              })}
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};
