import { useRef } from 'react';
import { ContentBlock, MappingContentBlock, MappingDirectusPage } from 'ui/utils/mapping';
import { v4 as uuid } from 'uuid';
import {
  BulletBlock,
  ChevronBlock,
  CtaColumns,
  Ecosystem,
  Footer,
  LeadGenForm,
  NavigationHeader,
  SocialProof,
  SwirlBlock,
  TwoColumnBlock,
  UseCases,
  VerboseDeviceShowcase,
  ColorCopyGrid,
  NewsBlock,
  FullbleedHero,
  BubbleBlock,
  SubscribeBlock,
  QuoteBlock,
  FaqBlock,
  ChannelBlock,
  TvSplashBlock,
  ChannelSearchBlock,
  SignUpPopup,
  Carousel,
  SliderBlock,
  ContentBlock as ContentBlockComponent,
  RadioButtonGallery,
  LandingHero,
  RotatingScreensBlock,
  OffersBlock,
  DeviceListBlock,
  PressBlock,
  VideoModal,
} from 'ui/components';
import Anchor from './Anchor';
import Disclaimer from './Disclaimer';

const Null = () => <div />;

export const PageContent: React.FC<{
  page: { content: MappingContentBlock[] };
}> = ({ page }) => {
  const uniqueId = useRef(uuid());

  const componentTable = {
    [ContentBlock.SocialProof]: SocialProof,
    [ContentBlock.ChevronBlock]: ChevronBlock,
    [ContentBlock.Footer]: Footer,
    [ContentBlock.NavigationHeader]: Null, //NavigationHeader,
    [ContentBlock.VerboseDeviceShowcase]: VerboseDeviceShowcase,
    [ContentBlock.LeadGenForm]: LeadGenForm,
    [ContentBlock.SwirlBlock]: SwirlBlock,
    [ContentBlock.BulletBlock]: Null, //BulletBlock,
    [ContentBlock.UseCases]: UseCases,
    [ContentBlock.TwoColumn]: TwoColumnBlock,
    [ContentBlock.CtaColumns]: CtaColumns,
    [ContentBlock.Ecosystem]: Ecosystem,
    [ContentBlock.ColorCopyGrid]: ColorCopyGrid,
    [ContentBlock.NewsBlock]: NewsBlock,
    [ContentBlock.FullbleedHero]: FullbleedHero,
    [ContentBlock.BubbleBlock]: BubbleBlock,
    [ContentBlock.SubscribeBlock]: SubscribeBlock,
    [ContentBlock.QuoteBlock]: QuoteBlock,
    [ContentBlock.FaqBlock]: FaqBlock,
    [ContentBlock.ChannelBlock]: ChannelBlock,
    [ContentBlock.TvSplashBlock]: TvSplashBlock,
    [ContentBlock.ChannelSearchBlock]: ChannelSearchBlock,
    [ContentBlock.SignUpPopup]: SignUpPopup,
    [ContentBlock.Carousel]: Carousel,
    [ContentBlock.ContentBlock]: ContentBlockComponent,
    [ContentBlock.SliderBlock]: SliderBlock,
    [ContentBlock.RadioButtonGallery]: DeviceListBlock,
    [ContentBlock.LandingHero]: LandingHero,
    [ContentBlock.RotatingScreensBlock]: RotatingScreensBlock,
    [ContentBlock.OffersBlock]: OffersBlock,
    [ContentBlock.Anchor]: Anchor,
    [ContentBlock.PressBlock]: PressBlock,
    [ContentBlock.Disclaimer]: Disclaimer,
    [ContentBlock.VideoModal]: VideoModal,
  };

  return (
    <>
      {page.content.map((c, id) => {
        const Component = componentTable[c.component as keyof typeof componentTable];
        return Component && <Component key={c.component + id + uniqueId} {...(c.props as any)} headingOne={id === 0} />;
      })}
    </>
  );
};
