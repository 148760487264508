import { DirectusCtaItemsList, DirectusText } from 'config';
import { Alignment } from '../../..';
import { ContentBlockProps, Collection, SectionPropsByCollection, TextProps } from '../../../components';
import stripHtmlTags from '../../stripHtmlTags';
import { mapDirectusCTAs } from '../general';

type DirectusContentColumnsItem = {
  alignment: Alignment;
  with_divider: boolean;
  content_blocks: Array<{
    item: DirectusContentBlock<Collection>;
  }>;
};

type DirectusImageItemsItem = {
  alignment: Alignment;
  image: {
    id: string;
    title?: string | null;
  };
};

export type DirectusItemByCollection<T extends Collection> = T extends Collection.TEXT
  ? DirectusText
  : T extends Collection.COLUMNS
  ? DirectusContentColumnsItem
  : T extends Collection.CTAS
  ? DirectusCtaItemsList
  : T extends Collection.IMAGES
  ? DirectusImageItemsItem
  : never;

type DirectusContentBlock<T extends Collection> = {
  background_color: string;
  accent: string;
  section_items: Array<{
    collection: T;
    item: DirectusItemByCollection<T>;
  }>;
  with_divider?: boolean;
  alignment?: Alignment;
};

export const mapDirectusContentBlock = (block: DirectusContentBlock<Collection>, isColumn = false): ContentBlockProps => {
  const sections: ContentBlockProps['sections'] = block.section_items.map(({ item, collection }) => {
    let data: SectionPropsByCollection<typeof collection>;
    switch (collection) {
      case Collection.TEXT:
        const text = item as DirectusText;
        data = { item: mapText(text), collection };
        break;
      case Collection.IMAGES:
        const images = item as DirectusImageItemsItem;
        data = { item: mapImage(images), collection };
        break;
      case Collection.CTAS:
        const { cta_items } = item as DirectusCtaItemsList;
        data = { item: mapDirectusCTAs(cta_items ?? [], undefined, true), collection };
        break;
      case Collection.COLUMNS:
        const { alignment, content_blocks, with_divider } = item as DirectusContentColumnsItem;
        data = {
          collection,
          item: content_blocks.map(({ item: column }: { item: DirectusContentBlock<Collection> }) => mapDirectusContentBlock(column, true)),
          alignment,
          withDivider: with_divider,
        };
        break;
    }
    return data;
  });

  return {
    sections,
    accent: block.accent,
    backgroundColor: block.background_color,
    ...(isColumn ? { alignment: block.alignment, withDivider: block.with_divider } : {}),
  };
};

const mapText = ({ size, color, alignment = Alignment.Left, content = '' }: DirectusText): TextProps => ({
  content: stripHtmlTags(content),
  tag: (content as string)
    .substring(0, content.indexOf('>') + 1)
    .replace('<', '')
    .replace('>', '') as TextProps['tag'],
  size: (size as SectionPropsByCollection<Collection.TEXT>['size']) ?? undefined,
  color: color ?? '#000',
  alignment: alignment as Alignment,
});

const mapImage = ({ image, alignment }: DirectusImageItemsItem) => ({
  src: image.id,
  alt: image.title ?? '',
  alignment,
});
