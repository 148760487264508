import { TargetAndTransition, Variant, Variants } from 'framer-motion';

type VariantConfig = {
  initial?: Variant;
  animate?: TargetAndTransition;
  moreVariants?: Variants;
  from?: Partial<Record<'x' | 'y', number>>;
  to?: Partial<Record<'x' | 'y', number>>;
};
export const makeVariants: Record<'fadeIn' | 'slideIn', (props?: VariantConfig) => Record<string, TargetAndTransition>> = {
  fadeIn: config => ({
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.5, delay: 0.4 }, ...config?.animate },
    ...(config?.moreVariants ?? {}),
  }),
  slideIn: config => ({
    initial: { x: -20, opacity: 0, ...(config?.initial ?? {}), ...(config?.from ?? {}) },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { staggerChildren: 0.075, ease: 'easeOut', duration: 0.5 },
      ...(config?.animate ?? {}),
      ...(config?.to ?? {}),
    },
    ...(config?.moreVariants ?? {}),
  }),
};
