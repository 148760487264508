import React from 'react';
import { CTATarget } from '../types';
import { Button } from './Button';

export interface ThreeStripeSplashCompany {
  href: string;
  alt: string;
}

export interface ThreeStripeSplashProps {
  title: string;
  colors: string[];
  body: string;
  imageSmall?: string;
  imageBig?: string;
  CTA: {
    href: string;
    label: string;
    target: CTATarget;
  };
}

export const ThreeStripeSplash: React.FC<ThreeStripeSplashProps> = ({ title, colors, imageSmall, imageBig, body, CTA }) => {
  const [bg, stripe, ending] = colors;

  return (
    <section className="relative mx-auto overflow-hidden " style={{ backgroundColor: bg }}>
      <div className={'container mx-auto flex min-h-[427px] items-center px-6 '}>
        <div className="flex w-2/5 flex-col justify-center">
          <h2
            className="z-10 mb-4 justify-center font-Helvetica text-4xl font-light leading-10 text-xumoLicorice"
            dangerouslySetInnerHTML={{ __html: title }}
            data-i18n={title}
          />
          <div className="z-10 mb-6 w-full">
            <p className="z-10 font-Helvetica text-xumoLicorice" dangerouslySetInnerHTML={{ __html: body }} data-i18n={title} />
          </div>
          <Button styleOverrides={'h-12 border-xumoTeal z-10'} label={CTA.label} handleClick={() => {}} buttonType="primary" />
        </div>
      </div>
      <div className="absolute right-0 top-0 flex h-full w-3/5">
        <div
          className="h-full w-full bg-cover bg-center bg-no-repeat"
          style={{ backgroundColor: ending, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 55% 100%)', backgroundImage: imageBig ? `url(${imageBig}` : undefined }}
        >
          {imageSmall ? (
            <div
              className="absolute left-0 top-0 z-10 h-full w-full bg-cover bg-center bg-no-repeat"
              style={{
                clipPath: 'polygon(0 0, 35% 0, 90% 100%, 55% 100%)',
                backgroundImage: imageSmall ? `url(${imageSmall}` : undefined,
              }}
            />
          ) : null}
          <div
            className="absolute left-0 top-0 z-20 h-full w-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundColor: stripe,
              clipPath: 'polygon(0 0, 35% 0, 90% 100%, 55% 100%)',
            }}
          />
        </div>
      </div>
    </section>
  );
};
