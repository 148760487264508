import React from 'react';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';

import { AdobeAnalytics, MotionConfig, XumoToast, PageContent, PageMetadata, NavigationHeader } from './';
import { GeneralPageProps, ToastsProvider } from '../';
import { CookieConsentScripts, CookieConsentFooter } from 'ui';

export const GeneralPage: NextPage<GeneralPageProps> = ({ page, metadata, locale }) => {
  const router = useRouter();
  const slug = router.query.slug || [''];
  const path = '/' + slug.join('/');

  return (
    <div key={'gen-page' + path} className="flex min-h-screen flex-col items-center justify-center overflow-x-hidden">
      <CookieConsentScripts />
      <AdobeAnalytics path={metadata.host + path} locale={locale} title={metadata.title} />
      <ToastsProvider>
        <PageMetadata {...metadata} />
        <MotionConfig>
          <main className="flex w-full flex-1 flex-col items-center justify-center text-center" role="main">
            <PageContent page={page} />
          </main>
        </MotionConfig>
        <XumoToast />
      </ToastsProvider>
      <CookieConsentFooter />
    </div>
  );
};
