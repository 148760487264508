import { DirectusCopyItems, DirectusCtaItems } from 'config';
import { CopyConfig, CopyProps } from '../../..';
import config from 'next/config';
import MotionImage from '../../../components/AnimatedImage';

export const mapCopySection = (block: DirectusCopyItems): CopyProps => {
  const baseConfig = {
    primary: block.accent,
    largeHeading: block.large_heading ?? false,
    alignment: (block.alignment as CopyConfig['alignment']) ?? 'left',
    withDivider: block.with_divider ?? false,
    reverseColumn: block.reversed ?? false,
    reducedWidth: block.reduced_width ?? false,
  };

  return {
    heading: block.heading,
    body: block.body,
    subHeading: block.subheading,
    ctas: block.cta_items?.length
      ? block.cta_items.map(({ item }: { item: DirectusCtaItems }) => ({
          label: item.label,
          href: item.href,
        }))
      : undefined,
    columnItems: block.copy_items?.length
      ? block.copy_items.map((item: any) => ({
          heading: item.item.heading ?? '',
          body: item.item.body ?? '',
          config: { ...baseConfig, isColumn: true },
        }))
      : undefined,
    config: { ...baseConfig, isColumn: false },
  };
};
