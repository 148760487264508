/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, useInView, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useContext, useRef, useEffect, useState } from 'react';
import { Button, CTA, CTAList, IImage, PageContext, useInViewScroll } from 'ui';
import { SplitH1, SplitH2, SplitH3 } from './';
import { MotionImage } from './AnimatedImage';

const variants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.05,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

const channelLogos = [
  [
    '/bubbles/channel-nbc-news-now.jpg',
    '/bubbles/channelLogo_americasTestKitchen.png',
    '/bubbles/channel-dateline.jpg',
    '/bubbles/channel-divorceCourt.jpg',
    '/bubbles/channel-lol.webp',
  ],
  [
    '/bubbles/channel-cine.webp',
    '/bubbles/channel-dateline.jpg',
    '/bubbles/channel-golfpass.webp',
    '/bubbles/channelLogo_million.png',
    '/bubbles/channel-nbc-news-now.jpg',
  ],
  [
    '/bubbles/channelLogo_bobthebuilder.png',
    '/bubbles/channel-Hallmark-Movies.jpg',
    '/bubbles/channel-heartland.webp',
    '/bubbles/channel-unsolved-mysteries.webp',
    '/bubbles/channelLogo_evil_kllers.png',
  ],
  [
    '/bubbles/channel-cornhole.webp',
    '/bubbles/channelLogo_AXS.png',
    '/bubbles/channel-cowboy.webp',
    '/bubbles/channel-pattrn.webp',
    '/bubbles/channel-stingray.webp',
  ],
  [
    '/bubbles/channel-cbc.webp',
    '/bubbles/channel-failarmy.webp',
    '/bubbles/channel-ff.webp',
    '/bubbles/channelLogo_FilmRiseFreeMoviesus.png',
    '/bubbles/channelLogo_pga.png',
  ],
];

const distLogos = ['/dist-logos/google.png', '/dist-logos/samsung.png', '/dist-logos/vizio.png', '/dist-logos/redbox.png', '/dist-logos/roku.png'];

const amounts = ['+72', '+42', '+71', '+83', '+88'];

export const RotatingScreensBlock: React.FC<{
  headingOne?: boolean;
  heading: string;
  body: string;
  subheading?: string;
  copyBlocks?: any;
  backgroundColor?: string;
  accent?: string;
  ctas: CTA[];
  fineprint?: string;
  images: IImage[];
}> = ({ headingOne, heading, subheading, copyBlocks, body, backgroundColor, accent, ctas, fineprint, images }) => {
  const { setTheme } = useContext(PageContext);
  const { ref, progress } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const amt = useSpring(progress, { stiffness: 400, damping: 90 });
  const { t } = useTranslation('common');

  const containerRotate = useTransform(amt, [0, 1], ['0deg', '4deg']);
  const containerY = useTransform(amt, [0, 1], ['0vh', '-15vh']);

  const playSection = useRef(null);
  const isInView = useInView(ref, { margin: '-50% 0px -50% 0px' });

  const Heading = headingOne ? SplitH1 : SplitH2;
  const SubHeading = headingOne ? SplitH2 : SplitH3;

  useEffect(() => {
    if (isInView) {
      setTheme({
        background: backgroundColor,
        accent,
        text: '#000000',
      });
    }
  }, [accent, backgroundColor, isInView, setTheme]);

  const [current, setCurrent] = useState(0);

  const timerRef = useRef<any>(0);

  const toggleSlide = () => {
    timerRef.current = setTimeout(() => {
      setCurrent(current => {
        return (current + 1) % images.length;
      });
      toggleSlide();
    }, 5000);
  };

  useEffect(() => {
    toggleSlide();
    return () => clearTimeout(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoVariants = {
    hidden: {
      y: 10,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  const rightBlock = (
    <div className="relative z-20 flex w-full flex-col justify-center text-left">
      <Heading content={heading} variants={variants} className={`${headingOne ? 'type-title' : 'type-heading'}`} />
      <motion.div variants={variants} className="type-body mb-5 pt-5" dangerouslySetInnerHTML={{ __html: t(body) }} data-i18n={body} />
      {/* TODO: Replace with CtaList */}
      {!!ctas?.length && (
        <>
          <motion.div className="flex space-x-5 py-5" variants={variants}>
            {ctas.map(({ label, href, backgroundColor, textColor }, index) => (
              <Button
                href={href}
                label={label}
                buttonType={index === 0 ? 'link-primary' : 'link'}
                styleOverrides={{
                  css: { backgroundColor: backgroundColor, color: textColor },
                  tailwind: `border-0 px-6 py-3`,
                }}
              />
            ))}
          </motion.div>
        </>
      )}
      {copyBlocks?.map((block: any, id: number) => (
        <div key={'rotating' + id} className="mt-6 border-t-[1px] border-solid border-black pt-5">
          {block.heading && <SubHeading content={block.heading} className={`weight-700 type-heading mb-6 text-3xl leading-tight md:max-w-[65%]`} />}
          {block.body && (
            <motion.div
              variants={variants}
              className={`weight-300 type-body mt-5 text-lg md:max-w-full`}
              dangerouslySetInnerHTML={{ __html: t(block.body as string) }}
              data-i18n={block.body}
            />
          )}
          {!!block.ctas.length && (
            <div className="mt-5 flex ">
              <CTAList ctas={block.ctas} />
            </div>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <motion.section ref={ref} className="w-full]" data-testid={'rotating-screens-section'}>
        <div className="container relative mx-auto flex min-h-[650px] flex-col-reverse justify-center px-10 py-[50px] text-left text-black lg:flex-row lg:py-[75px]">
          <motion.div ref={playSection} className="relative w-full lg:w-2/5 lg:pr-32" viewport={{ once: true, margin: '-30% 0% -30% 0%' }}>
            {rightBlock}
          </motion.div>
          <div className="mb-4 flex w-full items-center justify-center lg:w-3/5">
            <AnimatePresence>
              <motion.div
                animate={{ opacity: isInView ? 1 : 0.2, x: isInView ? -10 : 0 }}
                style={{ perspective: '800px' }}
                className="relative flex h-[475px] md:h-[500px] w-full items-center justify-center  max-lg:my-5"
              >
                <AnimatePresence>
                  {images[current].src && (
                    <motion.div
                      key={images[current].src}
                      initial={{ opacity: 0, x: -20, y: 10, rotateY: '-10deg' }}
                      animate={{ opacity: 1, x: 0, y: 0, rotateY: '0deg' }}
                      exit={{ opacity: 0, x: 20, y: -10, rotateY: '2deg' }}
                      transition={{ type: 'spring' }}
                      style={{ transformOrigin: 'left' }}
                      className="absolute left-2 md:left-8 lg:left-0 aspect-video w-full rounded-lg object-left lg:w-[110%]"
                    >
                      <motion.div className="relative aspect-video w-full rounded-lg object-left">
                        <MotionImage
                          fill={true}
                          alt={images[current].alt ?? ''}
                          src={images[current].src}
                          sizes={`(max-width: 768px) 100vw, (max-width: 1600px) 50vw, 30vw`}
                          className="absolute left-0 top-0 aspect-video h-full w-full object-contain object-left"
                        />
                      </motion.div>

                      <motion.div variants={logoVariants} initial="hidden" animate="show" exit="hidden" className="flex w-full max-sm:flex-col">
                        <motion.div variants={logoVariants} className="relative mr-16 h-[80px] w-[160px]">
                          <MotionImage
                            fill
                            className="h-full w-full object-contain object-left"
                            sizes={`(max-width: 768px) 50vw, (max-width: 1600px) 20vw, 15vw`}
                            src={distLogos[current]}
                            alt=""
                          />
                        </motion.div>
                        <div className="flex items-center md:justify-center">
                          {channelLogos[current].map(img => (
                            <motion.div
                              key={img + ' ' + current}
                              variants={logoVariants}
                              className="relative mr-2 h-[40px] max-h-[40px] w-[40px] max-w-[40px] overflow-hidden rounded-full bg-xumoOnyx lg:h-[70px] lg:max-h-[70px] lg:w-[70px] lg:max-w-[70px]"
                            >
                              <MotionImage
                                fill
                                className="h-full w-full object-cover"
                                sizes={`(max-width: 768px) 25vw, (max-width: 1600px) 20vw, 10vw`}
                                src={img}
                                alt=""
                              />
                            </motion.div>
                          ))}
                          <motion.div
                            variants={logoVariants}
                            className="type-subheading flex h-[50px] max-h-[50px] w-[50px] max-w-[50px] items-center justify-center rounded-full bg-white lg:h-[70px] lg:max-h-[70px] lg:w-[70px] lg:max-w-[70px]"
                          >
                            <span>{amounts[current]}</span>
                          </motion.div>
                        </div>
                      </motion.div>
                      {fineprint && current === 0 && <div 
                        className="type-body mt-2 text-[12px]" 
                        data-i18n={fineprint} 
                        dangerouslySetInnerHTML={{ __html: fineprint }} />}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </motion.section>
    </>
  );
};
