import { DirectusUseCases } from 'config';
import { Usecase, UseCasesProps } from '../../../components';
import { mapDirectusCTAs, mapDirectusImage } from '../general';

export const mapDirectusUseCases = (useCases: DirectusUseCases): UseCasesProps => {
  if (useCases.use_case_items?.length < 2 || useCases.use_case_items?.length > 3) {
    throw new Error('UseCases requires 3 use cases');
  }

  return {
    anchor: useCases.anchor ?? '',
    twoColumnText: {
      heading: useCases.heading,
      body: useCases.body ?? '',
    },
    backgroundColor: useCases.background_color ?? '#fff',
    bgImage: mapDirectusImage(useCases.bg_image),
    useCases: useCases.use_case_items?.map(({ item }) => ({
      headline: item.headline,
      body: item.body,
      CTA: mapDirectusCTAs(item.cta_items ?? []),
    })) as [Usecase, Usecase, Usecase],
  };
};
