/* eslint-disable sonarjs/cognitive-complexity */
import { DirectusChevronBlock } from 'config';
import { ChevronBlockProps, CTA, getRGBAFromHex } from '../../..';
import stripOuterHtmlTags from '../../stripHtmlTags';
import { bulletpoints, mapDirectusImage } from '../general';
import { mapDirectusCTAs } from '../general/ctas';

// Map Directus Response to ChevronBlock Props
export const mapDirectusChevronBlock = ({
  image,
  background_color,
  accent,
  heading,
  subheading,
  body,
  lottie,
  cta_items,
  chevronAccent,
  chevron_placement,
  chevron_block_copy_items,
}: DirectusChevronBlock): ChevronBlockProps => {
  return {
    heading: heading ?? '',
    subheading: subheading,
    body: bulletpoints(stripOuterHtmlTags(body ?? ''), true),
    ctas: mapDirectusCTAs(cta_items ?? [], undefined, true),
    secondaryHeading: chevron_block_copy_items?.length ?? 0 > 1 ? chevron_block_copy_items![0]?.item?.heading ?? '' : undefined,
    secondaryBody: chevron_block_copy_items?.length ?? 0 > 1 ? chevron_block_copy_items![0]?.item?.body ?? '' : undefined,
    secondaryCta: chevron_block_copy_items?.length ?? 0 > 1 ? mapDirectusCTAs(chevron_block_copy_items![0]?.item?.cta_items ?? [], 0) : undefined,
    secondaryCta2: chevron_block_copy_items?.length ?? 0 > 1 ? mapDirectusCTAs(chevron_block_copy_items![0]?.item?.cta_items ?? [], 1) : undefined,
    copyBlocks: chevron_block_copy_items?.length
      ? chevron_block_copy_items.map(({ item }) => ({
          body: item.body,
          heading: item.heading,
          ctas: chevron_block_copy_items?.item?.cta_items ? mapDirectusCTAs(chevron_block_copy_items?.item?.cta_items) : [],
        }))
      : [],
    backgroundColor: background_color,
    accent,
    image: mapDirectusImage(image),
    lottie: lottie ? mapDirectusImage(lottie).src : undefined,
    chevronPlacement: chevron_placement as 'left' | 'right',
    chevronAccent,
  };
};
