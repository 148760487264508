import Head from 'next/head';


export const BazaarVoiceBlock = () => {
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
  const url = isProduction 
    ? "https://apps.bazaarvoice.com/deployments/xumo/main_site/staging/en_US/bv.js"
    : "https://apps.bazaarvoice.com/deployments/xumo/main_site/staging/en_US/bv.js"

  return <>
    <Head>
      <script 
        async
        type="text/javascript"
        src="https://apps.bazaarvoice.com/deployments/xumo/main_site/staging/en_US/bv.js">  
      </script>
    </Head>
    <div className="relative flex w-full py-10">
      <div className="wrapper type-body">
        <div  
          data-bv-show="reviews"
          data-bv-product-id="xumo-tv"> 
        </div>
      </div> 
    </div>
  </>
};
