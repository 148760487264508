/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { CTA, IImage, StyleOverride } from '../types';
import { useContent } from '../hooks';
import MotionImage from './AnimatedImage';
import { Button } from '.';
import { makeVariants } from '../utils/makeVariants';
import { useScrolledTheme, SkipLink } from 'ui';
import Head from 'next/head';
import { BazaarVoiceBlock } from 'ui/components/BazaarVoiceBlock'

export type DeviceListBlockProps = {
  heading: string;
  body: string;
  images: { image: IImage; label: CTA['label']; id: string }[];
  cta: CTA;
  accent: string;
  backgroundColor: string;
};

const ShopButton = ({ image }) => {
  return (
    <>
      <div className="relative flex h-[50px] basis-1/2 justify-center rounded-lg border border-xumoAegean">
        <div className="max-sm:align-start relative flex grow p-3">
          <div className="relative h-full w-full">
            <MotionImage src={image} fill className="object-contain" />
          </div>
        </div>
        <Button
          label="Shop now"
          href="https://www.meijer.com/shopping/product/element-65-4k-uhd-hdr-xumo-tv/81064903271.html"
          buttonType="link-primary"
          styleOverrides={{
            tailwind: 'px-6 bg-xumoAegean disabled:bg-xumoSmoke cursor-pointer disabled:cursor-default disabled:text-xumoGrey disabled:drop-shadow-none',
            css: {
              backgroundColor: '',
              color: 'white',
            },
          }}
        />
      </div>
    </>
  );
};

const DeviceItem = ({ strap, buttons, heading, body, sizes, features, images }) => {
  const getGalleryJsonLd = () => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: heading,
      description: body,
      image: images,
    };

    return {
      __html: JSON.stringify(obj),
    };
  };

  return (
    <>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getGalleryJsonLd()} />
      </Head>
      <div className="basis-full lg:basis-1/2 ">
        <div className="relative mb-6 flex grow">
          <div className="relative aspect-video w-full">
            <MotionImage {...images} objectFit="contain" sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" fill />
          </div>
        </div>
        <div className="space-y-3 text-left">
          <div className="uppercase">{strap}</div>
          <div className="type-subheading" dangerouslySetInnerHTML={{ __html: heading }} />
          <div>{sizes}</div>
          <div>{features}</div>
        </div>
        <div className="mt-6 flex flex-col justify-center space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
          {buttons.map(image => (
            <ShopButton image={image} />
          ))}
        </div>
      </div>
    </>
  );
};

export const DeviceListBlock: React.FC<DeviceListBlockProps> = ({ heading, body, images, cta, accent, backgroundColor }) => {
  const [selected, setSelected] = React.useState<DeviceListBlockProps['images'][0]>(images[0]);
  let content = useContent({ heading, body }, [heading, body]);

  const slideInAndOut = useMemo(
    () =>
      makeVariants.slideIn({
        from: { x: 0, y: 100 },
        animate: { transition: { duration: 0.2, delay: 0.15 } },
        moreVariants: { exit: { opacity: 0, x: 0, y: -100, transition: { duration: 0.1 } } },
      }),
    [],
  );

  const ref = React.useRef<HTMLInputElement>(null);

  const { componentInView } = useScrolledTheme({
    ref,
    background: '#ffffff',
    accent,
    text: '#000000',
  });

  const { t } = useTranslation('common');

  return <>
    <motion.section ref={ref} className={'wrapper py-16'} data-test-id="device-list-block">
      <div className="flex justify-center space-x-0 max-lg:flex-col max-lg:space-y-16 md:lg:space-x-3 lg:space-x-16">
        <DeviceItem
          strap=""
          heading={'4K Element UHD Xumo TV'}
          sizes='65" | 55" | 50" | 43"'
          features="4K UHD, Dolby Vision, HDR 10, 120Hz Effective Refresh Rate"
          images={{
            src: '/element-tv.png',
            alt: 'Image of Element TV',
          }}
          buttons={['/Meijer_logo.svg']}
        />
        {/*        <DeviceItem
          strap=""
          heading={'4K Hisense Smart TV'}
          sizes='50"'
          features="4K Ultra HD"
          images={{
            src: '/hisense-tv.png',
            alt: 'Image of Hisense TV',
          }}
          buttons={[
            '/BestBuy_logo.svg'
          ]}
        />*/}
      </div>
    </motion.section>
    <BazaarVoiceBlock />
  </>;
};
