import { DirectusSliderBlock } from 'config';
import { SliderBlockProps } from '../../..';
import { mapDirectusImage } from '../general';

export const mapDirectusSliderBlock = ({ heading, body, backgroundColor, accent, image_items }: DirectusSliderBlock): SliderBlockProps => {
  return {
    heading: heading,
    body: body,
    images: image_items ? image_items.map(i => mapDirectusImage(i.item.image)) : [],
    backgroundColor: backgroundColor,
    accent,
  };
};
