import { DirectusSocialProof, DirectusSocialProofCompanyItems } from 'config';
import { SocialProofCompany, SocialProofProps } from '../../../components';
import { mapDirectusImage } from '../general';

export const mapDirectusSocialProof = (socialProof: DirectusSocialProof): SocialProofProps => {
  return {
    overlap: !!socialProof.overlap,
    heading: socialProof.heading,
    companies: mapDirectusSocialProofCompany(socialProof.social_proof_company_items.map(i => i.item)),
  };
};

export const mapDirectusSocialProofCompany = (socialProofCompanyItems: DirectusSocialProofCompanyItems[]): SocialProofCompany[] => {
  if (socialProofCompanyItems && socialProofCompanyItems.length) {
    return socialProofCompanyItems.map(companyItem => {
      const image = mapDirectusImage(companyItem.logo);
      const company: SocialProofCompany = {
        alt: image.alt,
        href: image.src,
      };
      return company;
    });
  }
  return [];
};
