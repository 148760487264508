/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, useInView, AnimatePresence } from 'framer-motion';
import React, { useContext, useRef } from 'react';
import { PageContext, useInViewScroll, useScrolledTheme, Button } from 'ui';

import Link from 'next/link';

const variants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

const copyVariants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,

      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 80,
      damping: 23,
    },
  },
};

const Promo = props => {
  const hoverVariants = {
    initial: { scale: 1, opacity: 0.8 },
    animate: {
      scale: 1.05,
      opacity: 0.8,
      transition: {
        type: 'spring',
        stiffness: 250,
        damping: 25,
        restDelta: 0.00001,
      },
    },
  };
  return (
    <Link
      href={props.href}
      className={`
          ${props.flex === 1 && 'basis-full lg:aspect-video'} 
          ${props.flex === 2 && 'basis-full lg:aspect-video lg:basis-1/2'} 
          ${props.flex === 4 && 'aspect-square basis-[calc(50%-0.75rem)] lg:basis-1/4'}
       floating-border relative flex h-full cursor-pointer flex-col items-start justify-start overflow-hidden rounded-lg bg-[#ffffffee] p-4 lg:p-6 xl:p-12`}
    >
      <motion.div variants={variants} whileHover="animate">
        {props.banner && (
          <motion.div className="type-body-bold relative z-10 mb-3 inline-block rounded-lg border-2 border-xumoGreen bg-white p-2 text-xs uppercase text-white text-xumoGreen lg:text-sm">
            {props.banner}
          </motion.div>
        )}
        {props.heading && (
          <motion.div
            className={`
            weight-600 relative z-10 mb-3 w-full font-WorkSans text-2xl leading-none text-white  

            ${props.flex == 1 && 'sm:text-xl lg:w-2/5 lg:text-5xl xl:text-7xl'}
            ${props.flex <= 2 && 'sm:text-xl lg:w-2/5 xl:text-3xl'} 
            text-xumoGreen`}
          >
            {props.heading}
          </motion.div>
        )}
        {props.body && (
          <motion.p className={`type-body relative z-10 mb-3 text-black max-md:mb-6 max-sm:text-sm ${props.flex <= 2 ? 'lg:w-2/5' : 'w-full'}`}>
            {props.body}
          </motion.p>
        )}
        {props.bg && (
          <motion.div className="absolute bottom-0 left-0 right-0 top-0" variants={hoverVariants}>
            <img src={props.bg} className="h-full w-full object-cover" alt={'background'} />
          </motion.div>
        )}
        {props.flex === 1 && <Button href="/products/xumo-tv" label={'Learn more'} buttonType="link-primary" styleOverrides={`bg-xumoGreen inline-block`} />}
        {props.product && (
          <motion.div
            className={`lg:top-1 ${props.flex <= 2 ? 'lg:left-[50%]' : 'lg:left-[15%]'} relative lg:absolute lg:bottom-1 lg:right-[-25%]`}
            variants={hoverVariants}
          >
            <img src={props.product} className="h-full w-full object-contain" alt={'product'} />
          </motion.div>
        )}
      </motion.div>
    </Link>
  );
};

export const OffersBlock = ({}) => {
  const { theme, setTheme } = useContext(PageContext);
  const { ref, progress } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const amt = useSpring(progress, { stiffness: 400, damping: 90 });

  const containerRotate = useTransform(amt, [0, 1], ['0deg', '4deg']);
  const y = useTransform(amt, [0, 1], ['0vh', '-15vh']);

  const playSection = useRef(null);
  const isInView = useInView(ref, { margin: '-50% 0px -50% 0px' });

  const { componentInView } = useScrolledTheme({
    ref,
    background: '#D4E5E0',
    accent: '#2AAC00',
    text: '#000000',
  });

  const squiggleVariant = {
    hidden: {
      opacity: 0,
      y: 30,
      transition: {
        when: 'afterChildren',
        delayChildren: 0,
        staggerChildren: 0.025,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.055,
        type: 'spring',
        stiffness: 90,
        damping: 23,
      },
    },
  };

  return (
    <>
      <div className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 h-[115vh] overflow-hidden">
        <motion.div style={{ y }} className="container relative mx-auto h-full px-10 max-md:opacity-10">
          <AnimatePresence>
            {componentInView && (
              <motion.img
                key="squiggle"
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={squiggleVariant}
                src={'/squiggle.webp'}
                className="relative left-[-10%] h-full  w-full object-cover object-left md:left-[50%] lg:w-auto"
              />
            )}
          </AnimatePresence>
        </motion.div>
      </div>
      <motion.section ref={ref} data-testid={'keyart-section'} className="w-full">
        <div className="wrapper relative z-10 flex min-h-[600px] w-full flex-col justify-center py-[100px] text-left text-black">
          <motion.div ref={playSection} variants={variants} initial="show" animate={'show'}>
            {/*	     <SplitH2 variants={copyVariants} content={'Featured<br/>products'} className={`type-heading md:w-1/2`} />
	      	<motion.p variants={copyVariants} className={`type-body md:w-1/2 pt-6`}>
	      		With Xumo in your home, you'll always be able to have all you can stream.
	      	</motion.p>
	      	<motion.div variants={copyVariants} className="pt-6 space-x-6">	
		        <Button
		          label={'Shop products'}
		          href="/shop"
		          buttonType="link-primary"
		          styleOverrides={`bg-xumoGreen inline-block`}
		        />
		     </motion.div>*/}
            <motion.div variants={copyVariants} className="mt-10 space-y-6">
              <div className="flex gap-6 max-lg:flex-wrap max-lg:justify-between">
                <Promo
                  flex={1}
                  href="/products/xumo-tv"
                  banner="New"
                  heading="Treat Dad to a Xumo TV this Father's Day"
                  body="With Xumo, the best that streaming has to offer is now at his fingertips."
                  product="/belair-tv.webp"
                />
                {/*                <Promo
                  flex={2}
                  href="/products/xumo-tv"
                  banner="New"
                  heading="Grab the new Xumo Stream Box"
                  body="Stream all your favorite content on any TV."
                  product="/stream-box.webp"
                />*/}
              </div>
              {/*	      	<div className="flex max-lg:justify-between gap-6 max-lg:flex-wrap">
		      		<Promo banner="Meijer TVs" bg={"/kids.png"} />
		      		<Promo banner="For Kids" bg={"/watchTV.png"} />
		      		<Promo banner="Game Day" bg={"/gameday-promo.jpg"}  />
		      		<Promo banner="All Promos" product={"/_next/image?url=https%3A%2F%2Fxumo-marketing.directus.app%2Fassets%2Ff40beb52-279d-43d6-be5b-e6fae59f3d4c&w=3840&q=75"} />
	      	</div>*/}
            </motion.div>
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};
