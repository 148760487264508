import React, { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { NewsStory, NewsStoryProps } from './NewsStory';
import { motion } from 'framer-motion';
import { CTAList, useScrolledTheme, SplitH1, SplitH2 } from 'ui';

export type NewsBlockProps = {
  heading: string;
  body?: string;
  stories: NewsStoryProps[];
};

const variants = {
  initial: (i: number) => ({
    opacity: 0,
    x: -40,
  }),
  animate: (i: number) => ({
    x: 0,
    y: 0,
    opacity: 1,
    transition: { staggerChildren: 0.075, ease: 'easeInOut', duration: 0.5 },
  }),
};

export const NewsBlock = ({ heading, body, stories, press, cta, headingOne }: NewsBlockProps) => {
  const { t } = useTranslation('common');
  const HeadingComponent = headingOne ? SplitH1 : SplitH2;

  const ref = useRef();
  const { componentInView } = useScrolledTheme({
    ref,
    background: '#F4E6E6',
    accent: '#BB2E76',
    text: '#000000',
  });

  return (
    <section ref={ref} id="news-section" className="wrapper flex w-full flex-col items-start justify-center py-8 max-lg:mt-[75px] lg:py-16">
      <HeadingComponent className={`${headingOne ? 'type-title' : 'type-heading'} mb-4 text-left`} content={heading} />
      {body && <p className={`type-subtitle`} data-i18n={body} dangerouslySetInnerHTML={{ __html: t(body) }} />}
      <motion.div variants={variants} initial="initial" animate="animate" id="gallery" className="grid grid-cols-1 gap-4 py-6 md:grid-cols-2 lg:grid-cols-3">
        {stories.map((story, index) => (
          <>
            <NewsStory key={story.title} {...story} index={index} />
          </>
        ))}
      </motion.div>
      <motion.div className="flex space-x-5 py-5" variants={variants}>
        {cta?.length && <CTAList ctas={cta} />}
      </motion.div>
    </section>
  );
};
