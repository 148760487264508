import React, { FC, cloneElement } from 'react';
import { motion } from 'framer-motion';
import { Button } from './Button';

interface SkipLinkProps {
  className?: string;

  children: React.ReactElement;
  /**
   * The css query aiding the selection of the
   * container (main, section etc) we want to scroll to;
   */
  skipTo: string;
}

export const SkipLink: FC<SkipLinkProps> = ({ skipTo, className }: SkipLinkProps) => {
  const handleSubmit = () => {
    const container: HTMLElement | null = document.querySelector(skipTo);
    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute('tabindex'), 1000);
    }
  };

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleSubmit();
  };

  const onKeyPress = (e: KeyboardEvent) => {
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  // cloneElement enables us to hide this component from the link view in a google search query, as per:
  // https://levelup.gitconnected.com/build-an-accessible-skip-to-content-anchor-link-with-react-140903f3bd7e
  return React.cloneElement(
    <motion.div onClick={onClick} className=" absolute" whileFocus={{ left: '2.4rem' }} initial={{ left: '-16rem' }} tabIndex={0}>
      <Button
        buttonType="link"
        label="Skip to content"
        styleOverrides={'pointer-events-none mr-1rem bg-white text-black focus:outline-none focus:shadow-sm whitespace-nowrap font-bold border-xumoTeal'}
      />
    </motion.div>,
    { onKeyPress, onClick, className },
  );
};

SkipLink.defaultProps = {
  className: 'skip-link',
  skipTo: 'h1:first-of-type',
};
