import React, { useRef, useState, useEffect } from 'react';
import { AnimatePresence, motion, useSpring, useTransform, useInView, useMotionValue, animate } from 'framer-motion';
import { useInViewScroll, MotionImage } from 'ui';
import { useTranslation } from 'next-i18next';

const squiggleVariant = {
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
      restDelta: 0.001,
    },
  },
};

const Counter: React.FC<{ toValue: number; description: string }> = ({ toValue, description }) => {
  const initialValue = toValue * (0.8 + Math.random() * 0.15);
  const animatedNumber = useMotionValue(toValue, { transition: { duration: 2 } });
  const [number, setNumber] = useState(toValue);
  const [shown, setShown] = useState(false);

  const { t } = useTranslation('common');

  const animateTimer = useRef();
  const ref = useRef();
  const inView = useInView(ref, { margin: '0px 0px 0px 0px' });

  useEffect(() => {
    animatedNumber.onChange(number => setNumber(Math.round(number)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView && !shown) {
      animatedNumber.set(initialValue);
      animateTimer.current = animate(animatedNumber, toValue, {
        ease: 'circOut',
        duration: 2.5 + Math.random() * 0.5,
      });
      setShown(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <motion.div ref={ref} className="flex flex-col px-16 py-8">
      <span className="type-title">{number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}+</span>
      <span data-i18n className="type-body">
        {t(description)}
      </span>
    </motion.div>
  );
};

export const StatsBlock = () => {
  const { t } = useTranslation('common');
  const { ref, progress } = useInViewScroll([0, 1], ['start 100vh', 'end 50vh']);
  const movement = useSpring(progress, { stiffness: 400, damping: 90 });
  const y = useTransform(progress, [0, 1], ['0', '-15vh']);
  const opacity = useTransform(progress, [0, 0.4, 0.6, 1], [0.0, 0.25, 0.25, 0.0]);

  const sectionRef = useRef();
  const componentInView = useInView(ref, { margin: '-50% 0% -25% 0%' });

  return (
    <>
      <div className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-0 flex items-center justify-center overflow-hidden">
        <motion.div style={{ y, opacity }} className="wrapper relative mx-auto h-full w-full">
          <AnimatePresence>
            {componentInView && (
              <MotionImage
                key="squiggle"
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={squiggleVariant}
                src={'/flat-map.png'}
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 1400px) 80vw, (max-width: 1920px) 70vw, 50vw"
                className="relative h-full  w-full object-contain"
              />
            )}
          </AnimatePresence>
        </motion.div>
      </div>
      <motion.section className="relative z-10 pb-16 text-center" ref={ref}>
        <div className="flex justify-around py-8 max-lg:flex-col">
          <div className="flex flex-col border-black px-16 max-lg:py-8 lg:border-r">
            <span className="type-title">18+</span>
            <span className="type-body" data-i18n="distribution<br/>endpoints" dangerouslySetInnerHTML={{ __html: t('distribution<br/>endpoints') }} />
          </div>
          <div className="flex flex-col border-black  px-16 max-lg:py-8 lg:border-r">
            <span className="type-title">850+</span>
            <span
              className="type-body"
              data-i18n="global channels<br/>powered by Xumo"
              dangerouslySetInnerHTML={{ __html: t('global channels<br/>powered by Xumo') }}
            />
          </div>
          <div className="flex flex-col px-16  max-lg:py-8">
            <span className="type-title">50+</span>
            <span className="type-body" data-i18n="DSP/SSP<br/>partnerships" dangerouslySetInnerHTML={{ __html: t('DSP/SSP<br/>partnerships') }} />
          </div>
        </div>
        <Counter toValue={1000000000} description="annual hours streamed" />
        <Counter toValue={10000000000} description="annual ad impressions generated" />
      </motion.section>
    </>
  );
};
