import { useEffect, useState } from 'react';

export const useActiveElement = () => {
  const [active, setActive] = useState<Element | undefined>(undefined);

  const handleFocusIn = (e: Event) => {
    if (typeof window !== 'undefined') setActive(document?.activeElement ?? undefined);
  };

  useEffect(() => {
    document.addEventListener('focusin', handleFocusIn);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, []);

  return active;
};
