import { DirectusBulletBlock } from 'config';
import { BulletBlockProps } from '../../../components';
import { mapDirectusCTAs, mapDirectusImage } from '../general';

export const mapDirectusBulletBlock = (bulletBlock: DirectusBulletBlock): BulletBlockProps => {
  return {
    heading: bulletBlock.heading,
    bullets: bulletBlock.bullet_items.map(({ item: bullet }) => ({ body: bullet.body, cta: mapDirectusCTAs(bullet.cta_items ?? []) })),
    backgroundColor: bulletBlock.background_color,
    bgImage: mapDirectusImage(bulletBlock.bg_image),
    initialPosition: { x: -20, y: 0 },
  };
};
