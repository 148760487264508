/* eslint-disable max-len */
import React from 'react';
import Image from 'next/image';
export interface SocialProofCompany {
  href: string;
  alt: string;
}

export interface SocialProofProps {
  overlap: boolean;
  heading: string;
  companies: SocialProofCompany[];
}

export const SocialProof: React.FC<SocialProofProps> = ({ overlap, heading, companies }) => {
  return (
    <section className={overlap ? ' -mt-[110px] w-full' : 'w-full'}>
      <div className={'z-5 container relative mx-auto flex flex-col items-center'}>
        <h2
          className="z-5 relative -mb-[30px] h-[60px] w-full grow-0 items-center justify-center rounded bg-xumoJet px-6 py-4 font-sans text-lg text-white"
          dangerouslySetInnerHTML={{ __html: heading }}
          data-i18n={heading}
        />
        <div className="flex h-[160px] w-full flex-wrap  items-center justify-around bg-xumoFog px-10 max-[540px]:mt-[30px] max-[540px]:h-[173px] max-[400px]:h-[255px]">
          {companies.map(company => {
            return (
              <div key={company.href} className="h-16 max-sm:mt-[15px] max-[540px]:last:mb-[15px]">
                <Image src={company.href} alt={company.alt} className="h-full w-[100px] max-w-none" width={100} height={100} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
