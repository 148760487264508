// A higher order component (HOC) defined as a functional component that wraps a component in a modal.

import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import isBrowser from '../../utils/isBrowser';

export const withModal = <P extends object>(WrappedComponent: React.FC<P>) => {
  const Wrapped = (props: JSX.IntrinsicAttributes & { trigger: string }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
      if (isBrowser) {
        const seen = window.localStorage.getItem(props.trigger);
        if (!seen) {
          setIsOpen(true);
          document.body.style.overflow = 'hidden';
          window.localStorage.setItem(props.trigger, 'true');
        }
      }
    }, [props.trigger]);

    const handleClose = () => {
      setIsOpen(false);
      document.body.style.overflow = 'unset';
    };

    return (
      <div
        className={`${
          isOpen ? 'transition-2 absolute bottom-0 left-0 right-0 top-0 z-[100] h-[100vh] w-full overscroll-contain bg-[#00000090] duration-150' : 'hidden'
        } overlay`}
      >
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="modal absolute-center m-4 w-3/5 min-w-fit max-w-screen-sm rounded-xl bg-white px-4 py-12 drop-shadow-lg"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1, transition: { duration: 0.2, delay: 0.4 } }}
              exit={{ opacity: 0, scale: 0.5 }}
            >
              <div>
                <button
                  title="Close"
                  className="absolute right-0 top-0 m-4"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <motion.svg whileHover={{ scale: 1.1 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="#979797" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 6L18 18" stroke="#979797" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </motion.svg>
                </button>
              </div>
              <WrappedComponent {...(props as P)} handleClose={handleClose} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };
  Wrapped.displayName = `withModal(${WrappedComponent.displayName})`;
  return Wrapped;
};
