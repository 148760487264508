import { Dispatch, SetStateAction, useRef, useState } from 'react';

/**
 * A hook that returns a boolean value indicating whether the element should be shown or not, and a function to toggle the value.
 * @param initialShowElement The initial value of the boolean.
 * @param timeout optional timeout in milliseconds to wait before hiding the element.
 * */
export const useShowElement = (
  initialShowElement: boolean = false,
  timeout: number = 1,
): {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  hideElement: () => void;
  showElement: () => void;
} => {
  // A timeout is used to prevent the element from being shown immediately after being hidden.
  // This is useful when there are multiple disjunct elements that the user has to navigate between.
  const showTimeout = useRef<any>(initialShowElement);
  const [isVisible, setIsVisible] = useState(initialShowElement);

  const hideElement = () => {
    if (showTimeout.current) {
      clearTimeout(showTimeout.current);
    }
    showTimeout.current = setTimeout(() => setIsVisible(false), timeout);
  };

  const showElement = () => {
    if (showTimeout.current) {
      clearTimeout(showTimeout.current);
    }
    setIsVisible(true);
  };

  return { isVisible, hideElement, showElement, setIsVisible };
};
