import React, { useContext } from 'react';
import { PageContext } from '../contexts';
import { InputType } from '../types';
import { isEnterpriseOrShopper } from '../utils';
import { makeVariants } from '../utils/makeVariants';
import { XumoForm } from './Form';
import { SplitH2 } from './hocs';

export type SubscibeBlockProps = {
  heading?: string;
  placeholder?: string;
  buttonText?: string;
  backgroundColor?: string;
  accent?: string;
};

const variants = makeVariants.slideIn({ animate: { transition: { staggerChildren: 0.1 } } });

export const SubscribeBlock: React.FC<SubscibeBlockProps> = ({ heading, placeholder, buttonText, backgroundColor, accent }) => {
  const { pageCtx } = useContext(PageContext);
  const isCorporate = isEnterpriseOrShopper(pageCtx.host);

  return (
    <section className={`flex w-full flex-col items-center py-16`} data-test-id={`subscribe-block`}>
      <SplitH2
        content={heading ?? 'Subscribe to our newsletter'}
        className="type-heading mb-8 flex w-2/3 flex-col items-center text-center"
        whileInView={'animate'}
        variants={variants}
      />
      <XumoForm
        formFields={[{ name: 'email', type: InputType.Email, placeholder: placeholder ?? 'Enter your email', width: 'full' }]}
        CTA={{ label: buttonText ?? 'Subscribe', href: '#' }}
        formAction={{
          submissionEndpoint: '/items/subscribe',
          successMessage: 'Susccessfully subscribed',
          errorMessage: 'Unable to subscribe',
        }}
        styleOverrides={{
          form: `justify-center items-center w-3/4 md:w-3/5`,
          formField: `placeholder:text-center ${!isCorporate ? 'focus:border-b-xumoAegean' : 'focus:border-b-xumoBerry'} focus:border-opacity-[100%]`,
          CTA: {
            general: `border-0 text-white px-6 mt-6 py-2 w-min`,
            disabledColor: 'bg-xumoCharcoal',
            enabledColor: !isCorporate ? 'bg-xumoAegean' : 'bg-xumoBerry',
          },
        }}
      />
    </section>
  );
};
