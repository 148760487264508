import { useSpring, useTransform } from 'framer-motion';
import React from 'react';
import { SplitDiv } from '.';
import { useInViewScroll } from '../hooks';
import { makeVariants } from '../utils/makeVariants';
import { useScrolledTheme } from 'ui';

export interface Quote {
  quote: string;
  attribution?: string;
}

export interface QuoteBlockProps {
  quotes: Quote[];
  backgroundColor?: string;
  textColor?: string;
  attributionsColor?: string;
}

export const QuoteBlock: React.FC<QuoteBlockProps> = ({ quotes, backgroundColor = 'white', textColor = 'black', attributionsColor }) => {
  const { ref, progress: y } = useInViewScroll([0, 100], ['start 0vh', 'end 0vh']);
  const rotateAmount = useTransform(y, [0, 100], [0, 10], { clamp: false });
  const rotate = useSpring(rotateAmount, { stiffness: 400, damping: 90 });

  const variants = makeVariants.slideIn();

  const { componentInView } = useScrolledTheme({
    ref,
    background: backgroundColor,
    accent: '#000000',
    text: '#000000',
  });

  return (
    <section className="w-full overflow-hidden">
      <div className={`3xl:max-w-screen-xl wrapper flex max-w-screen-lg flex-col items-center justify-center py-16 text-center lg:py-24`}>
        {quotes.map(({ quote, attribution }, index, arr) => {
          const isLast = arr.length - 1 === index;

          return (
            <div key={index} style={{ marginBottom: isLast ? 0 : 27 }} className={'flex-col items-center justify-center text-center'}>
              <SplitDiv
                content={quote}
                variants={variants}
                className="weight-700 max-w-2xl text-center font-WorkSans text-2xl leading-[38px] tracking-[-0.47px] sm:text-3xl"
                style={{ color: textColor, marginBottom: attribution ? 27 : 0 }}
              />
              {attribution && (
                <SplitDiv
                  content={attribution}
                  variants={variants}
                  className="weight-400 mx-auto max-w-sm text-center font-WorkSans text-sm leading-[20px]"
                  style={{ color: attributionsColor }}
                />
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};
