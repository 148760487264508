export const MagnifyingGlass = () => (
  <svg className="inline min-h-[16px] min-w-[14px]" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_0_1056" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="16">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H14V16H0V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_0_1056)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={
          'M2.021 9.07689C0.323384 6.81453 0.830178 3.62767 3.15094 1.97278C4.05078 1.33089 5.11377' +
          ' 0.991881 6.22507 0.991881C7.88477 0.991881 9.45997 1.77022 10.4385 3.07428C12.1361 5.33643' +
          ' 11.6291 8.5235 9.30852 10.1784C8.40868 10.8203 7.3457 11.1593 6.23439 11.1593C4.57469 ' +
          '11.1593 2.99949 10.3807 2.021 9.07689ZM13.8937 14.6091L10.9459 10.6805C10.794 10.4783 ' +
          '10.809 10.2029 10.9754 10.0122C12.8017 7.92005 12.9945 4.80078 11.2594 2.48878C10.0903 ' +
          '0.930416 8.20805 0 6.22507 0C4.89645 0 3.62567 0.405328 2.55033 1.17226C-0.223277 3.15011' +
          ' -0.828873 6.95879 1.19982 9.66239C2.36941 11.2208 4.25141 12.151 6.23439 12.151C7.07117 ' +
          '12.151 7.88477 11.99 8.64117 11.6793C8.87864 11.5817 9.15295 11.651 9.30505 11.8538L12.2516' +
          ' 15.7801C12.431 16.0194 12.7755 16.0714 13.0207 15.8965L13.7745 15.3589C14.0198 15.1841 14.0733' +
          ' 14.8482 13.8937 14.6091Z'
        }
        fill="#0A6984"
      />
    </g>
  </svg>
);
