import { DirectusTvSplashBlock } from 'config';
import { TvSplashBlockProps } from '../../../components';
import { mapDirectusCTAs, mapDirectusImage } from '../general';
import { mapCopySection } from '../general/copy';

export const mapDirectusTvSplashBlock = (block: DirectusTvSplashBlock): TvSplashBlockProps => {
  return {
    copyProps: mapCopySection(block),
    topSwirl: mapDirectusImage(block.image),
    bottomSwirl: mapDirectusImage(block.image_2),
    ctas: mapDirectusCTAs(block.cta_items ?? [], undefined, true),
    deviceImages: [mapDirectusImage(block.device_image), ...(block.device_image_2 ? [mapDirectusImage(block.device_image_2)] : [])],
    devicePlacement: block.device_placement as TvSplashBlockProps['devicePlacement'],
    imageLayout: block.layout_items?.reduce((acc: { [key: string]: any }, item) => {
      acc[item.item.image] = item.item.layout;
      return acc;
    }, {}) as TvSplashBlockProps['imageLayout'],
    backgroundColor: block.background_color,
    fineprint: block.fineprint,
    imageFineprint: block.imageFineprint,
  };
};
