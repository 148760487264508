import { CSSProperties } from 'react';
import { StyleOverride } from '..';

export const serializeStyleOverride = (styleOverride?: StyleOverride): { css: CSSProperties | undefined; tailwindClasses: string | undefined } => {
  const base: ReturnType<typeof serializeStyleOverride> = {
    css: undefined,
    tailwindClasses: undefined,
  };

  if (!styleOverride) return base;

  if (typeof styleOverride === 'string') {
    base.tailwindClasses = styleOverride;
    return base;
  } else if ('tailwind' in styleOverride && styleOverride.tailwind) {
    base.tailwindClasses = styleOverride.tailwind;
  }
  if ('css' in styleOverride && styleOverride.css) {
    base.css = styleOverride.css;
  } else if (
    typeof styleOverride === 'object' &&
    ((override: StyleOverride): override is CSSProperties => !!Object.keys(override ?? {})?.length)(styleOverride)
  ) {
    base.css = styleOverride;
  }
  return base;
};
