import invariant from 'invariant';
import { ReactElement, ComponentType } from 'react';

/**
 * Extracts the keys of a type that are assignable to a given type and converts them to boolean values.
 * @type Type - The type to extract the keys from.
 * @example `type FeatureOptions = OptionsFlags<FeatureFlags>;`
 */
export type OptionsFlags<Type> = {
  [Property in keyof Type]: boolean;
};

// Removes 'optional' attributes from a type's properties
//** */
export type Concrete<Type> = {
  [Property in keyof Type]-?: Type[Property];
};

// PartiallyRequired<T, K> makes all properties of T required except those in K
export type PartiallyRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

/**
 * This function checks whether the given component props match the provided
 * component props. It does this by comparing the keys of the component props
 * and the provided component props. If the keys don't match, it will throw an
 * error.
 *
 * ** Parameters **
 *  @param instance - The instance to check.
 *  @param componentType - The component type to check against.
 * ** Return **
 *  @returns validation of the assertion that instance matches componentType
 */
export function matchesComponentProps<T extends Record<string, any>>(instance: Record<string, any>, componentType: ComponentType<T>): asserts instance is T {
  invariant(typeof instance === 'object' && instance !== null, 'The provided instance is not an object or is null.');

  const propKeys = Object.keys(instance);

  // Check if the object has the required keys and if the types match
  for (const key in componentType.propTypes) {
    invariant(propKeys.includes(key), `The instance is missing the "${key}" property.`);

    const expectedType = typeof (componentType.propTypes as any)[key];
    const actualType = typeof instance[key];

    invariant(actualType === expectedType, `The "${key}" property has an incorrect type. Expected "${expectedType}", but got "${actualType}".`);
  }
}
