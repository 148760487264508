import { DirectusCtaColumns, DirectusCtaItems, DirectusItemWrapper } from 'config';
import { CtaColumnsProps } from '../../..';
import stripOuterHtmlTags from '../../stripHtmlTags';
import { bulletpoints, mapDirectusCTAs, mapDirectusImage } from '../general';

export const mapDirectusCtaColumns = (block: DirectusCtaColumns): CtaColumnsProps => {
  return {
    bgColor: block.bgColor,
    textColor: block.textColor,
    heading: block.heading,
    body: block.body,
    accent: block.accent ?? '',
    columns:
      block.column_items?.map(col => ({
        icon: col.item.icon ? mapDirectusImage(col.item.icon) : undefined,
        iconBackground: col.item.icon_background_image ? mapDirectusImage(col.item.icon_background_image) : undefined,
        body: bulletpoints(stripOuterHtmlTags(col.item.body_ ?? '')),
        superText: col.item.overheading_text,
        heading: col.item.heading,
        cta: mapDirectusCTAs(col.item.cta_items as unknown as DirectusItemWrapper<DirectusCtaItems>[]),
      })) ?? undefined,
    headingAlign: block.heading_align,
    columnAlign: block.column_align,
    iconSize: block.iconSize as any,
    withDivider: block.withDivider,
    imageBgFill: block.column_image_background_fill,
    largeColHeaders: block.large_column_headers,
  };
};
