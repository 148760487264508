import React, { ComponentType, useEffect, useMemo } from 'react';
import { motion, Variants } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface SplitAndMapProps {
  content: string;
  className?: string;
  variants?: Variants;
  partition?: string;
  map?: (line: string, index: number) => JSX.Element;
  [key: string]: any;
}

export function getDisplayName(WrappedComponent: React.ComponentType<any>): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * Maps the content to a component, splitting it by the partition string and mapping each line to a JSX element
 * @param className: string
 * @param content: string
 * @param variants: framer-motion.Variants
 * @param partition: string by which to split the content
 * @param map: (line: string, index: number) => JSX.Element - a function to map the lines to JSX elements; defaults to a span
 * @returns <Component> with the content split and mapped to JSX elements
 */
export const withSplitAndMap = <P extends object>(Component: ComponentType<P>): React.FC<P & SplitAndMapProps> => {
  const WrappedComponent: React.FC<P & SplitAndMapProps> = ({
    className,
    content,
    variants,
    partition,
    map = (content: string, index: number) => (
      <motion.span key={'line-' + index} className={'block ' + className} variants={variants} dangerouslySetInnerHTML={{ __html: content }} />
    ),
    ...rest
  }) => {
    const { t, i18n } = useTranslation('common');

    // remove line breaks for other languages
    const defaultContent = i18n.language !== 'en' ? t(content).replace(/<br\/{0,1}>/gm, ' ') : t(content);

    return (
      <Component className={className} data-i18n={content} {...(rest as P)} dangerouslySetInnerHTML={{ __html: defaultContent.replace(/<p\/{0,1}>/gm, '') }} />
    );
  };

  WrappedComponent.displayName = `SplitAndMap(${getDisplayName(Component)})`;
  return WrappedComponent;
};

export const SplitH1: React.FC<SplitAndMapProps> = withSplitAndMap(motion.h1);
export const SplitH2: React.FC<SplitAndMapProps> = withSplitAndMap(motion.h2);
export const SplitH3: React.FC<SplitAndMapProps> = withSplitAndMap(motion.h3);
export const SplitH4: React.FC<SplitAndMapProps> = withSplitAndMap(motion.h3);
export const SplitP: React.FC<SplitAndMapProps> = withSplitAndMap(motion.p);
export const SplitDiv: React.FC<SplitAndMapProps> = withSplitAndMap(motion.div);
