import _ from 'lodash';
import { useContext } from 'react';
import { PageContext } from '../contexts/';
import { isEnterpriseOrShopper } from '../utils';

export const Highlighted = ({ text = '', highlight = '' }) => {
  const { pageCtx } = useContext(PageContext);
  const host = pageCtx?.host;

  if (!highlight.trim() || highlight.length <= 3) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
  const parts = text
    .split(regex)
    .filter(part => part)
    .map((part, index) =>
      regex.test(part) ? (
        <mark key={index} className={`${isEnterpriseOrShopper(host) ? 'bg-xumoTeal' : 'bg-xumoBerry'} text-white`}>
          {part}
        </mark>
      ) : (
        <span key={index}>{part}</span>
      ),
    );

  return <>{parts}</>;
};
