import React from 'react';
import { useTranslation } from 'next-i18next';
import { motion } from 'framer-motion';
import { MotionImage, SplitH1, useInViewScroll } from 'ui';
import { makeVariants } from '../utils/makeVariants';

export interface UseCasePageHeroProps {
  backgroundColor: string;
  bgImage: string;
  heading: string;
  body: string;
}

export const UseCasePageHero: React.FC<UseCasePageHeroProps> = ({ heading, body, backgroundColor, bgImage }) => {
  const { t } = useTranslation('common');
  const { ref, progress: y } = useInViewScroll([100, 0], ['start 0vh', 'end 0vh']);

  const variants = makeVariants.slideIn();

  return (
    <section ref={ref} className="mt-[-130px] pt-[100px] lg:mt-[-100px]">
      {/* trigger parallax 100px earlier due to header */}
      <div className={`relative flex w-full justify-center overflow-hidden bg-cover bg-no-repeat`} style={{ backgroundColor }}>
        <MotionImage fill variants={variants} alt={''} src={bgImage} className=" absolute left-0 h-full w-full" style={{ y }} />
        <motion.div
          variants={variants}
          initial="initial"
          animate="animate"
          className="3xl:max-w-screen-xl relative flex min-h-[600px] max-w-screen-lg flex-row justify-center px-6"
        >
          <div className="z-1 relative flex max-w-[75%] basis-full flex-col items-center justify-center self-center justify-self-center text-center">
            <SplitH1 content={heading} className="weight-700 mb-6 font-WorkSans text-4xl leading-[48px] tracking-[-0.39px]" />
            <motion.p
              variants={variants}
              className="max-w-[460px] font-WorkSans text-lg leading-[20px] "
              dangerouslySetInnerHTML={{ __html: body }}
              data-i18n={body}
            />
          </div>
        </motion.div>
      </div>
    </section>
  );
};
