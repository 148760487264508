//@ts-nocheck
import { useRef } from 'react';
import { MotionValue, useScroll, useTransform } from 'framer-motion';

export const useInViewScroll = (output: number[], offset?: string[]): { ref: MutableRefObject<MotionValue<number>>; progress: MotionValue<number> } => {
  // when the top of `ref` touches the bottom of the viewport
  // until the bottom of the `ref` touches the top of the viewport
  const defaultOffset = ['start 100vh', 'end 0vh'];

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: offset || defaultOffset,
  });
  const progress = useTransform(scrollYProgress, [0, 1], output);

  return {
    ref,
    progress,
  };
};
