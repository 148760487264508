import { DirectusChannelBlock } from 'config';
import { ChannelBlockProps } from '../../../components';
import { mapDirectusImage } from '../general';

export const mapDirectusChannelBlock = (block: DirectusChannelBlock): ChannelBlockProps => {
  return {
    header: block.heading,
    headerLogo: mapDirectusImage(block.heading_logo),
    backgroundColor: block.background_color,
    accent: block.accent,
    channels: block.channel_items.map(channel => ({
      header: channel.item.heading ?? '',
      body: channel.item.body ?? '',
    })),
  };
};
