import { CustomDomComponent, motion } from 'framer-motion';
import Image, { ImageProps } from 'next/image';
import { forwardRef } from 'react';

const ImageWrapper = forwardRef<HTMLImageElement | SVGImageElement, ImageProps>(({ src, alt, ...props }, ref) => {
  // @ts-ignore: next-i18next needs to be augmented to support this: https://www.i18next.com/overview/typescript
  return <Image {...props} src={src} alt={alt} ref={ref} />;
});

ImageWrapper.displayName = 'MotionImage';

export const MotionImage: CustomDomComponent<ImageProps & { dangerouslyAllowSVG?: boolean }> = motion(
  ImageWrapper as unknown as string, // This is a hack to manage type issues caused by forwardRef.
);

export default MotionImage;
