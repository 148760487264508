import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { CTA } from '../types';
import { Button } from './Button';
import { makeVariants } from '../utils/makeVariants';
import { ToggleButton } from './ToggleButton';

type UseCaseProps = {
  useCase: Usecase;
  isShown: boolean;
  setShownCopy: React.Dispatch<React.SetStateAction<number>>;
  index: number;
};

export type Usecase = {
  headline: string;
  body: string;
  CTA: CTA;
};

const variants = makeVariants.slideIn();

export const UseCase = ({ useCase, isShown, setShownCopy, index }: UseCaseProps) => {
  const { t } = useTranslation('common');

  const CTAButton = () => (
    <Button
      label={useCase.CTA.label}
      href={useCase.CTA.href}
      buttonType="link"
      styleOverrides={`${isShown ? '' : 'max-md:hidden'} justify-self-end  h-8 w-48 whitespace-nowrap py-6 px-8 max-md:mb-4`}
    />
  );

  return (
    <li
      className={` flex-grow-1 xs:border-b-black mb-10 flex w-full flex-col items-start
    border border-solid border-black border-x-transparent border-t-transparent pb-10`}
    >
      <motion.div transition={{ ease: 'easeInOut', duration: 0.15 }} className={`flex w-full items-center justify-between`}>
        <div className="flex w-full flex-col justify-start md:flex-row">
          <div className="flex flex-col md:grow md:flex-row md:items-center">
            <div className="flex flex-row items-center">
              <div className="mr-[15px]">
                <ToggleButton isOpen={isShown} setIsOpen={() => setShownCopy(index)} setIsClosed={() => setShownCopy(-1)} />
              </div>
              <h3
                className={`type-heading max-w-[64vw] text-left text-black max-md:py-6 max-sm:text-[24px]`}
                data-i18n={useCase.headline}
                dangerouslySetInnerHTML={{ __html: t(useCase.headline) }}
              />
            </div>
          </div>
          {!isShown && <CTAButton />}
        </div>
      </motion.div>
      <AnimatePresence>
        {isShown && (
          <motion.div
            variants={variants}
            initial="initial"
            animate={['initial', 'animate']}
            className={`xs:max-md:w-full flex h-full flex-col items-start justify-between md:flex-row md:pt-8`}
          >
            <div
              className="type-body w-full text-left text-black max-md:pb-6 md:pr-24"
              data-i18n={useCase.body}
              dangerouslySetInnerHTML={{ __html: t(useCase.body) }}
            />
            <div className="flex shrink-0">
              <CTAButton />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  );
};
