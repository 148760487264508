import React from 'react';
import { MotionConfig as M } from 'framer-motion';

export const MotionConfig: React.FC<{ children: React.ReactNode[] }> = ({ children }) => {
  // Todo: Add a way to override this config from the CMS
  const transitionConfig = {
    type: 'spring',
    stiffness: 100,
    damping: 20,
    mass: 0.5,
    velocity: 0,
    delay: 0,
    duration: 0.5,
    ease: 'easeInOut',
  };

  return (
    <M reducedMotion="user" transition={transitionConfig}>
      <>{children}</>
    </M>
  );
};
