import { StyleOverride } from '../types';
import { IconName, IconVariant, getHeroIcon, serializeStyleOverride } from '../';
import { cloneElement, createElement } from 'react';

export type ButtonIconProps = {
  name: 'arrow' | 'chevron-down' | 'chevron-right' | IconName;
  color: string;
  placement: 'left' | 'right';
  variant?: IconVariant;
  styleOverrides?: StyleOverride;
};

const ArrowIcon = ({ color = '#000', classes = '' }: { color: string; classes: string }) => (
  <svg className={'ml-2 h-[24px] w-[24px] ' + classes} viewBox="0 0 100 100">
    <path d="M10 50 L90 50 M70 30 L90 50 70 70" strokeWidth="3" stroke="currentColor" fill={color} />
  </svg>
);

const ChevronRight = ({ color = '#000', classes = '' }: { color: string; classes: string }) => (
  <div className={classes}>
    <svg width="7" height="10" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0L2.21359 0L5 3L2.75993 3L0 0Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 6L2.21359 6L5 3L2.75993 3L0 6Z" fill={color} />
    </svg>
  </div>
);

const ChevronDown = ({ color = '#000', classes = '' }: { color: string; classes: string }) => (
  <div className={classes}>
    <svg width="10" height="7" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 0V2.21359L3 5L3 2.75993L6 0Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0L0 2.21359L3 5L3 2.75993L0 0Z" fill={color} />
    </svg>
  </div>
);

export const ButtonIcon = ({ name, color, variant, styleOverrides }: ButtonIconProps) => {
  const { css, tailwindClasses = '' } = serializeStyleOverride(styleOverrides);
  switch (name) {
    case 'chevron-down':
      return <ChevronDown classes={tailwindClasses} color={color} />;
    case 'chevron-right':
      return <ChevronRight classes={tailwindClasses} color={color} />;
    case 'arrow':
      return <ArrowIcon classes={tailwindClasses} color={color} />;
    default:
      if (name in IconName) {
        const IconComponent = getHeroIcon(name as IconName, variant);

        return cloneElement(IconComponent, {
          className: tailwindClasses,
          style: { ...css, color: color },
        });
      }

      console.error(`Icon ${name} not found`);
      return <></>;
  }
};
