import classnames from 'classnames';
import { Variants, motion } from 'framer-motion';
import { useMemo } from 'react';
import { useContent } from '../hooks';
import { Alignment, FontSize, StyleOverride } from '../types';
import { serializeStyleOverride } from '../utils/serializeStyleOverride';
import { useTranslation } from 'next-i18next';
import { withSplitAndMap } from '..';

export enum TextAnimation {
  SlideRight = 'slideRight',
  SlideLeft = 'slideLeft',
  Alternate = 'alternate',
  FadeIn = 'fadeIn',
}

export type TextProps = {
  content: string;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'p';
  color: string;
  size?: FontSize;
  alignment: Alignment;
  isCorporate?: boolean;
  styleOverrides?: StyleOverride;
  variants?: Variants;
  animationPreset?: TextAnimation;
};

const styles = {
  base: 'flex flex-col justify-center px-4 py-2 decoration-xumoLicorice',
  alignment: {
    [Alignment.Left]: 'text-left items-start',
    [Alignment.Center]: 'text-center items-center',
    [Alignment.Right]: 'text-right items-end',
    [Alignment.Justify]: 'text-justify items-start',
  },
  h1: 'type-title',
  h2: 'type-title',
  h3: 'type-heading',
  h4: 'type-subheading',
  p: 'text-base',
};

export const Text: React.FC<TextProps> = props => {
  const { t } = useTranslation('common');
  const { alignment, animationPreset: animationPreset, color, content, isCorporate, size, styleOverrides, tag = 'p', variants } = props;

  const { css, tailwindClasses } = serializeStyleOverride(styleOverrides);
  const computedStyles = useMemo(
    () => classnames([styles.alignment[alignment], size, styles[tag], tailwindClasses ?? '']),
    [size, alignment, tag, tailwindClasses],
  );

  // TODO: Create preset animations
  // TODO: Move underline to config mixin
  // TODO: Support custom animations
  if (animationPreset && variants) {
    const TextElement = withSplitAndMap(motion[tag]);
    return (
      <TextElement
        content={content}
        data-i18n={content}
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.5 }}
        className={computedStyles}
        style={{ color, ...css }}
      />
    );
  }

  const TextElement = tag;
  return <TextElement dangerouslySetInnerHTML={{ __html: t(content) }} data-i18n={content} className={computedStyles} style={{ color, ...css }} />;
};
