/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { CTA, IImage, StyleOverride } from '../types';
import { useContent } from '../hooks';
import MotionImage from './AnimatedImage';
import { Button } from '.';
import { makeVariants } from '../utils/makeVariants';
import { useScrolledTheme } from 'ui';
import Head from 'next/head';

import { BazaarVoiceBlock } from 'ui/components/BazaarVoiceBlock'

export type RadioButtonGalleryProps = {
  heading: string;
  body: string;
  images: { image: IImage; label: CTA['label']; id: string }[];
  cta: CTA;
  accent: string;
  backgroundColor: string;
};

const StoreButton: React.FC<{ accent: string; locations: string[]; image: string }> = ({ accent, locations, image }) => {
  const styleOverrides: StyleOverride = {
    css: {
      backgroundColor: accent,
      borderColor: accent,
      boxSizing: 'border-box',
    },
    tailwind: '',
  };
  return (
    <div className="flex min-h-full flex-col rounded-lg border-2 p-4" style={{ borderColor: accent }}>
      <div className="relative mb-4 h-[50px] w-[120px]">
        <MotionImage className="object-contain" src={image} alt={''} fill />
      </div>
      <ul className="ml-4 grow list-disc pb-4 text-left">
        {locations.map(location => (
          <li key={image + location}>{location}</li>
        ))}
      </ul>
      <Button label={'Shop now'} href="/contact" buttonType="link-primary" styleOverrides={styleOverrides} onClick={() =>{
        BV.pixel.trackConversion({
            "type" : "Shop now", 
            "label" : name,
            "value" : "xumo-tv"
          });
      }} />
    </div>
  );
};

export const RadioButtonGallery: React.FC<RadioButtonGalleryProps> = ({ heading, body, images, cta, accent, backgroundColor, name }) => {
  const [selected, setSelected] = React.useState<RadioButtonGalleryProps['images'][0]>(images[0]);
  let content = useContent({ heading, body }, [heading, body]);

  const slideInAndOut = useMemo(
    () =>
      makeVariants.slideIn({
        from: { x: 0, y: 100 },
        animate: { transition: { duration: 0.2, delay: 0.15 } },
        moreVariants: { exit: { opacity: 0, x: 0, y: -100, transition: { duration: 0.1 } } },
      }),
    [],
  );

  const ref = React.useRef<HTMLInputElement>(null);

  const { componentInView } = useScrolledTheme({
    ref,
    background: backgroundColor,
    accent,
    text: '#000000',
  });

  const getGalleryJsonLd = () => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      "@id" : "https://www.xumo.com/products/xumo-tv",
      name: heading,
      description: body,
      image: images,
    };

    return {
      __html: JSON.stringify(obj),
    };
  };

  const { t } = useTranslation('common');

  const element = content.heading.__html.indexOf('lement') >= 0;

  return <>
    <motion.section
      {...(element ? { id: 'devices' } : {})}
      animate={{ opacity: componentInView ? 1 : 0.2 }}
      ref={ref}
      className={'flex w-full flex-col '}
      data-test-id="radio-button-gallery"
    >
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getGalleryJsonLd()} />
      </Head>
      <div className="wrapper flex flex-col items-center px-6 py-32 md:justify-between lg:flex-row">
        <div className={'flex w-full flex-col items-start justify-center lg:w-2/5 lg:px-6'}>
          <h2 className={'type-heading z-[2] mb-6 text-left text-3xl font-bold'} dangerouslySetInnerHTML={content.heading} />
          <div className="relative z-[1] flex min-h-[400px] w-full overflow-hidden rounded-lg">
            <AnimatePresence mode="wait">
              <MotionImage
                key={selected.id}
                variants={slideInAndOut}
                initial={'initial'}
                animate={'animate'}
                exit={'exit'}
                className="object-cover  "
                src={selected.image.src}
                alt={selected.image.alt ?? ''}
                fill
              />
            </AnimatePresence>
          </div>
        </div>
        <div className={'flex w-full flex-col flex-wrap justify-start text-left max-lg:mt-6 md:pl-6 lg:w-3/5 lg:flex-col'}>
          <div>
            <div className={'type-body text-left'} data-i18n dangerouslySetInnerHTML={content.body} />
          </div>
          <div>
            <div className="py-4">
              <div className="type-body-bold mb-3">Sizes</div>
              <div className="space-x-3">
                {element && <span className="inline-block rounded-md bg-white p-3 text-sm">65"</span>}
                {element && <span className="inline-block rounded-md bg-white p-3 text-sm">55"</span>}
                <span className="inline-block rounded-md bg-white p-3 text-sm">50"</span>
                {element && <span className="inline-block rounded-md bg-white p-3 text-sm">43"</span>}
              </div>
            </div>

            <div className="flex flex-row space-x-4 pt-5">
              {element && <StoreButton accent={accent} locations={['Online', 'In-store']} image="/Meijer_logo.svg" name="Meijer" />}
              <StoreButton accent={accent} locations={['Online']} image="/Walmart_logo.svg" />
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  </>;
};
