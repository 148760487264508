import React from 'react';
import Image from 'next/image';

export interface TestimonialSectionProps {
  logo: string;
  bgImage: string;
  bgColor: string;
  quote: string;
  name: string;
  role: string;
  companyName: string;
}

export const TestimonialSection: React.FC<TestimonialSectionProps> = ({ logo, quote, name, role, companyName, bgImage, bgColor }) => {
  return (
    <section
      className={`relative flex min-h-[450px] w-full flex-col justify-center bg-cover bg-center py-20`}
      style={{ backgroundColor: bgColor, backgroundImage: `url(${bgImage})` }}
    >
      <div className="z-5 absolute left-0 top-0 h-full w-full bg-black opacity-40" />
      <div className={`container relative z-10 mx-auto flex flex-col items-center justify-center`}>
        <div className="mx-auto h-14">
          <Image src={logo} className="h-full" alt={companyName} width={100} height={56} />
        </div>
        <h4
          className="mb-[26px] mt-[42px] text-center font-Helvetica text-4xl font-light text-white"
          dangerouslySetInnerHTML={{ __html: quote }}
          data-i18n={quote}
        />
        <div className="text-center font-Helvetica text-sm font-light text-white">
          <span>
            {role}, {name}
          </span>
        </div>
      </div>
    </section>
  );
};
