import { DirectusCtaItems, DirectusEcosystem, DirectusEcosystemServiceItems, DirectusItemWrapper } from 'config';
import { convertRGBAHexToRGB, EcosystemProps, EcosystemService } from '../../..';
import { mapDirectusImage, DirectusImageAsset } from '../general';
import { mapDirectusCTAs } from '../general/ctas';

export const mapDirectusEcosystem = ({ heading, body, background_image, ecosystem_service_items, background_color }: DirectusEcosystem): EcosystemProps => {
  return {
    heading: heading,
    body: body,
    bgImage: mapDirectusImage(background_image),
    services: ecosystem_service_items ? mapDirectusEcosystemCompany(ecosystem_service_items.map(i => i.item)) : [],
    backgroundColor: convertRGBAHexToRGB(background_color),
  };
};

export const mapDirectusEcosystemCompany = (ecosystemServiceItem: DirectusEcosystemServiceItems[]): EcosystemService[] => {
  if (ecosystemServiceItem && ecosystemServiceItem.length) {
    return ecosystemServiceItem.map(serviceItem => {
      const service: EcosystemService = {
        logo: mapDirectusImage(serviceItem.logo),
        cta: mapDirectusCTAs(serviceItem.cta_items ?? []),
        title: serviceItem.title,
      };
      return service;
    });
  }
  return [];
};
