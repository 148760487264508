import { useEffect, useRef } from 'react';
import { MotionValue, useInView, useScroll, useSpring, useTransform } from 'framer-motion';

export type ResponsiveScrollSpringProps = {
  mobile: { attribute: 'x' | 'y'; values: number[] };
  desktop: { attribute: 'x' | 'y'; values: number[] };
  offset?: any;
  breakpointConfig: {
    MIN_WINDOW_SIZE: number;
    windowSize: {
      width: number;
      height: number;
    };
  };
  springConfig: Record<string, any>;
};

/**
 *useResponsiveScrollSpring hook is used to create scroll effects that differ depending on the breakpoint.
 * @param mobile - The mobile breakpoint configuration.
 *                 Attribute represents the axis of the scroll effect (x or y)
 *                 and values represents the range of the scroll effect.
 * @param desktop - The desktop breakpoint configuration
 * @param breakpointConfig - The breakpoint configuration
 * @param springConfig - The spring configuration
 * @param offset - The offset
 *
 */
export const useResponsiveScrollSpring = ({ mobile, desktop, breakpointConfig, springConfig, offset }: ResponsiveScrollSpringProps) => {
  const { MIN_WINDOW_SIZE, windowSize } = breakpointConfig;
  const defaultOffset = ['start 100vh', 'end 0vh'];

  // TODO: Find a less expensive way to do this.
  //This is a hack to force an update when the component is mounted (otherwise the scrollYProgress is not calculated)
  // See: https://github.com/framer/motion/issues/835#issuecomment-726379355 for another solution
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }, []);

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: offset ?? defaultOffset,
  });

  const { attribute, values } = windowSize.width > MIN_WINDOW_SIZE ? desktop : mobile;

  const progress = useTransform(scrollYProgress, [0, 1], values);
  const spring = { [attribute]: useSpring(progress as unknown as MotionValue, springConfig) } as any;

  return { ref, spring };
};
