'use client';

import React, { Suspense, useRef } from 'react'
import { XumoGlobe } from './XumoGlobe.tsx'
import { SplitH1, SplitH2 } from './';
import { useScrolledTheme } from 'ui';
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next';

const heading = "Xumo Enterprise powers <br/>streaming experiences in over 170 countries" 
const body = "Explore a selection of our FAST endpoints below."

const variants = {
  initial: {
    opacity: 0,
    y: 40,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      staggerChildren: 0.03,
    },
  },
};

export const GlobeBlock = ({ headingOne }) => {
  const { t } = useTranslation('common')
  const Heading = headingOne ? SplitH1 : SplitH2;

  const ref = useRef()
  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#E0E7ED',
    accent: '#2AAC00',
    text: '#000000',
  });


  return <div ref={ref} className="relative py-[50px] z-10">
    <motion.div className="wrapper flex flex-col items-center text-left lg:text-center w-full" variants={variants} initial="initial" whileInView="animate" viewport={{once: true}}>
      <Heading 
        variants={variants} 
        content={heading} 
        className={`${headingOne ? 'type-title' : 'type-heading'} z-10 flex flex-col`} />
      {body && <motion.div variants={variants} className="type-body py-4 text-left lg:text-center lg:w-1/2" data-i18n dangerouslySetInnerHTML={{ __html: t(body) }} />}
    </motion.div>
    <div className="xumo-globe w-full h-[500px] md:h-[750px] xl:h-[1000px]">
      <XumoGlobe/>
    </div>
  </div>;
};
