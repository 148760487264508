import { StyleOverride } from '../types';
import { serializeStyleOverride } from '../utils/serializeStyleOverride';

type ToggleButtonProps = {
  isOpen: boolean;
  setIsOpen: () => void;
  setIsClosed: () => void;
  label?: string | { __html: string };
  styleOverrides?: StyleOverride;
};

export const ToggleButton = ({ isOpen, label, setIsOpen, setIsClosed, styleOverrides }: ToggleButtonProps) => {
  const { css, tailwindClasses } = serializeStyleOverride(styleOverrides);
  return (
    <button
      className={`weight-500 leading-0 z-18 ${
        label ? 'min-w-fit rounded-md border-2 text-sm' : 'min-w-[32px] rounded-sm text-xl'
      }  border-0  transition-colors ${isOpen ? 'bg-xumoAegean text-white' : 'border border-xumoAegean bg-transparent text-xumoAegean'}
      ${tailwindClasses}
      `}
      style={css}
      onClick={() => {
        isOpen ? setIsClosed() : setIsOpen();
      }}
      dangerouslySetInnerHTML={typeof label === 'object' ? label : { __html: isOpen ? '-' : '+' }}
    ></button>
  );
};
