import { DirectusQuoteBlock } from 'config';
import { QuoteBlockProps } from '../../../components';

export const mapDirectusQuoteBlock = (block: DirectusQuoteBlock): QuoteBlockProps => {
  return {
    backgroundColor: block.background_color,
    attributionsColor: block.attribution_color,
    textColor: block.quote_color,
    quotes: block.quote_items.map(item => {
      return {
        quote: item.item.quote,
        attribution: item.item.attribution,
      };
    }),
  };
};
