import { Button, ButtonVariant } from '.';
import { Alignment, CTA } from '../types';

export interface CTAListProps {
  ctas: Array<CTA & { image?: { src: string; alt?: string } }>;
  buttonType?: ButtonVariant;
  styleOverrides?: { css?: React.CSSProperties; tailwind?: string };
  alignment?: Alignment;
}

export const CTAList: React.FC<CTAListProps> = ({ ctas = [], buttonType, styleOverrides, alignment = 'left' }) => {
  return (
    <div
      className={`my-4 flex w-full flex-row flex-wrap gap-x-6 gap-y-2
      ${alignment === 'left' ? 'justify-start' : alignment === 'center' ? 'justify-center' : 'justify-end'}
      `}
    >
      {ctas.map((button, index) => {
        const { label, href, image, handleClick, backgroundColor, textColor, isSelected } = button ?? {};
        return button ? (
          <Button
            label={label ?? ''}
            href={href}
            icon={image as any}
            key={index}
            handleClick={handleClick}
            buttonType={'link-primary'}
            styleOverrides={{
              css: { backgroundColor: backgroundColor, color: textColor, ...styleOverrides?.css },
              tailwind: styleOverrides?.tailwind ?? '',
            }}
            isSelected={isSelected}
          />
        ) : null;
      })}
    </div>
  );
};
